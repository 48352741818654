import { ORDER_NOTES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import OrderNote from '../../../domain/automator/OrderNote';
import { OrderNoteQueryKey } from '../../queryKeys/OrderNoteQueryKey';

export const useFetchOrderNote = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDER_NOTES_PATH + '?orderId=' + orderId);

  return useFetchData<OrderNote>(url, OrderNoteQueryKey(orderId));
};
