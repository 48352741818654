import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Retailer from '../../../../domain/automator/Retailer';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import RetailerLooksIcon from './RetailerLooksIcon';
import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';

interface RetailerTableRowProps {
  retailer: Retailer;
}

export const RetailerTableRow = ({ retailer }: RetailerTableRowProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.UPDATE_RETAILER_ADDRESS);

  return (
    <TableRow key={retailer.id}>
      <TableCell
        width={'0.1rem'}
        align="left"
      >
        <RetailerLooksIcon retailerId={retailer.id} />
      </TableCell>
      <TableCell align="left">
        <Box>
          <Typography
            variant="body1"
            gutterBottom
          >
            {retailer.name}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align="right">
        <Box>
          <Typography
            variant="body1"
            gutterBottom
          >
            {retailer.isApproved === false
              ? t(tokens.automator.retailers.status.blocked)
              : t(tokens.automator.retailers.status.active)}
          </Typography>
        </Box>
      </TableCell>

      {/*<TableCell align="right">*/}
      {/*  <Box>*/}
      {/*    <Typography*/}
      {/*      variant="body1"*/}
      {/*      gutterBottom*/}
      {/*    >*/}
      {/*      {retailer.forwardEmailAddress}*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</TableCell>*/}

      <TableCell align="right">{retailer.createdDateTime}</TableCell>

      <TableCell sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ActionButton
          onClick={() => openDialog({ retailer })}
          label={t(tokens.automator.retailers.update_retailer_address.update_retailer_address)}
        />
      </TableCell>
    </TableRow>
  );
};
