import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import { useSendMessageThreadReply } from '../../../../api/automator/emails/useSendMessageThreadReply';
import { toast } from 'react-hot-toast';
import MessageTemplate from '../../../../domain/automator/MessageTemplate';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { TemplateVariableSelection } from './TemplateVariableSelection';
import HtmlEditor from '../../email/components/HtmlEditor';
import { RecipientType } from '../../../../domain/automator/RecipientType';
import MessageThread from '../../../../domain/automator/MessageThread';

interface SendMessageThreadReplyFormProps {
  onClick?: () => void;
  messageThread: MessageThread;
  messageTemplate: MessageTemplate | null;
}

export const SendMessageThreadReplyForm = ({
  onClick,
  messageThread,
  messageTemplate: initialMessageTemplate,
}: SendMessageThreadReplyFormProps) => {
  const [messageTemplate, setMessageTemplate] = useState<MessageTemplate | null>(
    initialMessageTemplate
  );

  const [insertVariable, setInsertVariable] = useState<string | null>(null);

  useEffect(() => {
    if (messageTemplate !== null) {
      setBody(messageTemplate?.body || '');
    }
  }, [messageTemplate]);

  const determineBody = (recipientType: RecipientType) => {
    if (recipientType == RecipientType.CUSTOMER) {
      return `Beste [[CUSTOMER_NAME]],<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Partner van Bol.com`;
    }

    if (recipientType == RecipientType.BOL) {
      return `Beste Bol,<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Winkelnummer: [[BOL_RETAILER_ID]]`;
    }
    return '';
  };

  const [body, setBody] = useState(
    initialMessageTemplate?.body
      ? initialMessageTemplate.body
      : determineBody(messageThread.recipientType)
  );

  const { t } = useTranslation();

  const { mutate: sendReply, isLoading } = useSendMessageThreadReply(messageThread.id);

  const onSend = () => {
    sendReply(
      {
        email_template_id: messageTemplate?.id,
        body: body || '',
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.resolutions.dialogs.send_email_thread_reply.reply_sent));
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="row"
        gap={2}
      >
        <Stack
          direction="column"
          gap={2}
          width="70%"
        >
          <HtmlEditor
            content={body}
            onChange={(newBody) => {
              if (newBody === body) {
                return;
              }
              setBody(newBody);
            }}
            insertVariable={insertVariable}
          />

          <ActionButton
            icon={<Email />}
            label={t(tokens.automator.resolutions.dialogs.send_email_thread_reply.send_reply)}
            onClick={() => onSend()}
            isLoading={isLoading}
            variant="contained"
            color="primary"
            size="small"
          />
        </Stack>

        <Box width="30%">
          <TemplateVariableSelection
            phase={messageThread.phase}
            onSelectTemplate={(messageTemplate) => {
              setBody(messageTemplate.body);
              setMessageTemplate(messageTemplate);
            }}
            selectedTemplate={messageTemplate}
            onSelectVariable={setInsertVariable}
          />
        </Box>
      </Stack>
    </form>
  );
};
