import { useFetchMessageTemplates } from '../../../../api/automator/emails/useFetchMessageTemplates';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import Stack from '@mui/material/Stack';
import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { ReturnRecoverability } from '../../../../domain/automator/ReturnRecoverability';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import MessageTemplate from '../../../../domain/automator/MessageTemplate';
import ActionButton from '../../../../components/ActionButton';
import { MessageTemplateType } from '../../../../domain/automator/MessageTemplateType';
import { MessageTemplateSelect } from './MessageTemplateSelect';

interface MessageTemplateSelectionProps {
  type: MessageTemplateType;
  phase: ThreadPhase;
  recoverability: ReturnRecoverability | null;
  onSelect: (messageTemplate: MessageTemplate) => void;
  selected: MessageTemplate | null;
}

export const MessageTemplateSelection = ({
  type,
  phase,
  recoverability,
  onSelect,
  selected,
}: MessageTemplateSelectionProps) => {
  const { data: messageTemplates } = useFetchMessageTemplates(type, phase, recoverability);
  const createMessageTemplateDialog = useOpenDialog(DialogId.CREATE_MESSAGE_TEMPLATE);

  const { t } = useTranslation();

  return (
    <Stack
      gap={1}
      direction="column"
      justifyContent="space-between"
    >
      <Stack
        gap={1}
        direction="column"
      >
        <Typography variant={'h6'}>
          {t(tokens.automator.resolutions.dialogs.send_email.email_template)}
        </Typography>

        {messageTemplates?.messageTemplates.map((messageTemplate, index) => (
          <MessageTemplateSelect
            key={index}
            messageTemplate={messageTemplate}
            onSelect={onSelect}
            selected={selected}
          />
        ))}
      </Stack>

      <ActionButton
        label={tokens.automator.returns.create_email_template}
        onClick={() =>
          createMessageTemplateDialog({
            phase: phase,
            recoverability: recoverability,
            type: type,
            onCreate: onSelect,
          })
        }
        variant="outlined"
      />
    </Stack>
  );
};
