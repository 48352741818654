import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { CreateReturnForm } from '../components/CreateReturnForm';

export const CreateReturnDialog = () => {
  const id = DialogId.CREATE_RETURN;
  const { isOpen } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.resolutions.dialogs.send_email.dialog_title)}
        >
          <CreateReturnForm onClick={closeDialog} />
        </ApplicationDialog>
      )}
    </>
  );
};
