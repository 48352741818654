import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';
import { CountryCode } from '../../domain/automator/CountryCode';

export const ProductRanksSearchTermsQueryKey = (
  productId: number,
  startDate: Date,
  endDate: Date,
  countryCode?: CountryCode | null
) => {
  return (
    EndpointQueryKey.PRODUCT_RANKS_SEARCH_TERMS +
    '_' +
    productId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate) +
    '_' +
    countryCode
  );
};
