import { RETURN_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/Returns';
import { ReturnRecoverability } from '../../../domain/automator/ReturnRecoverability';
import { OrderSummaryQueryKey } from '../../queryKeys/OrderSummaryQueryKey';

export const useUpdateReturnItemRecoverability = (returnItemId: number) => {
  const url = useAutomatorApiAccountUrl(
    RETURN_ITEMS_PATH + '/' + returnItemId + `/update-recoverability`
  );

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns, UpdateReturnItemRecoverabilityData>(
    url,
    [OrderSummaryQueryKey(undefined)],
    undefined,
    onSuccess
  );
};

export interface UpdateReturnItemRecoverabilityData {
  recoverability: ReturnRecoverability;
}
