import { RETAILERS_PATH } from '../automatorApiPaths';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import Retailer from '../../../domain/automator/Retailer';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';

export interface CreateRetailerData {
  client_id: string;
  secret: string;
}

export const useCreateRetailer = () => {
  const url = useAutomatorApiAccountUrl(RETAILERS_PATH);
  return useAuthorizedPost<Retailer, CreateRetailerData>(url, [EndpointQueryKey.RETAILERS]);
};
