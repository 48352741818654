import { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useCreateRetailer } from '../../../../api/automator/retailers/useCreateRetailer';
import ActionButton from '../../../../components/ActionButton';
import { toast } from 'react-hot-toast';

interface AddRetailerFormProps {
  onClick?: () => void;
}

const AddRetailerForm = ({ onClick }: AddRetailerFormProps) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useCreateRetailer();

  const [formData, setFormData] = useState({
    client_id: '',
    secret: '',
  });

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const doAddRetailer = () => {
    mutate(formData, {
      onSuccess: () => {
        toast.success(tokens.automator.retailers.add_retailer.add_retailer_success);
        onClick?.();
      },
    });
  };

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap={2}
    >
      <Typography
        variant="h6"
        gutterBottom
      >
        {t(tokens.automator.retailers.add_retailer.add_retailer_explanation)}
      </Typography>

      <TextField
        fullWidth
        label={t(tokens.automator.retailers.add_retailer.client_id)}
        name="client_id"
        value={formData.client_id}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />

      <TextField
        fullWidth
        label={t(tokens.automator.retailers.add_retailer.secret)}
        name="secret"
        value={formData.secret}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        type="password"
      />

      <ActionButton
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          doAddRetailer();
        }}
        isLoading={isLoading}
        label={t(tokens.common.add)}
      />
    </Box>
  );
};

export default AddRetailerForm;
