import { EndpointQueryKey } from './EndpointQueryKey';
import { ThreadPhase } from '../../domain/automator/ThreadPhase';
import { ReturnRecoverability } from '../../domain/automator/ReturnRecoverability';
import { MessageTemplateType } from '../../domain/automator/MessageTemplateType';

export const MessageTemplatesQueryKey = (
  type: MessageTemplateType,
  phase: ThreadPhase | null,
  returnRecoverability?: ReturnRecoverability | null
) => {
  return EndpointQueryKey.MESSAGE_TEMPLATE + '_' + type + '_' + phase + '_' + returnRecoverability;
};
