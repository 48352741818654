import { EMAIL_RULES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import EmailRules from '../../../domain/automator/EmailRules';
import { EmailRulesQueryKey } from '../../queryKeys/EmailRulesQueryKey';

export const useDisableEmailRule = (emailRuleId: number) => {
  const url = useAutomatorApiAccountUrl(EMAIL_RULES_PATH + `/${emailRuleId}` + '/disable');
  return useAuthorizedPost<EmailRules, undefined>(url, [EmailRulesQueryKey()]);
};
