import { Switch, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import Offer from '../../../../domain/automator/Offer';
import { useState } from 'react';
import { DeliveryCodeDropdown } from './DeliveryCodeDropdown';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useUpdateOffer } from '../../../../api/automator/offers/useUpdateOffer';
import { DeliveryCode } from '../../../../domain/automator/DeliveryCode';
import CurrencyTextField from '../../shared/CurrencyTextField';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { ActionIcon } from './ActionIcon';

type UpdateOfferFormProps = {
  onSave?: () => void;
  productId: number;
  offer: Offer;
};

export const UpdateOfferForm = ({ productId, offer, onSave }: UpdateOfferFormProps) => {
  const [unitPrice, setUnitPrice] = useState(
    offer.bundlePrices.find((bundlePrice) => bundlePrice.quantity === 1)!.unitPriceInCents
  );

  const [stockAmount, setStockAmount] = useState(offer.stock.amount || 0);

  const [deliveryCode, setDeliveryCode] = useState(
    offer.fulfillment.deliveryCode || DeliveryCode.A1_2D
  );

  const [isListed, setIsListed] = useState(!offer.isOnHoldByRetailer);

  const { t } = useTranslation();

  const { mutate: updateSettings, isLoading } = useUpdateOffer(offer.id, productId);

  const doUpdate = () => {
    const bundlePrices = offer.bundlePrices.filter((bundlePrice) => bundlePrice.quantity !== 1);

    bundlePrices.push({
      quantity: 1,
      unitPriceInCents: unitPrice,
    });

    const data = {
      is_on_hold_by_retailer: !isListed,
      delivery_code: deliveryCode,
      stock_amount: stockAmount,
      bundle_prices: bundlePrices,
    };

    updateSettings(data, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.orders.dialogs.update_offer.offer_updated));
        onSave?.();
      },
    });
  };

  return (
    <Stack
      key={offer.id}
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={4}
    >
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
      >
        <RetailerLooksIcon retailerId={offer.retailer.id} />
        <Typography
          fontSize={14}
          variant="body1"
        >
          {offer.retailer.name}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        gap={1}
        alignItems="center"
      >
        <CurrencyTextField
          value={unitPrice}
          onChange={(cents: number) => setUnitPrice(cents)}
          textFieldProps={{ variant: 'outlined', size: 'small', style: { width: 100 } }}
        />

        <Stack direction="row">
          <ActionIcon
            iconSize={20}
            icon={<KeyboardDoubleArrowUpIcon />}
            onClick={() => setUnitPrice(unitPrice + 100)}
            tooltip={t(tokens.automator.orders.dialogs.update_offer.one_euro_up)}
          />
          <ActionIcon
            iconSize={20}
            icon={<KeyboardDoubleArrowDownIcon />}
            onClick={() => setUnitPrice(unitPrice - 100)}
            tooltip={t(tokens.automator.orders.dialogs.update_offer.one_euro_up)}
          />
        </Stack>
      </Stack>

      <Stack
        direction={'row'}
        gap={2}
        alignItems={'center'}
      >
        <TextField
          label={t(tokens.automator.orders.dialogs.update_offer.stock_amount)}
          size="small"
          variant="outlined"
          value={stockAmount}
          style={{ width: 100 }}
          onChange={(e) => setStockAmount(Number(e.target.value))}
        />

        <TextField
          label={t(tokens.automator.orders.dialogs.update_offer.corrected_stock)}
          size="small"
          disabled
          variant="outlined"
          style={{ width: 100 }}
          value={offer.stock.correctedStock}
        />
      </Stack>
      <DeliveryCodeDropdown
        selected={deliveryCode!}
        onSelect={setDeliveryCode}
      />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Typography>{t(tokens.common.off)}</Typography>
        <Switch
          checked={isListed}
          onChange={() => setIsListed(!isListed)}
          inputProps={{ 'aria-label': 'ant design' }}
        />

        <Typography>{t(tokens.common.on)}</Typography>
      </Stack>
      <ActionButton
        onClick={doUpdate}
        isLoading={isLoading}
        label={t(tokens.automator.orders.dialogs.update_offer.update_offers)}
      />
    </Stack>
  );
};
