import Stack from '@mui/material/Stack';
import { CountryCode } from '../../../../../domain/automator/CountryCode';
import { FlagIcon } from '../../../../automator/orders/components/FlagIcon';

interface CountryCodeSelectProps {
  onSelect: (country: CountryCode) => void;
  countryCode: CountryCode | null;
}

const CountrySelect = ({ onSelect, countryCode }: CountryCodeSelectProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      key={2}
    >
      <FlagIcon
        size={20}
        onClick={onSelect}
        countryCode={CountryCode.NL}
        isActive={countryCode == null || countryCode === CountryCode.NL}
      />

      <FlagIcon
        size={20}
        onClick={onSelect}
        countryCode={CountryCode.BE}
        isActive={countryCode == null || countryCode === CountryCode.BE}
      />
    </Stack>
  );
};

export default CountrySelect;
