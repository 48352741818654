import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import ButtonGroup from '@mui/material/ButtonGroup';

interface TemplateVariableToggleButtonsProps {
  selected: TemplateVariableToggleType;
  onSelect: (select: TemplateVariableToggleType) => void;
}

export enum TemplateVariableToggleType {
  VARIABLES = 'VARIABLES',
  ORDER_TEMPLATES = 'ORDER_TEMPLATES',
  SHIPMENT_TEMPLATES = 'SHIPMENT_TEMPLATES',
  RETURN_TEMPLATES = 'RETURN_TEMPLATES',
}

export const TemplateVariableToggleButtons = ({
  selected,
  onSelect,
}: TemplateVariableToggleButtonsProps) => {
  const { t } = useTranslation();

  return (
    <ButtonGroup
      variant="outlined"
      color="info"
      size="small"
      sx={{ height: 'auto' }}
    >
      <ActionButton
        color="info"
        variant={selected == TemplateVariableToggleType.ORDER_TEMPLATES ? 'contained' : 'outlined'}
        onClick={() => onSelect(TemplateVariableToggleType.ORDER_TEMPLATES)}
        label={t(tokens.automator.resolutions.dialogs.send_email.order_templates)}
        size="small"
      />
      <ActionButton
        color="info"
        variant={
          selected == TemplateVariableToggleType.SHIPMENT_TEMPLATES ? 'contained' : 'outlined'
        }
        onClick={() => onSelect(TemplateVariableToggleType.SHIPMENT_TEMPLATES)}
        label={t(tokens.automator.resolutions.dialogs.send_email.shipment_templates)}
        size="small"
      />
      <ActionButton
        color="info"
        variant={selected == TemplateVariableToggleType.RETURN_TEMPLATES ? 'contained' : 'outlined'}
        onClick={() => onSelect(TemplateVariableToggleType.RETURN_TEMPLATES)}
        label={t(tokens.automator.resolutions.dialogs.send_email.return_templates)}
        size="small"
      />
      <ActionButton
        color="info"
        variant={selected == TemplateVariableToggleType.VARIABLES ? 'contained' : 'outlined'}
        onClick={() => onSelect(TemplateVariableToggleType.VARIABLES)}
        label={t(tokens.automator.resolutions.email_variables.email_variables)}
        size="small"
      />
    </ButtonGroup>
  );
};
