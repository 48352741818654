import { MESSAGE_THREADS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ThreadMessagesQueryKey } from '../../queryKeys/ThreadMessagesQueryKey';
import { ResolutionDossiersQueryKey } from '../resolutions/ResolutionDossiersQueryKey';
import { ResolutionDossierStatus } from '../../../domain/automator/ResolutionDossierStatus';

export const useSendMessageThreadReply = (messageThreadId: number) => {
  const url = useAutomatorApiAccountUrl(MESSAGE_THREADS_PATH + '/' + messageThreadId + `/reply`);

  return useAuthorizedPost<undefined, SendMessageThreadReplyData>(url, [
    ThreadMessagesQueryKey(messageThreadId),
    ResolutionDossiersQueryKey(ResolutionDossierStatus.OPEN), // needed to refresh the bol thread reply time
  ]);
};

export interface SendMessageThreadReplyData {
  email_template_id?: number;
  body: string;
}
