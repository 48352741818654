import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';
import { CountryCode } from '../../domain/automator/CountryCode';

export const ProductRanksQueryKey = (
  startDate: Date,
  endDate: Date,
  productId?: number,
  categoryId?: number,
  productRankSearchTermId?: number,
  countryCode?: CountryCode | null
) => {
  return (
    EndpointQueryKey.PRODUCT_RANKS +
    '_' +
    productId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate) +
    '_' +
    (categoryId || '') +
    '_' +
    (productRankSearchTermId || '') +
    '_' +
    countryCode
  );
};
