import ResolutionCase from '../../../../domain/automator/ResolutionCase';
import Stack from '@mui/material/Stack';
import ReturnResolutionCaseActions from './ReturnResolutionCaseActions';
import * as React from 'react';
import { OpenStartShipmentEmailThreadDialogButton } from '../../shipments/components/OpenStartShipmentEmailThreadDialogButton';
import { OpenStartOrderItemEmailThreadDialogButton } from '../../orders/components/OpenStartOrderItemEmailThreadDialogButton';
import { OpenStartReturnItemEmailThreadDialogButton } from '../../returns/components/OpenStartReturnItemEmailThreadDialogButton';
import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { useFetchThreadMessages } from '../../../../api/automator/emails/useFetchThreadMessages';
import OpenCreateResendDialogButton from './OpenCreateResendDialogButton';
import { ActionType } from '../../orders/components/copyButtons/ActionType';
import { OpenSendEmailThreadReplyButton } from './OpenSendEmailThreadReplyButton';

interface ResolutionCaseActionsProps {
  resolutionCase: ResolutionCase;
  hasOpenResend: boolean;
}

const ResolutionCaseActions = ({ resolutionCase, hasOpenResend }: ResolutionCaseActionsProps) => {
  const { data: emailMessages, isLoading } = useFetchThreadMessages(
    resolutionCase.messageThread.id
  );

  if (isLoading || !emailMessages) {
    return <>Loading...</>;
  }

  const forwardBody = emailMessages.messages.map((emailMessage) => emailMessage.body).join('\n\n');

  return (
    <Stack
      direction={'row'}
      gap={0.5}
      justifyContent="space-between"
      alignItems="center"
      paddingX={2}
      paddingY={1}
    >
      <Stack
        gap={2}
        direction="row"
        alignItems="center"
      >
        <OpenSendEmailThreadReplyButton messageThread={resolutionCase.messageThread} />

        {resolutionCase.messageThread.phase == ThreadPhase.ORDER && (
          <OpenStartOrderItemEmailThreadDialogButton
            body={forwardBody}
            order={resolutionCase.messageThread.order}
            orderItemId={resolutionCase.messageThread.orderItemId!}
            type={ActionType.BUTTON}
          />
        )}

        {resolutionCase.messageThread.phase == ThreadPhase.SHIPMENT && (
          <OpenStartShipmentEmailThreadDialogButton
            body={forwardBody}
            shipment={resolutionCase.messageThread.shipment!}
            type={ActionType.BUTTON}
          />
        )}

        {resolutionCase.messageThread.phase == ThreadPhase.RETURN && (
          <OpenStartReturnItemEmailThreadDialogButton
            body={forwardBody}
            returns={resolutionCase.messageThread.returns}
            returnItemId={resolutionCase.messageThread.returnItemId!}
            type={ActionType.BUTTON}
          />
        )}

        {!hasOpenResend && (
          <OpenCreateResendDialogButton orderId={resolutionCase.messageThread.order.id} />
        )}
      </Stack>

      {resolutionCase.messageThread.returnItemId && (
        <ReturnResolutionCaseActions
          resolutionCaseId={resolutionCase.id}
          returnItemId={resolutionCase.messageThread.returnItemId}
          hasOpenResend={hasOpenResend}
        />
      )}
    </Stack>
  );
};

export default ResolutionCaseActions;
