import Typography from '@mui/material/Typography';
import { tss } from 'tss-react/mui-compat';
import ShipmentDetails from '../../../../domain/automator/ShipmentDetails';
import Box from '@mui/material/Box';
import { PickupPointIcon } from '../../orders/components/PickupPointIcon';

interface ShipmentRecipientProps {
  shipmentDetails: ShipmentDetails;
  pickupPoint?: boolean;
}

export const ShipmentRecipient = ({ shipmentDetails, pickupPoint }: ShipmentRecipientProps) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={'0.45rem'}
        alignItems={'center'}
      >
        <Typography
          variant="subtitle1"
          className={cx(classes.shipmentRecipientPerson)}
        >
          {shipmentDetails.firstName} {shipmentDetails.surname}
        </Typography>
        {pickupPoint && <PickupPointIcon />}
      </Box>

      {shipmentDetails.deliveryPhoneNumber && (
        <Typography variant="body2">{shipmentDetails.deliveryPhoneNumber}</Typography>
      )}
    </Box>
  );
};

const useStyles = tss.create(() => ({
  shipmentRecipientPerson: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.1rem',
  },
}));
