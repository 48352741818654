import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { ReturnRecoverability } from '../../../../domain/automator/ReturnRecoverability';
import { tokens } from '../../../../locales/translationTokens';
import { DropdownSelect } from '../../shared/DropdownSelect';
import MessageTemplate from '../../../../domain/automator/MessageTemplate';
import { useFetchMessageTemplates } from '../../../../api/automator/emails/useFetchMessageTemplates';
import { MessageTemplateType } from '../../../../domain/automator/MessageTemplateType';

interface MessageTemplatesDropdownProps {
  type: MessageTemplateType;
  phase: ThreadPhase | null;
  returnRecoverability: ReturnRecoverability | null;
  onClick?: (messageTemplate: MessageTemplate) => void;
  value: MessageTemplate | null;
}

export const MessageTemplatesDropdown = ({
  type,
  phase,
  returnRecoverability,
  onClick,
  value,
}: MessageTemplatesDropdownProps) => {
  const { data: messageTemplates } = useFetchMessageTemplates(type, phase, returnRecoverability);
  const createMessageTemplateDialog = useOpenDialog(DialogId.CREATE_MESSAGE_TEMPLATE);

  const { t } = useTranslation();

  const createMessageTemplateId = 0;

  const options = [
    { key: createMessageTemplateId, value: t(tokens.automator.returns.create_email_template) },
    ...(messageTemplates?.messageTemplates.map((template) => ({
      key: template.id,
      value: template.name,
    })) || []),
  ];

  const onSelect = (id: number) => {
    if (id == createMessageTemplateId) {
      createMessageTemplateDialog({
        type: type,
        phase: phase,
        recoverability: returnRecoverability,
        onCreate: (messageTemplate: MessageTemplate) => {
          onClick?.(messageTemplate);
        },
      });
    }

    const messageTemplate = messageTemplates?.messageTemplates.find(
      (template) => template.id === id
    );

    if (!messageTemplate) return;

    onClick?.(messageTemplate);
  };

  return (
    <DropdownSelect
      options={options}
      onSelect={onSelect}
      selected={value?.id}
      label={tokens.automator.resolutions.dialogs.send_email.email_template}
    />
  );
};
