import Stack from '@mui/material/Stack';
import MessageThreadView from './MessageThreadView';
import ResolutionCaseActions from './ResolutionCaseActions';
import { MessageTemplateSelection } from './MessageTemplateSelection';
import { MessageTemplateType } from '../../../../domain/automator/MessageTemplateType';
import { useFetchResolutionCase } from '../../../../api/automator/resolutions/useFetchResolutionCase';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ResolutionCaseInfoCard } from './ResolutionCaseInfoCard';

interface ResolutionCaseViewProps {
  resolutionCaseId: number;
  hasOpenResend: boolean;
}

export const ResolutionCaseView = ({
  resolutionCaseId,
  hasOpenResend,
}: ResolutionCaseViewProps) => {
  const { data: resolutionCase, isLoading } = useFetchResolutionCase(resolutionCaseId);

  const openReplyDialog = useOpenDialog(DialogId.SEND_MESSAGE_THREAD_REPLY);

  if (isLoading || !resolutionCase) {
    return <>...loading</>;
  }

  return (
    <Stack
      direction="row"
      paddingTop={2}
    >
      <Stack
        width="60%"
        direction="column"
      >
        <ResolutionCaseActions
          resolutionCase={resolutionCase}
          hasOpenResend={hasOpenResend}
        />
        <MessageThreadView resolutionCase={resolutionCase} />
      </Stack>

      <Stack
        paddingTop={1}
        paddingBottom={1}
        paddingX={2}
        minWidth={300}
        gap={2}
        direction="row"
        alignItems="flex-start"
      >
        <MessageTemplateSelection
          type={MessageTemplateType.MANUAL}
          phase={resolutionCase.messageThread.phase}
          recoverability={null}
          selected={null}
          onSelect={(messageTemplate) => {
            openReplyDialog({
              messageThread: resolutionCase.messageThread,
              messageTemplate: messageTemplate,
            });
          }}
        />

        <ResolutionCaseInfoCard resolutionCase={resolutionCase} />
      </Stack>
    </Stack>
  );
};
