import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import Retailer from '../../../../domain/automator/Retailer';
import { UpdateRetailerAddressForm } from '../components/UpdateRetailerAddressForm';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';

export const UpdateRetailerAddressDialog = () => {
  const id = DialogId.UPDATE_RETAILER_ADDRESS;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'md'}
          title={t(tokens.automator.retailers.update_retailer_address.update_retailer_address)}
        >
          <UpdateRetailerAddressForm
            onSave={closeDialog}
            retailer={data.retailer}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateRetailerAddressDialogData {
  retailer: Retailer;
}
