import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';
import { TableLoading } from '../../../../../components/TableLoading';
import { useEffect, useState } from 'react';
import Category from '../../../../../domain/pd/Category';
import SearchTerm from '../../../../../domain/pd/SearchTerm';
import Stack from '@mui/material/Stack';
import { useFetchProductRankCategories } from '../../../../../api/pd/productPerformances/useFetchProductRankCategories';
import { useFetchProductRankSearchTerms } from '../../../../../api/pd/productPerformances/useFetchProductRankSearchTerms';
import { ProductRankChart } from './charts/ProductRankChart';
import { ProductRankSearchTermCategoryListTable } from './tables/ProductRankSearchTermCategoryListTable';
import { LatestDays } from './LatestDaysDropdownSelect';
import getStartDateByLatestDays from '../../../../../helpers/getLatestDateByLatestDays';
import getYesterday from '../../../../../helpers/getYesterday';
import { CountryCode } from '../../../../../domain/automator/CountryCode';
import { DeviationsChart } from './charts/DeviationChart';
import { useFetchPerformanceNotes } from '../../../../../api/pd/notes/useFetchPerformanceNotes';
import { UpdatePerformanceNoteForm } from './forms/UpdatePerformanceNoteForm';
import { PerformanceNoteType } from '../../../../../domain/pd/PerformanceNoteType';
import { CompetitorProductPerformancesCard } from './CompetitorProductPerformancesCard';

interface ProductPerformancesTabProps {
  storeId: number;
  productId: number;
  latestDays: LatestDays;
  countryCode: CountryCode | null;
}

export const ProductPerformancesTab = ({
  storeId,
  productId,
  latestDays,
  countryCode,
}: ProductPerformancesTabProps) => {
  const [category, setCategory] = useState<Category | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<SearchTerm | undefined>(undefined);

  const startDate = getStartDateByLatestDays(latestDays);

  const { data: productRankCategories, isLoading: isLoadingCategories } =
    useFetchProductRankCategories(productId, startDate, getYesterday(), countryCode);
  const { data: productRankSearchTerms, isLoading: isLoadingSearchTerms } =
    useFetchProductRankSearchTerms(productId, startDate, getYesterday(), countryCode);

  const { data: performanceNotes, isLoading: isLoadingPerformanceNotes } =
    useFetchPerformanceNotes(productId);

  const { t } = useTranslation();

  useEffect(() => {
    if (searchTerm) {
      setSearchTerm(undefined);
    }

    if (category) {
      setCategory(undefined);
    }
  }, [productId]);

  useEffect(() => {
    if (category && searchTerm) {
      setSearchTerm(undefined);
    }
  }, [category]);

  useEffect(() => {
    if (category && searchTerm) {
      setCategory(undefined);
    }
  }, [searchTerm]);

  if (isLoadingCategories || isLoadingSearchTerms || isLoadingPerformanceNotes) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  return (
    <Stack
      direction="column"
      gap={1}
    >
      {countryCode != null ? (
        <CompetitorProductPerformancesCard
          productId={productId}
          countryCode={countryCode!}
          latestDays={latestDays}
        />
      ) : null}

      <DeviationsChart
        storeId={storeId}
        latestDays={latestDays}
        countryCode={countryCode}
        searchTerm={searchTerm}
      />

      <ProductRankChart
        productId={productId}
        latestDays={latestDays}
        categoryId={category?.id}
        searchTermId={searchTerm?.id}
        countryCode={countryCode}
      />

      <UpdatePerformanceNoteForm
        performanceNote={
          performanceNotes!.performanceNotes.find(
            (note) => note.type === PerformanceNoteType.PRODUCT_PERFORMANCES
          )!
        }
      />

      <ProductRankSearchTermCategoryListTable
        productRankCategories={productRankCategories!.productRankCategories}
        productRankSearchTerms={productRankSearchTerms!.productRankSearchTerms}
        selectCategory={setCategory}
        selectSearchTerm={setSearchTerm}
        selectedCategory={category}
        selectedSearchTerm={searchTerm}
      />
    </Stack>
  );
};
