import { Tooltip } from '@mui/material';
import { ProductThumbnail } from './ProductThumbnail';

interface TooltippedProductThumbnailProps {
  thumbnailUrl?: string;
  productTitle: string;
  height: number;
  onClick?: () => void;
}

export const TooltippedProductThumbnail = ({
  thumbnailUrl,
  productTitle,
  height,
  onClick,
}: TooltippedProductThumbnailProps) => {
  return (
    <Tooltip
      enterDelay={200}
      enterNextDelay={200}
      title={productTitle}
    >
      <div>
        <ProductThumbnail
          onClick={onClick}
          height={height}
          thumbnailUrl={thumbnailUrl}
        />
      </div>
    </Tooltip>
  );
};
