import Alert from '@mui/material/Alert';

interface InfoAlertProps {
  onClose?: () => void;
  children?: React.ReactNode;
}

export const InfoAlert = ({ onClose, children }: InfoAlertProps) => {
  return (
    <Alert
      onClose={onClose}
      sx={{ backgroundColor: 'rgb(229, 246, 253)', color: 'rgb(1, 67, 97)' }}
      variant="standard"
      severity="info"
    >
      {children}
    </Alert>
  );
};
