import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { LabelType } from '../../../../domain/automator/LabelType';
import OpenStartPickSessionDialogButton from './OpenStartPickSessionDialogButton';
import SetSelectedOrdersToLabelTypeButton from './SetSelectedOrdersToLabelTypeButton';
import Order from '../../../../domain/automator/Order';
import { DistributionParty } from '../../../../domain/automator/DistributionParty';
import { TransporterCode } from '../../../../domain/automator/TransporterCode';
import { LabelGeneration } from '../../../../domain/automator/LabelGeneration';
import { CountryCode } from '../../../../domain/automator/CountryCode';

interface BulkOrderActionsProps {
  selectedOrders: Order[];
  selectedSomeOrders: boolean;
  selectedSomeNotAllOrders: boolean;
  selectedAllOrders: boolean;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  distributionParty: DistributionParty;
  top3TransporterCodes?: TransporterCode[];
}

export const BulkOrderActions = ({
  selectedOrders,
  onSelectAll,
  onDeselectAll,
  selectedSomeOrders,
  selectedAllOrders,
  selectedSomeNotAllOrders,
  distributionParty,
  top3TransporterCodes,
}: BulkOrderActionsProps) => {
  const allSelectedOrdersAreBelgiumNonVVB =
    selectedOrders &&
    !selectedOrders.some((order) => {
      return (
        order.shipmentDetails.countryCode == CountryCode.NL ||
        order.distributionParty == DistributionParty.BOL
      );
    });

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        //@TODO to tss
        alignItems: 'center',
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.50'),
        display: selectedSomeOrders ? 'flex' : 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        paddingLeft: '18px',
        py: 1,
        zIndex: 10,
      }}
    >
      <Checkbox
        checked={selectedAllOrders}
        indeterminate={selectedSomeNotAllOrders}
        onChange={(event) => {
          if (event.target.checked) {
            onSelectAll?.();
          } else {
            onDeselectAll?.();
          }
        }}
      />

      <OpenStartPickSessionDialogButton
        selectedOrders={selectedOrders}
        distributionParty={distributionParty}
        transporterCode={null}
      />

      {distributionParty == DistributionParty.RETAILER &&
        top3TransporterCodes &&
        top3TransporterCodes.length > 0 &&
        top3TransporterCodes.map((transporterCode) => {
          return (
            <OpenStartPickSessionDialogButton
              key={transporterCode}
              selectedOrders={selectedOrders}
              distributionParty={distributionParty}
              transporterCode={transporterCode}
            />
          );
        })}

      {allSelectedOrdersAreBelgiumNonVVB && (
        <OpenStartPickSessionDialogButton
          selectedOrders={selectedOrders}
          distributionParty={DistributionParty.RETAILER}
          transporterCode={TransporterCode.TNT}
          labelGeneration={LabelGeneration.BUY_FROM_BOL}
        />
      )}

      {distributionParty == DistributionParty.BOL && (
        <>
          <SetSelectedOrdersToLabelTypeButton
            labelType={LabelType.MAILBOX}
            selectedOrderIds={selectedOrders.map((order) => order.id)}
          />
          <SetSelectedOrdersToLabelTypeButton
            labelType={LabelType.PARCEL}
            selectedOrderIds={selectedOrders.map((order) => order.id)}
          />
        </>
      )}
    </Stack>
  );
};
