import { Tooltip } from '@mui/material';
import Shipment from '../../../../domain/automator/Shipment';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { useUpdateShipmentIsResentAfterReturn } from '../../../../api/automator/shipments/useUpdateShipmentIsResentAfterReturn';

interface IsResentAfterReturnCheckboxProps {
  shipment: Shipment;
}

const IsResentAfterReturnCheckbox = ({ shipment }: IsResentAfterReturnCheckboxProps) => {
  const { mutate: updateIsResentAfterReturn } = useUpdateShipmentIsResentAfterReturn(shipment.id);

  const { t } = useTranslation();

  const onUpdate = (isResentAfterReturn: boolean) => {
    //setIsResentAfterReturn(isResentAfterReturn);
    updateIsResentAfterReturn({ is_resent_after_return: isResentAfterReturn });
  };

  return (
    <Tooltip
      enterDelay={2000}
      enterNextDelay={2000}
      title={t(tokens.automator.shipments.is_resent_after_return_tooltip)}
    >
      <Checkbox
        onClick={() => {
          onUpdate(!shipment.isResentAfterReturn);
        }}
        checked={shipment.isResentAfterReturn}
      />
    </Tooltip>
  );
};

export default IsResentAfterReturnCheckbox;
