import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { tokens } from '../../locales/translationTokens';
import { TutorialAlert } from './TutorialAlert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { TutorialKey, TutorialService } from './TutorialService';
import { usePaths } from '../../routes/usePaths';
import { useRouter } from '../../../devias/src/hooks/use-router';
import { useFetchRetailers } from '../../api/automator/retailers/useFetchRetailers';

export const OrdersTutorial = () => {
  const { t } = useTranslation();

  const showWithoutRetailers = !TutorialService.isUsed(TutorialKey.ORDERS_WITHOUT_RETAILER);

  const { data: retailers } = useFetchRetailers();

  const paths = usePaths();
  const router = useRouter();

  return (
    <Box>
      {showWithoutRetailers && (
        <TutorialAlert tutorialKey={TutorialKey.ORDERS_WITHOUT_RETAILER}>
          <Stack spacing={3}>
            <Box>{t(tokens.automator.orders.tutorials.without_retailer_message)}</Box>
            <Button
              sx={{ width: '300px' }}
              color="success"
              size="small"
              variant="contained"
              onClick={() => router.push(paths.automator.retailers)}
            >
              {t(tokens.automator.orders.tutorials.without_retailer_button)}
            </Button>
          </Stack>
        </TutorialAlert>
      )}

      {!showWithoutRetailers && retailers && retailers.length > 0 && (
        <Stack gap={'1rem'}>
          <TutorialAlert tutorialKey={TutorialKey.ORDERS_WITH_RETAILER}>
            <Stack spacing={3}>
              <Box> {t(tokens.automator.orders.tutorials.with_retailer_message)}</Box>
            </Stack>
          </TutorialAlert>

          <TutorialAlert tutorialKey={TutorialKey.ORDERS_WITH_RETAILER}>
            <Stack spacing={3}>
              <Box> {t(tokens.automator.orders.tutorials.internal_reference_info)}</Box>
            </Stack>
          </TutorialAlert>
        </Stack>
      )}
    </Box>
  );
};
