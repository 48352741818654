import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { DistributionParty } from '../../../../domain/automator/DistributionParty';
import Order from '../../../../domain/automator/Order';
import { tokens } from '../../../../locales/translationTokens';
import {
  TransporterCode,
  TransporterCodeTokenMap,
} from '../../../../domain/automator/TransporterCode';
import { useTranslation } from 'react-i18next';
import { LabelGeneration } from '../../../../domain/automator/LabelGeneration';

interface OpenStartPickSessionDialogButtonProps {
  selectedOrders: Order[];
  distributionParty: DistributionParty;
  transporterCode: TransporterCode | null;
  labelGeneration?: LabelGeneration;
}

const OpenStartPickSessionDialogButton = ({
  selectedOrders,
  distributionParty,
  transporterCode,
  labelGeneration,
}: OpenStartPickSessionDialogButtonProps) => {
  const openDialog = useOpenDialog(DialogId.CONFIRM_CREATE_PICK_SESSION);

  const transporterName = TransporterCodeTokenMap[transporterCode!];

  const { t } = useTranslation();

  const generateLabel = () => {
    if (distributionParty == DistributionParty.BOL) {
      return t(tokens.automator.orders.buttons.start_vvb_pick_session);
    } else {
      if (labelGeneration == LabelGeneration.BUY_FROM_BOL) {
        return t(tokens.automator.pick_sessions.label_generation.buy_from_bol);
      } else if (transporterCode == null) {
        return t(tokens.automator.orders.buttons.start_non_vvb_pick_session);
      } else {
        return t(transporterName);
      }
    }
  };

  return (
    <ActionButton
      color="primary"
      variant="contained"
      size="small"
      label={generateLabel()}
      onClick={() => {
        openDialog({
          orders: selectedOrders,
          distributionParty: distributionParty,
          transporterCode: transporterCode,
          labelGeneration: labelGeneration,
        });
      }}
    />
  );
};

export default OpenStartPickSessionDialogButton;
