import { Collapse, TableCell, TableRow } from '@mui/material';
import ShipmentsTable from './ShipmentsTable';
import Shipment from '../../../../domain/automator/Shipment';
import LoadShipmentEventsReport from '../../../../domain/automator/LoadShipmentEventsReport';

interface ShipmentPerDayRowProps {
  isExpanded: boolean;
  shipments?: Shipment[];
  loadShipmentEventsReport?: LoadShipmentEventsReport;
  isLoading: boolean;
}

const ShipmentPerDayRow = ({
  isExpanded,
  shipments,
  loadShipmentEventsReport,
  isLoading,
}: ShipmentPerDayRowProps) => {
  return (
    <TableRow>
      <TableCell
        style={{ padding: 0 }}
        colSpan={10}
      >
        <Collapse
          in={isExpanded}
          unmountOnExit
        >
          <ShipmentsTable
            shipments={shipments}
            loadShipmentEventsReport={loadShipmentEventsReport}
            isLoading={isLoading}
          />
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default ShipmentPerDayRow;
