import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { ResendStatus } from '../../../../domain/automator/ResendStatus';

interface ResendStatusChipProps {
  resendStatus: ResendStatus;
}

export const ResendStatusChip = ({ resendStatus }: ResendStatusChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (resendStatus: ResendStatus) => {
    switch (resendStatus) {
      case ResendStatus.OPEN:
        return t(tokens.common.resend_status.open);
      case ResendStatus.HANDLED:
        return t(tokens.common.resend_status.handled);
    }
  };

  const determineColor = (resendStatus: ResendStatus) => {
    switch (resendStatus) {
      case ResendStatus.OPEN:
        return 'info';
      case ResendStatus.HANDLED:
        return 'success';
    }
  };

  return (
    <Chip
      color={determineColor(resendStatus)}
      label={determineLabel(resendStatus)}
    />
  );
};
