import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ReturnItem from '../../../../domain/automator/ReturnItem';
import { HandleReturnItemForm } from '../../shipments/components/HandleReturnItemForm';

export const HandleReturnItemDialog = () => {
  const id = DialogId.HANDLE_RETURN_ITEM;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.returns.dialogs.handle_return_item.dialog_title)}
        >
          <HandleReturnItemForm
            returnItem={data.returnItem}
            type={data.type}
            onHandle={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface HandleReturnItemDialogData {
  returnItem: ReturnItem;
  type: 'ACCEPT' | 'REJECT';
}
