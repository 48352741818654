import Order from '../../../../domain/automator/Order';
import { OrderPlacedDateText } from './OrderPlacedDateText';
import { LatestHandoverDateText } from './LatestHandoverDateText';
import Box from '@mui/material/Box';
import { ExpectedDeliveryDateText } from './ExpectedDeliveryDateText';
import { useFetchAccount } from '../../../../api/automator/account/useFetchAccount';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';

interface BolOrderInfoViewProps {
  order: Order;
}

export const OrderDatesStack = ({ order }: BolOrderInfoViewProps) => {
  const { data: account, isLoading } = useFetchAccount();

  if (!account || isLoading) {
    return <Box />;
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'0.15rem'}
    >
      <Stack
        spacing={1}
        direction="row"
      >
        <Typography variant={'body1'}>{order.bolOrderId}</Typography>
        <RetailerLooksIcon retailerId={order.retailerId} />
      </Stack>

      <OrderPlacedDateText orderPlacedDate={order.orderPlacedDate} />

      {order.latestHandoverDate ? (
        <LatestHandoverDateText
          order={order}
          account={account}
        />
      ) : (
        <ExpectedDeliveryDateText expectedDeliveryDate={order.expectedDeliveryDate} />
      )}
    </Box>
  );
};
