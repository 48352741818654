import Chip from '@mui/material/Chip';
import { TransportEventCode } from '../../../../domain/automator/TransportEventCode';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@untitled-ui/icons-react/build/esm/Check';
import { ShipmentEventLoadingStatus } from '../../../../domain/automator/ShipmentEventLoadingStatus';
import Shipment from '../../../../domain/automator/Shipment';

interface ShipmentStatusChipProps {
  shipment?: Shipment;
  shipmentEventsLoadingStatus: ShipmentEventLoadingStatus;
}

const ShipmentStatusChip = ({ shipment, shipmentEventsLoadingStatus }: ShipmentStatusChipProps) => {
  const { t } = useTranslation();
  const determineColor = (
    shipment?: Shipment
  ): 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' => {
    if (shipment && !shipment.isTrackAndTraceable) {
      return 'default';
    }

    const shipmentStatus = shipment?.latestEvent?.eventCode;

    switch (shipmentStatus) {
      case TransportEventCode.PRE_ANNOUNCED:
        return 'info';
      case TransportEventCode.AT_TRANSPORTER:
        return 'info';
      case TransportEventCode.IN_TRANSIT:
        return 'info';
      case TransportEventCode.INBOUND_COLLECT:
        return 'warning';
      case TransportEventCode.AT_PICK_UP_POINT:
        return 'warning';
      case TransportEventCode.PICKED_UP_AT_PICK_UP_POINT:
        return 'success';
      case TransportEventCode.DELIVERED_AT_CUSTOMER:
        return 'success';
      case TransportEventCode.DELIVERED_AT_NEIGHBOURS:
        return 'success';
      case TransportEventCode.RETURNED_TO_SENDER:
        return 'error';
      default:
        return 'info';
    }
  };

  const determineLabel = (shipment?: Shipment) => {
    if (shipment && !shipment.isTrackAndTraceable) {
      return t(tokens.automator.shipments.status.not_applicable);
    }

    const shipmentStatus = shipment?.latestEvent?.eventCode;

    switch (shipmentStatus) {
      case TransportEventCode.PRE_ANNOUNCED:
        return t(tokens.automator.shipments.status.pre_announced);
      case TransportEventCode.AT_TRANSPORTER:
        return t(tokens.automator.shipments.status.at_transporter);
      case TransportEventCode.IN_TRANSIT:
        return t(tokens.automator.shipments.status.in_transit);
      case TransportEventCode.INBOUND_COLLECT:
        return t(tokens.automator.shipments.status.inbound_collect);
      case TransportEventCode.AT_PICK_UP_POINT:
        return t(tokens.automator.shipments.status.at_pick_up_point);
      case TransportEventCode.PICKED_UP_AT_PICK_UP_POINT:
        return t(tokens.automator.shipments.status.picked_up_at_pick_up_point);
      case TransportEventCode.DELIVERED_AT_CUSTOMER:
        return t(tokens.automator.shipments.status.delivered_at_customer);
      case TransportEventCode.DELIVERED_AT_NEIGHBOURS:
        return t(tokens.automator.shipments.status.delivered_at_neighbours);
      case TransportEventCode.RETURNED_TO_SENDER:
        return t(tokens.automator.shipments.status.returned_to_sender);
      default:
        return t(tokens.automator.shipments.status.waiting_for_events);
    }
  };

  const determineLoadingEventsStatusIcon = (
    shipmentEventsLoadingStatus: ShipmentEventLoadingStatus
  ) => {
    switch (shipmentEventsLoadingStatus) {
      case ShipmentEventLoadingStatus.PENDING:
        return (
          <CircularProgress
            disableShrink
            size={'1.35rem'}
            color="info"
          />
        );

      case ShipmentEventLoadingStatus.IN_PROGRESS:
        return (
          <CircularProgress
            disableShrink
            size={'1.35rem'}
            color="primary"
          />
        );
      case ShipmentEventLoadingStatus.FAILED:
        return <WarningAmberIcon color={'warning'} />;
      case ShipmentEventLoadingStatus.FINISHED:
        return <CheckIcon color={'GREEN'} />;
      default:
        return <></>;
    }
  };

  return (
    <Stack
      height={2}
      gap={1}
      alignItems="center"
      direction="row"
    >
      <Chip
        size="small"
        label={determineLabel(shipment)}
        color={determineColor(shipment)}
      />

      {determineLoadingEventsStatusIcon(shipmentEventsLoadingStatus)}
    </Stack>
  );
};

export default ShipmentStatusChip;
