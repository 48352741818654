interface ProductThumbnailProps {
  thumbnailUrl?: string;
  height: number;
  onClick?: () => void;
}

export const ProductThumbnail = ({ thumbnailUrl, height, onClick }: ProductThumbnailProps) => {
  const placeholderThumbnail = 'https://imageholdr.com/250x200/transparent/757575/fa-barcode';

  const width = height * 1.14583333333;

  return (
    <img
      onClick={onClick}
      style={{
        width: width + 'px',
        height: height + 'px',
        cursor: onClick ? 'pointer' : undefined,
      }}
      src={thumbnailUrl ? thumbnailUrl : placeholderThumbnail}
      onError={(e: any) => {
        e.target.onerror = null;
        e.target.src = placeholderThumbnail;
      }}
      alt=""
    />
  );
};
