import Stack from '@mui/material/Stack';
import { OrderSummaryViewHeader } from './OrderSummaryViewHeader';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Resend from '../../../../domain/automator/Resend';
import { ResendStatusChip } from '../../returns/components/ResendStatusChip';

interface OrderSummaryResendsViewProps {
  resends: Resend[];
}

export const OrderSummaryResendsView = ({ resends }: OrderSummaryResendsViewProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
    >
      <OrderSummaryViewHeader label={t(tokens.automator.order_summary.resends)} />

      {resends.map((resend, index) => {
        return (
          <Stack
            key={index}
            direction="row"
          >
            <ResendStatusChip resendStatus={resend.status} />
          </Stack>
        );
      })}
    </Stack>
  );
};
