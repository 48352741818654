import { useTranslation } from 'react-i18next';
import { TableTabs, TabOption } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';
import Stack from '@mui/material/Stack';
import { ResendStatus } from '../../../../domain/automator/ResendStatus';

interface ResendTabsProps {
  currentTab: ResendStatus;
  setCurrentTab: (tab: ResendStatus) => void;
}

const ResendTabs = ({ currentTab, setCurrentTab }: ResendTabsProps) => {
  const { t } = useTranslation();

  const tabOptions: TabOption<ResendStatus>[] = [];
  tabOptions.push(
    ...[
      {
        label: t(tokens.common.resend_status.open),
        value: ResendStatus.OPEN,
      },
      {
        label: t(tokens.common.resend_status.handled),
        value: ResendStatus.HANDLED,
      },
    ]
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <TableTabs
        value={currentTab}
        onChange={(tab: ResendStatus) => {
          setCurrentTab(tab);
        }}
        tabOptions={tabOptions}
      />
    </Stack>
  );
};

export default ResendTabs;
