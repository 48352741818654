import { RETURNS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/Returns';
import { ReturnItemQueryKey } from '../../queryKeys/ReturnItemQueryKey';
import { OrderSummaryQueryKey } from '../../queryKeys/OrderSummaryQueryKey';
import { ReturnScansQueryKey } from '../../queryKeys/ReturnScansQueryKey';

export const useRefreshReturn = (returnId: number) => {
  const url = useAutomatorApiAccountUrl(RETURNS_PATH + '/' + returnId + `/refresh`);

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns>(
    url,
    [ReturnItemQueryKey(undefined), OrderSummaryQueryKey(undefined), ReturnScansQueryKey()],
    undefined,
    onSuccess
  );
};
