import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import { EmailDocumentsList } from './EmailDocumentsList';
import HtmlEditor from '../../email/components/HtmlEditor';
import { RecipientTypeToggleButtonGroup } from './RecipientTypeToggleButtonGroup';
import Box from '@mui/material/Box';
import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { useGetEmailMessageDraft } from '../../../../store/emailMessageDrafts/useGetEmailMessageDraft';
import { RecipientType } from '../../../../domain/automator/RecipientType';
import { EmailMessageDraft } from '../../../../store/emailMessageDrafts/emailMessageDraftSlice';
import {
  CreateEmailThreadData,
  useCreateEmailThreads,
} from '../../../../api/automator/emails/useCreateEmailThreads';
import { toast } from 'react-hot-toast';
import { EmailPhaseToggleButtonGroup } from './EmailPhaseToggleButtonGroup';
import OrderSummary from '../../../../domain/automator/OrderSummary';
import { EmailThreadAggregateSelection } from './EmailThreadAggregateSelection';
import { TemplateVariableSelection } from './TemplateVariableSelection';
import Chip from '@mui/material/Chip';
import FileUpload from '../../shared/FileUpload';
import FileUploadForm from './FileUploadForm';

interface CreateEmailThreadFormProps {
  onClick: () => void;
  phase: ThreadPhase | null;
  aggregateId: number | null;
  aggregateIds: number[];
  body: string | null;
  orderSummary: OrderSummary | null;
  mode: CreateEmailThreadMode;
}

export enum CreateEmailThreadMode {
  BULK = 'BULK',
  SINGLE = 'SINGLE',
}

export const CreateEmailThreadForm = ({
  phase: initialPhase,
  aggregateId: initialAggregateId,
  aggregateIds,
  onClick,
  body,
  orderSummary,
  mode,
}: CreateEmailThreadFormProps) => {
  const { t } = useTranslation();

  const [aggregateId, setAggregateId] = useState<number | null>(initialAggregateId);
  const [phase, setPhase] = useState<ThreadPhase>(initialPhase || ThreadPhase.ORDER);
  const [aggregateIdError, setAggregateIdError] = useState(false);

  const [insertVariable, setInsertVariable] = useState<string | null>(null);

  const { emailMessageDraft, setEmailMessageDraft } = useGetEmailMessageDraft(aggregateId, phase);

  const determineBody = (newRecipientType: RecipientType) => {
    if (newRecipientType == RecipientType.CUSTOMER) {
      return `Beste [[CUSTOMER_NAME]],<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Partner van Bol.com`;
    }

    if (newRecipientType == RecipientType.BOL) {
      return `Beste Bol,<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Winkelnummer: [[BOL_RETAILER_ID]]`;
    }

    return '';
  };

  const determineSubject = (newRecipientType: RecipientType) => {
    if (newRecipientType == RecipientType.BOL) {
      return '[[BOL_ORDER_ID]] ';
    }

    return '';
  };

  const getDraft = () => {
    if (emailMessageDraft) {
      return emailMessageDraft;
    } else {
      return {
        recipientType: RecipientType.CUSTOMER,
        phase: phase,
        aggregateId: aggregateId,
        messageTemplate: null,
        subject: determineSubject(RecipientType.CUSTOMER),
        body: body || determineBody(RecipientType.CUSTOMER),
        startWithCase: true,
        files: [],
      };
    }
  };

  const onUpdatePhase = (phase: ThreadPhase) => {
    setEmailMessageDraft({
      ...draft,
      phase,
      aggregateId: null,
    });
    setPhase(phase);
    setAggregateId(null);
  };

  const onUpdateRecipientType = (recipientType: RecipientType) => {
    setEmailMessageDraft({
      ...draft,
      body: draft.messageTemplate != null ? draft.body : determineBody(recipientType),
      subject: draft.messageTemplate != null ? draft.subject : determineSubject(recipientType),
      recipientType,
    });
  };

  const { mutate, isLoading } = useCreateEmailThreads(getDraft().phase);

  const canChangePhase = initialPhase == null;

  const draft = getDraft();

  const onCreate = (emailMessageDraft: EmailMessageDraft) => {
    if (mode == CreateEmailThreadMode.SINGLE && !emailMessageDraft.aggregateId) {
      toast.error(
        t(tokens.automator.resolutions.dialogs.send_email.aggregate_id_needs_to_be_selected_error)
      );
      setAggregateIdError(true);
      return;
    }

    const createRequest = (emailMessageDraft: EmailMessageDraft, aggregateId: number) => {
      return {
        order_item_id: emailMessageDraft.phase === ThreadPhase.ORDER ? aggregateId : undefined,
        shipment_id: emailMessageDraft.phase === ThreadPhase.SHIPMENT ? aggregateId : undefined,
        return_item_id: emailMessageDraft.phase === ThreadPhase.RETURN ? aggregateId : undefined,
        email_template_id: emailMessageDraft.messageTemplate?.id,
        subject: emailMessageDraft.subject,
        body: emailMessageDraft.body,
        recipient_type: emailMessageDraft.recipientType,
        phase: emailMessageDraft.phase,
        is_start_with_resolution_case: emailMessageDraft.startWithCase,
        files: emailMessageDraft.files,
      } as CreateEmailThreadData;
    };

    mutate(
      mode == CreateEmailThreadMode.SINGLE
        ? [createRequest(emailMessageDraft, emailMessageDraft.aggregateId!)]
        : aggregateIds.map((aggregateId) => createRequest(emailMessageDraft, aggregateId)),
      {
        onSuccess: async () => {
          setEmailMessageDraft({
            ...draft,
            body: determineBody(RecipientType.CUSTOMER),
            subject: determineSubject(RecipientType.CUSTOMER),
            messageTemplate: null,
            startWithCase: false,
          });
          onClick();
          toast.success(t(tokens.automator.resolutions.dialogs.send_email.email_sent));
        },
      }
    );
  };

  const determineSelectablePhase = (orderSummary: OrderSummary) => {
    const selectablePhases = [ThreadPhase.ORDER];

    if (orderSummary.shipment) {
      selectablePhases.push(ThreadPhase.SHIPMENT);
    }

    if (orderSummary.returns.length > 0) {
      selectablePhases.push(ThreadPhase.RETURN);
    }

    return selectablePhases;
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="row"
        gap={3}
      >
        <Stack
          direction="column"
          gap={1.5}
          width="70%"
        >
          {canChangePhase && (
            <EmailPhaseToggleButtonGroup
              onSelect={onUpdatePhase}
              value={draft.phase}
              selectablePhases={determineSelectablePhase(orderSummary!) || []}
            />
          )}

          {mode == CreateEmailThreadMode.SINGLE && (
            <Box border={aggregateIdError ? 1 : 0}>
              <EmailThreadAggregateSelection
                orderSummary={orderSummary!}
                phase={draft.phase}
                aggregateId={draft.aggregateId}
                setAggregateId={(aggregateId) => {
                  setEmailMessageDraft({
                    ...draft,
                    aggregateId,
                  });
                  setAggregateId(aggregateId);
                  setAggregateIdError(false);
                }}
              />
            </Box>
          )}

          {canChangePhase && <hr />}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <RecipientTypeToggleButtonGroup
              onSelect={onUpdateRecipientType}
              value={draft.recipientType}
              phase={draft.phase}
            />

            {mode == CreateEmailThreadMode.BULK && (
              <Chip
                label={
                  aggregateIds.length +
                  ' ' +
                  t(tokens.automator.resolutions.dialogs.send_email.amount_of_recipients)
                }
                color="success"
              />
            )}
          </Stack>

          <TextField
            id="subject-field"
            label={t(tokens.automator.resolutions.dialogs.send_email.subject)}
            variant="filled"
            fullWidth
            value={draft.subject}
            onChange={(e) => setEmailMessageDraft({ ...draft, subject: e.target.value })}
          />

          <HtmlEditor
            content={draft.body}
            onChange={(body) => {
              if (draft.body === body) {
                return;
              }
              setEmailMessageDraft({ ...draft, body });
            }}
            insertVariable={insertVariable}
          />

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={draft.startWithCase}
                  onChange={(event) =>
                    setEmailMessageDraft({
                      ...draft,
                      startWithCase: event.target.checked,
                    })
                  }
                  name="startWithCase"
                  color="primary"
                />
              }
              label={t(tokens.automator.resolutions.dialogs.send_email.start_with_case)}
            />
          </Box>

          {draft.messageTemplate && draft.messageTemplate!.documents.length > 0 ? (
            <EmailDocumentsList documents={draft.messageTemplate!.documents} />
          ) : (
            <Box marginLeft={-1}>
              <FileUploadForm
                onChange={(fileUploads: FileUpload[]) =>
                  setEmailMessageDraft({
                    ...draft,
                    files: fileUploads,
                  })
                }
                multiple
              />
            </Box>
          )}

          <ActionButton
            icon={<Email />}
            label={t(tokens.automator.resolutions.dialogs.send_email.send_email)}
            onClick={() => onCreate(draft)}
            isLoading={isLoading}
            variant="contained"
            color="primary"
          />
        </Stack>

        <Box width="30%">
          <TemplateVariableSelection
            phase={draft.phase}
            onSelectTemplate={(messageTemplate) => {
              setEmailMessageDraft({
                ...draft,
                messageTemplate: messageTemplate,
                body: messageTemplate.body,
                subject: messageTemplate.subject,
              });
            }}
            selectedTemplate={draft.messageTemplate}
            onSelectVariable={setInsertVariable}
          />
        </Box>
      </Stack>
    </form>
  );
};
