import { CancelOrderItemDialog } from '../pages/automator/orders/components/CancelOrderItemDialog';
import { SettingsDialog } from './settings/SettingsDialog';
import { ChangeOrderLatestHandoverDateDialog } from '../pages/automator/orders/dialogs/ChangeOrderLatestHandoverDateDialog';
import { AddRetailerDialog } from '../pages/automator/retailers/components/AddRetailerDialog';
import { ConfirmCreatePickSessionDialog } from '../pages/automator/retailers/components/ConfirmCreatePickSessionDialog';
import { useAuth } from '../store/auth/useAuth';
import { FirstRetailerCreatedTutorialDialog } from './tutorials/FirstRetailerCreatedTutorialDialog';
import { HandleReturnItemDialog } from '../pages/automator/returns/dialogs/HandleReturnItemDialog';
import { CreateMessageTemplateDialog } from '../pages/automator/resolutions/dialogs/CreateMessageTemplateDialog';
import { CreateReturnDialog } from '../pages/automator/returns/dialogs/CreateReturnDialog';
import { SendEmailThreadReplyDialog } from '../pages/automator/resolutions/dialogs/SendEmailThreadReplyDialog';
import { UpdateOrderNoteDialog } from '../pages/automator/resolutions/dialogs/UpdateOrderNoteDialog';
import { UpdateOfferInternalReferenceDialog } from '../pages/automator/orders/dialogs/UpdateOfferInternalReferenceDialog';
import { CreateResendDialog } from '../pages/automator/resolutions/dialogs/CreateResendDialog';
import { useAccountIdFromUrl } from '../api/useAccountIdFromUrl';
import { CreateProductPurchaseCostsDialog } from '../pages/pd/pages/performances/dialogs/CreateProductPurchaseCostsDialog';
import { CreateProductReorderIntervalDialog } from '../pages/pd/pages/performances/dialogs/CreateProductReorderIntervalDialog';
import { UpdateProductReorderIntervalDialog } from '../pages/pd/pages/performances/dialogs/UpdateProductReorderIntervalDialog';
import { CreateOfferShippingCostsDialog } from '../pages/pd/pages/performances/dialogs/CreateOfferShippingCostsDialog';
import { UpdateOfferShippingCostsDialog } from '../pages/pd/pages/performances/dialogs/UpdateOfferShippingCostsDialog';
import { UpdateProductPurchaseCostsDialog } from '../pages/pd/pages/performances/dialogs/UpdateProductPurchaseCostsDialog';
import { UpdateOfferPerformanceOverwriteDialog } from '../pages/pd/pages/performances/dialogs/UpdateOfferPerformanceOverwriteDialog';
import { CreateOfferPerformanceOverwriteDialog } from '../pages/pd/pages/performances/dialogs/CreateOfferPerformanceOverwriteDialog';
import { UpdateProductPurchaseVolumeDialog } from '../pages/pd/pages/performances/dialogs/UpdateProductPurchaseVolumeDialog';
import { CreateProductPurchaseVolumeDialog } from '../pages/pd/pages/performances/dialogs/CreateProductPurchaseVolumeDialog';
import { CreateManualSeasonalEventDialog } from '../pages/pd/pages/performances/dialogs/CreateManualSeasonalEventDialog';
import { CreateManualPerformanceEventDialog } from '../pages/pd/pages/performances/dialogs/CreateManualPerformanceEventDialog';
import { CreateEmailRuleDialog } from '../pages/automator/email/dialogs/CreateEmailRuleDialog';
import { UpdateMessageTemplateDialog } from '../pages/automator/resolutions/dialogs/UpdateMessageTemplateDialog';
import { UpdateEmailRuleDialog } from '../pages/automator/email/dialogs/UpdateEmailRuleDialog';
import { ViewOrderSummaryDialog } from '../pages/automator/orderSummaries/dialogs/ViewOrderSummaryDialog';
import { CreateEmailThreadDialog } from '../pages/automator/resolutions/dialogs/CreateEmailThreadDialog';
import { ViewReturnScanPhotosDialog } from '../pages/automator/returns/dialogs/ViewReturnScanPhotosDialog';
import { UpdateOffersDialog } from '../pages/automator/orders/dialogs/UpdateOffersDialog';
import { UpdateRetailerAddressDialog } from '../pages/automator/retailers/dialogs/UpdateRetailerAddressDialog';

export const Dialogs = () => {
  const { isAuthenticated } = useAuth();
  const accountId = useAccountIdFromUrl();

  if (!isAuthenticated || !accountId) {
    return null;
  }

  return (
    <>
      {/* Automator */}
      <CancelOrderItemDialog />
      <SettingsDialog />
      <ChangeOrderLatestHandoverDateDialog />
      <AddRetailerDialog />
      <ConfirmCreatePickSessionDialog />
      <FirstRetailerCreatedTutorialDialog />
      <HandleReturnItemDialog />
      <CreateMessageTemplateDialog />
      <CreateReturnDialog />
      <SendEmailThreadReplyDialog />
      <UpdateOrderNoteDialog />
      <UpdateOfferInternalReferenceDialog />
      <CreateResendDialog />
      <CreateEmailRuleDialog />
      <UpdateMessageTemplateDialog />
      <UpdateEmailRuleDialog />
      <ViewOrderSummaryDialog />
      <CreateEmailThreadDialog />
      <ViewReturnScanPhotosDialog />
      <UpdateOffersDialog />
      <UpdateRetailerAddressDialog />

      {/* PD */}
      <CreateProductPurchaseCostsDialog />
      <CreateProductReorderIntervalDialog />
      <CreateOfferShippingCostsDialog />
      <UpdateOfferShippingCostsDialog />
      <UpdateProductPurchaseCostsDialog />
      <UpdateProductReorderIntervalDialog />
      <UpdateOfferPerformanceOverwriteDialog />
      <CreateOfferPerformanceOverwriteDialog />
      <UpdateProductPurchaseVolumeDialog />
      <CreateProductPurchaseVolumeDialog />

      <CreateManualSeasonalEventDialog />
      <CreateManualPerformanceEventDialog />
    </>
  );
};
