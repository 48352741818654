import Chip from '@mui/material/Chip';
import { useFetchRetailers } from '../../../../api/automator/retailers/useFetchRetailers';

interface RetailerChipProps {
  retailerId: number;
}

const RetailerChip = ({ retailerId }: RetailerChipProps) => {
  const { data: retailers } = useFetchRetailers();

  const retailer = retailers?.find((retailer) => {
    return retailer.id == retailerId;
  });

  return (
    <Chip
      size="small"
      label={retailer?.name}
      variant="outlined"
    />
  );
};

export default RetailerChip;
