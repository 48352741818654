import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import EmailRule from '../../../../domain/automator/EmailRule';
import { EmailRuleTableRow } from './EmailRuleTableRow';

interface EmailRuleListTableProps {
  isLoading: boolean;
  emailRules?: EmailRule[] | null;
}

export const EmailRuleListTable = ({ emailRules, isLoading }: EmailRuleListTableProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (emailRules!.length == 0) {
    return <TableEmpty message={t(tokens.automator.ltr.no_items)} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t(tokens.automator.email_rules.name)}</TableCell>
              <TableCell>{t(tokens.automator.email_rules.conditions)}</TableCell>
              <TableCell>{t(tokens.automator.email_rules.product)}</TableCell>
              <TableCell>{t(tokens.automator.email_rules.delay_type.delay)}</TableCell>
              <TableCell width={10} />
            </TableRow>
          </TableHead>

          <TableBody>
            {emailRules!
              .sort((a, b) => {
                return a.isDisabled < b.isDisabled ? -1 : 1;
              })
              .map((emailRule) => {
                return (
                  <EmailRuleTableRow
                    key={emailRule.id}
                    emailRule={emailRule}
                  />
                );
              })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
