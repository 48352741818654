import { ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

interface ActionIconProps {
  icon: ReactNode;
  tooltip: string;
  tooltipDisabled?: string | null;
  onClick?: any;
  disabled?: boolean;
  href?: string;
  iconSize?: number;
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  border?: string;
  isLoading?: boolean;
  isRectangular?: boolean;
  backgroundColor?: string;
}

export const ActionIcon = ({
  icon,
  tooltip,
  tooltipDisabled,
  onClick,
  disabled,
  href,
  iconSize,
  color,
  border,
  isLoading,
  isRectangular,
  backgroundColor,
}: ActionIconProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      enterDelay={500}
      enterNextDelay={500}
      title={disabled ? tooltipDisabled : t(tooltip)}
    >
      <a href={href}>
        <IconButton
          size="small"
          edge="end"
          onClick={onClick}
          disabled={disabled}
          color={color}
          style={{
            borderRadius: isRectangular ? '5px' : undefined,
            backgroundColor: backgroundColor,
          }}
        >
          {isLoading ? (
            <CircularProgress size={28} />
          ) : (
            <SvgIcon
              sx={{
                fontSize: iconSize,
                color: color ? color : !disabled ? 'black' : undefined,
                border: border ? border : 'none',
              }}
            >
              {icon}
            </SvgIcon>
          )}
        </IconButton>
      </a>
    </Tooltip>
  );
};
