import { useTranslation } from 'react-i18next';
import { OrderStatus } from '../../../../domain/automator/OrderStatus';
import { useFetchAccount } from '../../../../api/automator/account/useFetchAccount';
import { TableTabs, TabOption } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';
import OrdersReport from '../../../../domain/automator/OrdersReport';
import { DistributionParty } from '../../../../domain/automator/DistributionParty';
import Stack from '@mui/material/Stack';
import { useOrderTab } from '../../../../store/orderTabs/useOrderTab';

interface OrderTabsProps {
  distributionParty: DistributionParty;
  ordersReport?: OrdersReport;
}

const OrderTabs = ({ distributionParty, ordersReport }: OrderTabsProps) => {
  const { setOrderTab, orderTab: currentTab } = useOrderTab();

  const { data: account } = useFetchAccount();

  const { t } = useTranslation();

  const tabOptions: TabOption<OrderStatus>[] = [];
  tabOptions.push({
    label: t(tokens.automator.orders.status.open),
    value: OrderStatus.OPEN,
    amount: ordersReport?.totalOpen,
  });

  if (!account?.settings?.ignoreLatestHandoverDate && distributionParty === DistributionParty.BOL) {
    tabOptions.push({
      label: t(tokens.automator.orders.status.open_later),
      value: OrderStatus.OPEN_LATER,
      amount: ordersReport?.totalOpenLater,
    });
  }

  const hasPendingOrders = !ordersReport ? false : ordersReport.totalPendingPickSession > 0;
  if (hasPendingOrders) {
    tabOptions.push({
      label: t(tokens.automator.orders.status.pick_session_pending),
      value: OrderStatus.PENDING_PICK_SESSION,
      amount: ordersReport?.totalPendingPickSession,
    });
  }

  tabOptions.push(
    ...[
      {
        label: t(tokens.automator.orders.status.shipped),
        value: OrderStatus.SHIPPED,
      },
      {
        label: t(tokens.automator.orders.status.cancelled),
        value: OrderStatus.CANCELLED,
      },
      {
        label: t(tokens.automator.orders.status.failed),
        value: OrderStatus.FAILED,
        amount: ordersReport?.totalFaulty,
      },
    ]
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
    >
      <TableTabs
        value={currentTab}
        onChange={(status: OrderStatus) => {
          setOrderTab({ orderTab: status });
        }}
        tabOptions={tabOptions}
      />
    </Stack>
  );
};

export default OrderTabs;
