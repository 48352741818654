import Stack from '@mui/material/Stack';
import ResolutionDossier from '../../../../domain/automator/ResolutionDossier';
import { ResolutionDossierCard } from './ResolutionDossierCard';
import { ResolutionCaseSelect } from './ResolutionCaseSelect';
import TableRow from '@mui/material/TableRow';
import { GeneralResolutionCaseActions } from './GeneralResolutionCaseActions';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import { colors } from '@mui/material';

interface ResolutionDossierInfoTableRowProps {
  resolutionDossier: ResolutionDossier;
  isSelected: boolean;
  setSelectedCaseId: (resolutionCaseId: number | null) => void;
  selectedCaseId: number | null;
  isFirst: boolean;
  isLast: boolean;
}

export const ResolutionDossierInfoTableRow = ({
  resolutionDossier,
  isSelected,
  setSelectedCaseId,
  selectedCaseId,
  isFirst,
  isLast,
}: ResolutionDossierInfoTableRowProps) => {
  const casesSorted = resolutionDossier.resolutionCases.sort((a, b) => {
    return a.id - b.id;
  });

  return (
    <TableRow
      hover={true}
      style={{
        cursor: 'pointer',
      }}
    >
      <SlimTableCell
        style={{ backgroundColor: isSelected ? colors.teal[50] : 'inherit' }}
        width={300}
        onClick={() => setSelectedCaseId(isSelected ? null : casesSorted[0].id)}
        hasBorderTop={!isFirst}
        hasBorderBottom={!isLast}
      >
        <ResolutionDossierCard resolutionDossier={resolutionDossier} />
      </SlimTableCell>

      <SlimTableCell
        style={{ backgroundColor: isSelected ? colors.teal[50] : 'inherit' }}
        hasBorderTop={!isFirst}
        hasBorderBottom={!isLast}
      >
        <Stack
          direction="column"
          gap={0.5}
        >
          {casesSorted.map((resolutionCase, index) => (
            <ResolutionCaseSelect
              key={index}
              bolOrderId={resolutionDossier.bolOrderId}
              reducedResolutionCase={resolutionCase}
              dossierHasMultipleCases={casesSorted.length > 1}
              isSelected={
                isSelected && selectedCaseId != null && selectedCaseId === resolutionCase.id
              }
              setSelectedCaseId={setSelectedCaseId}
            />
          ))}
        </Stack>
      </SlimTableCell>

      <SlimTableCell
        style={{ backgroundColor: isSelected ? colors.teal[50] : 'inherit' }}
        hasBorderTop={!isFirst}
        hasBorderBottom={!isLast}
      >
        <Stack
          direction="column"
          gap={3}
          alignItems="flex-end"
        >
          {resolutionDossier.resolutionCases.map((resolutionCase) => {
            return (
              <GeneralResolutionCaseActions
                key={resolutionCase.id}
                resolutionCaseId={resolutionCase.id}
                status={resolutionCase.status}
              />
            );
          })}
        </Stack>
      </SlimTableCell>
    </TableRow>
  );
};
