import TextField from '@mui/material/TextField';
import { CancellationReason } from '../../../../domain/automator/CancellationReason';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface CancellationReasonDropdownProps {
  selected: CancellationReason;
  onSelect: (reason: CancellationReason) => void;
}

export const CancellationReasonDropdown = ({
  onSelect,
  selected,
}: CancellationReasonDropdownProps) => {
  const { t } = useTranslation();

  const cancelReasonOptions = [
    {
      key: CancellationReason.OUT_OF_STOCK,
      value: t(tokens.automator.orders.cancellation_reason.out_of_stock),
    },
    {
      key: CancellationReason.REQUESTED_BY_CUSTOMER,
      value: t(tokens.automator.orders.cancellation_reason.requested_by_customer),
    },
    {
      key: CancellationReason.BAD_CONDITION,
      value: t(tokens.automator.orders.cancellation_reason.bad_condition),
    },
    {
      key: CancellationReason.HIGHER_SHIPCOST,
      value: t(tokens.automator.orders.cancellation_reason.higher_shipcost),
    },
    {
      key: CancellationReason.INCORRECT_PRICE,
      value: t(tokens.automator.orders.cancellation_reason.incorrect_price),
    },
    {
      key: CancellationReason.NOT_AVAIL_IN_TIME,
      value: t(tokens.automator.orders.cancellation_reason.not_avail_in_time),
    },
    {
      key: CancellationReason.NO_BOL_GUARANTEE,
      value: t(tokens.automator.orders.cancellation_reason.no_bol_guarantee),
    },
    {
      key: CancellationReason.ORDERED_TWICE,
      value: t(tokens.automator.orders.cancellation_reason.ordered_twice),
    },
    {
      key: CancellationReason.RETAIN_ITEM,
      value: t(tokens.automator.orders.cancellation_reason.retain_item),
    },
    {
      key: CancellationReason.TECH_ISSUE,
      value: t(tokens.automator.orders.cancellation_reason.tech_issue),
    },
    {
      key: CancellationReason.UNFINDABLE_ITEM,
      value: t(tokens.automator.orders.cancellation_reason.unfindable_item),
    },
    {
      key: CancellationReason.OTHER,
      value: t(tokens.automator.orders.cancellation_reason.other),
    },
  ];

  return (
    <TextField
      fullWidth
      label={t(tokens.automator.orders.cancellation_reason.cancellation_reason)}
      select
      value={selected}
      SelectProps={{ native: true }}
      onChange={(e) => onSelect(e.target.value as CancellationReason)}
    >
      {cancelReasonOptions.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {option.value}
        </option>
      ))}
    </TextField>
  );
};
