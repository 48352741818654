import OrderItem from '../../../../domain/automator/OrderItem';
import Box from '@mui/material/Box';
import { ActionIcon } from './ActionIcon';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { toast } from 'react-hot-toast';
import { useCancelOrderItem } from '../../../../api/automator/orders/useCancelOrderItem';
import { CancellationReason } from '../../../../domain/automator/CancellationReason';
import { colors } from '@mui/material';

interface CancelOrderItemButtonProps {
  orderItem: OrderItem;
}

export const CancelOrderItemButton = ({ orderItem }: CancelOrderItemButtonProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.CANCEL_ORDER_ITEM);

  const { mutate: cancelOrderItem } = useCancelOrderItem(orderItem.id);

  const onCancel = () => {
    cancelOrderItem(
      { cancellation_reason: CancellationReason.REQUESTED_BY_CUSTOMER },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.orders.order_item_cancelled));
        },
      }
    );
  };

  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      flexDirection={'row'}
      gap={1}
    >
      <ActionIcon
        icon={
          <CancelOutlinedIcon
            fontSize={'small'}
            style={{ color: colors.blue[400] }}
          />
        }
        tooltip={t(tokens.automator.orders.cancel)}
        onClick={() =>
          orderItem.cancellationRequest ? onCancel() : openDialog({ orderItem: orderItem })
        }
      />
    </Box>
  );
};
