import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import Return from '../../../../domain/automator/Return';
import { HandledReturnTableRow } from './HandledReturnTableRow';

interface HandledReturnListTableProps {
  returns?: Return[];
  isLoading: boolean;
}

export const HandledReturnListTable = ({ returns, isLoading }: HandledReturnListTableProps) => {
  const { t } = useTranslation();

  if (isLoading || !returns) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (returns!.length == 0) {
    return <TableEmpty message={t(tokens.automator.returns.no_items)} />;
  }

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.returns.product)}</TableCell>
            <TableCell>{t(tokens.automator.returns.recipient)}</TableCell>
            <TableCell>{t(tokens.automator.returns.processing_results)}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {returns!.map((return_) => {
            return (
              <HandledReturnTableRow
                key={return_.id}
                return_={return_}
              />
            );
          })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
