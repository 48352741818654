import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PRODUCT_RANK_SEARCH_TERMS_PATH } from '../pdApiPaths';
import { ProductRanksSearchTermsQueryKey } from '../../queryKeys/ProductRanksSearchTermsQueryKey';
import ProductRankSearchTerms from '../../../domain/pd/ProductRankSearchTerms';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import { CountryCode } from '../../../domain/automator/CountryCode';

export const useFetchProductRankSearchTerms = (
  productId: number,
  startDate: Date,
  endDate: Date,
  countryCode: CountryCode | null
) => {
  const url = usePdApiAccountUrl(
    PRODUCT_RANK_SEARCH_TERMS_PATH +
      `?productId=${productId}` +
      `&startDate=${getDateStringFromDate(startDate)}` +
      `&endDate=${getDateStringFromDate(endDate)}` +
      (countryCode ? `&countryCode=${countryCode}` : '')
  );
  return useFetchData<ProductRankSearchTerms>(
    url,
    ProductRanksSearchTermsQueryKey(productId, startDate, endDate, countryCode)
  );
};
