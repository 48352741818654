import TableRow from '@mui/material/TableRow';
import { Collapse, TableCell } from '@mui/material';
import ResolutionDossier from '../../../../domain/automator/ResolutionDossier';
import { useState } from 'react';
import { ResolutionDossierInfoTableRow } from './ResolutionDossierInfoTableRow';
import { ResolutionCaseView } from './ResolutionCaseView';

interface ResolutionDossierTableRowProps {
  resolutionDossier: ResolutionDossier;
  isSelected: boolean;
  setSelectedDossierId: (id: number | null) => void;
  isFirst: boolean;
  isLast: boolean;
}

export const ResolutionDossierTableRow = ({
  resolutionDossier,
  isSelected,
  setSelectedDossierId,
  isFirst,
  isLast,
}: ResolutionDossierTableRowProps) => {
  const [selectedCaseId, setSelectedCaseId] = useState<number | null>(null);

  const onSelectCaseId = (resolutionCaseId: number | null) => {
    setSelectedCaseId(resolutionCaseId != selectedCaseId ? resolutionCaseId : null);
    if (resolutionCaseId === null) {
      setSelectedDossierId(null);
    } else {
      setSelectedDossierId(resolutionDossier.id);
    }
  };

  return (
    <>
      <ResolutionDossierInfoTableRow
        resolutionDossier={resolutionDossier}
        setSelectedCaseId={onSelectCaseId}
        selectedCaseId={selectedCaseId}
        isSelected={isSelected}
        isFirst={isFirst}
        isLast={isLast}
      />

      {isSelected && selectedCaseId && (
        <TableRow>
          <TableCell
            style={{ padding: 0 }}
            colSpan={10}
          >
            <Collapse
              in={isSelected}
              unmountOnExit
            >
              <ResolutionCaseView
                resolutionCaseId={selectedCaseId}
                hasOpenResend={resolutionDossier.hasOpenResend}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {isSelected && selectedCaseId && (
        <TableRow style={{ backgroundColor: '#F8F9FA' }}>
          <TableCell colSpan={10}></TableCell>
        </TableRow>
      )}
    </>
  );
};
