import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import Typography from '@mui/material/Typography';
import useEmailPhaseTranslations from '../translations/useEmailPhaseTranslations';

interface PhaseTypographyProps {
  phase: ThreadPhase;
  minWidth?: number;
}

export const PhaseTypography = ({ phase, minWidth }: PhaseTypographyProps) => {
  const label = useEmailPhaseTranslations(phase);

  const getPhaseColor = (phase: ThreadPhase) => {
    switch (phase) {
      case ThreadPhase.ORDER:
        return 'PURPLE';
      case ThreadPhase.SHIPMENT:
        return 'GREEN';
      case ThreadPhase.RETURN:
        return 'ORANGE';
      case ThreadPhase.OTHER:
        return 'BLUE';
    }
  };

  return (
    <Typography
      minWidth={minWidth == undefined ? 75 : minWidth}
      fontWeight={600}
      color={getPhaseColor(phase)}
    >
      {label}
    </Typography>
  );
};
