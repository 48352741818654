import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import LabelTypeRule from '../../../../domain/automator/LabelTypeRule';
import { LabelTypeToggleButton } from '../../orders/components/LabelTypeToggleButton';
import { debounce } from '@mui/material';
import { LabelType } from '../../../../domain/automator/LabelType';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';
import RetailerChip from '../../retailers/components/RetailerChip';
import { useUpdateLabelTypeRules } from '../../../../api/automator/label-type-rules/useUpdateLabelTypeRules';
import Stack from '@mui/material/Stack';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import { OpenUpdateOfferDialogButton } from '../../orders/components/OpenUpdateOfferDialogButton';

interface LabelTypeRuleTableRowProps {
  labelTypeRule: LabelTypeRule;
}

export const LabelTypeRuleTableRow = ({ labelTypeRule }: LabelTypeRuleTableRowProps) => {
  const { t } = useTranslation();

  const [maxItems, setMaxItems] = useState(labelTypeRule.maxItemsForMailbox.toString());

  const { mutate: updateLabelTypeRules } = useUpdateLabelTypeRules();

  const doUpdate = (maxItems: number | null, labelType: LabelType) => {
    const data = {
      label_type_rule_ids: [labelTypeRule.id],
      max_items_for_mailbox: maxItems,
      label_type: labelType,
    };

    updateLabelTypeRules(data, {
      onSuccess: () => {
        toast.success(t(tokens.automator.ltr.label_type_changed));
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateMaxItemsFormMailbox = useCallback(
    debounce((maxItems: string) => {
      const maxItemsNumber = Number(maxItems);
      if (maxItems && maxItemsNumber !== labelTypeRule.maxItemsForMailbox && maxItemsNumber >= 1) {
        doUpdate(maxItemsNumber, labelTypeRule.labelType);
      }
    }, 500),
    []
  );

  return (
    <TableRow key={labelTypeRule.id}>
      <TableCell>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
        >
          <TooltippedProductThumbnail
            thumbnailUrl={labelTypeRule.thumbnailUrl}
            productTitle={labelTypeRule.productTitle + ' | ' + labelTypeRule.ean}
            height={50}
          />
          <OpenUpdateOfferDialogButton productId={labelTypeRule.productId} />
        </Stack>
      </TableCell>
      <TableCell>
        <LabelTypeToggleButton
          id={labelTypeRule.id}
          value={labelTypeRule.labelType}
          onChange={(_, labelType) => {
            doUpdate(labelTypeRule.maxItemsForMailbox, labelType);
          }}
        />
      </TableCell>
      <TableCell>
        <LabelTypeToggleButton
          id={labelTypeRule.id}
          value={LabelType.PARCEL}
          isDisabled={true}
          onChange={() => console.log('DISABLED')}
        />
      </TableCell>
      <TableCell>
        <TextField
          value={maxItems}
          onChange={(e) => {
            const value = e.target.value;
            if (Number(value) > 0 || value == '') {
              setMaxItems(value);
              debouncedUpdateMaxItemsFormMailbox(e.target.value);
            }
          }}
          onBlur={() => debouncedUpdateMaxItemsFormMailbox(maxItems)}
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          size={'small'}
        />
      </TableCell>
      <TableCell>
        <Stack
          direction="row"
          gap={1}
        >
          {labelTypeRule.retailerIds.map((retailerId) => (
            <RetailerChip
              key={retailerId}
              retailerId={retailerId}
            />
          ))}
        </Stack>
      </TableCell>
    </TableRow>
  );
};
