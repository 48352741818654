import { fillMissingKeys, tokens, TranslationTokens } from '../translationTokens';

export const en: TranslationTokens = fillMissingKeys(tokens, {
  common: {
    language_changed: 'Language changed',
    created_date_time: 'Creation date',
    loading: 'Loading...',
    actions: {
      actions: 'Actions',
      delete: 'Delete',
    },
    netherlands: 'Netherlands',
    belgium: 'Belgium',
    close: 'Close',
    save: 'Save',
    add: 'Add',
    confirm: 'Confirm',
    language_change: 'Change language',
    action_error: 'Action failed',
    select: '*Select',
    days_of_the_week: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    recipient_type: {
      recipient_type: '*Recipient type',
      transporter: '*Transporter',
      seller: '*Seller',
      customer: '*Customer',
      bol: 'Bol.com',
    },
  },
  nav: {
    orders: 'Orders',
    pick_sessions: 'VVB Labels',
    label_type_rules: 'Label rules',
    retailers: 'Account API',
    login: 'Login',
    register: 'Register',
    error: 'Error',
    shipments: 'TrackingView',
    logout: 'Logout',
    settings: 'Settings',
    subscribe: 'Subscription',
    customer_portal: 'Customer portal',
    support: 'Support / Feedback',
    returns: '*Returns',
    email_threads: '*Email threads',
    resolution_dossiers: '*CaseHub',
    resends: '*Resends',
    reports: '*Reports',
    email_rules: '*AutoMail',
  },
  auth: {
    email_address: 'Email address',
    password: 'Password',
    invalid_email_address: 'Invalid email address',
    email_required: 'Email required',
    password_required: 'Password required',
    login: {
      login: 'Login',
      incorrect_password: 'Incorrect password and/or email address',
      email_address_already_in_use: 'Email address is already in use',
      forgot_password: 'Forgot password?',
      unknown_error: 'Unknown error message',
    },
    register: {
      password_min_length: 'A password must be at least 8 characters long',
      password_alpha: 'A password must contain at least 1 letter',
      password_numeric: 'A password must contain at least 1 number',
      passwords_must_match: 'The passwords must match',
      confirm_password_required: 'Confirmation password required',
      confirm_password: 'Confirm password',
      register: 'Sign up',
    },
    request_new_password: {
      request_new_password: 'Request new password',
      do_request_new_password: 'Request',
      token_invalid: 'Invalid token, try to request a new password.',
      email_sent: 'An email has been sent to you with a link to reset your password.',
      new_password_set: 'You can now login with your new password.',
      set_new_password: 'Set new password',
    },
  },
  automator: {
    products: {
      products: '*Products',
    },
    resolutions: {
      resolution_dossiers: '*CaseHub',
      resends: '*Resends',
      email_sent: '*Email sent',
      no_items: '*No items',
      show_all: '*Show all',
      response: '*Response',
      is_openend: '*Is opened',
      is_not_openend: '*Is not opened',
      indicator: {
        has_case: '*Has case',
        has_unread_email: '*Has unread email',
      },
      email_variables: {
        email_variables: '*Email variables',
        customer_name: '*Customer name',
        store_name: '*Store name',
        transporter_name: '*Transporter name',
      },
      dialogs: {
        create_email_template: {
          dialog_title: '*Dialog title',
          email_template_created: '*Email template created',
          create_email_template: '*Create email template',
          name: '*Name',
          subject: '*Subject',
          body: '*Body',
          attachments: '*Attachments',
        },
        update_email_template: {
          dialog_title: '*Dialog title',
          email_template_updated: '*Email template updated',
          update_email_template: '*Update email template',
          name: '*Name',
          subject: '*Subject',
          body: '*Body',
          attachments: '*Attachments',
        },
        send_email: {
          transporter_unknown: '*Transporter unknown',
          dialog_title: '*Dialog title',
          send_email: '*Send email',
          forward_email: '*Forward email',
          email_template: '*Email template',
          phase: '*Phase',
          email_sent: '*Email sent',
          subject: '*Subject',
          recipient: '*Recipient',
          body: '*Body',
          start_with_case: '*Start with case',
          attachments: '*Attachments',
          archive_email_template: '*Archive email template',
          email_template_archived: '*Email template archived',
        },
        send_email_thread_reply: {
          dialog_title: '*Dialog title',
          send_reply: '*Send reply',
          reply_sent: '*Reply sent',
          body: '*Body',
        },
        create_resend: {
          dialog_title: '*Dialog title',
          note: '*Note',
          create_resend: '*Create resend',
          resend_created: '*Resend created',
        },
      },
      actions: {
        handle_case: '*Handle case',
        case_handled: '*Case handled',
        handle_resend: '*Handle resend',
        resend_handled: '*Resend handled',
        case_awaiting_response: '*Case awaiting response',
        case_set_to_awaiting_response: '*Case set to awaiting response',
      },
      email_type: {
        return_item: '*Return item',
        order_item: '*Order item',
        shipment: '*Shipment',
      },
      resolution_dossier_status: {
        open: '*Open',
        handled: '*Handled',
        awaiting_response: '*Awaiting response',
      },
      resolution_case_status: {
        open: '*Open',
        handled: '*Handled',
        awaiting_response: '*Awaiting response',
      },
      resend_status: {
        open: '*Open',
        handled: '*Handled',
      },
      recipient_type: {
        recipient_type: '*Recipient type',
        customer: '*Customer',
        bol: '*Bol',
        transporter: '*Transporter',
        seller: '*Seller',
      },
      phase: {
        order: '*Order',
        return: '*Return',
        shipment: '*Shipment',
        other: '*Other',
      },
      resolution_case_table: {
        subject: '*Subject',
      },
      resolution_dossier_table: {
        phase: '*Phase',
        recipient_type: '*Recipient type',
        note: '*Note',
        order_info: '*Order info',
        subject: '*Subject',
        created_date_time: '*Created date time',
        new_reply: '*New reply',
        is_old: '*Is old',
      },
      resend_table: {
        order_items: '*Order items',
        order_number: '*Order number',
        shipment_details: '*Shipment details',
        note: '*Note',
      },
      email_messages_table: {
        sender: '*Sender',
        recipient: '*Recipient',
        body: '*Body',
        sent_date_time: '*Sent date time',
      },
    },
    orders: {
      no_items: 'No orders found',
      no_shipped_orders_this_days: '*No shipped orders on these days',
      shipped_orders: '*Shipped orders',
      open_product_page: '*Open product page',
      dialogs: {
        update_internal_reference: {
          dialog_title: '*Dialog title',
          internal_reference: '*Internal reference',
          add_internal_reference: '*Add internal reference',
          update_internal_reference: '*Update internal reference',
          internal_reference_updated: '*Internal reference updated',
          internal_reference_max_length_warning: '*Internal reference max length warning',
        },
        update_order_note: {
          dialog_title: '*Dialog title',
          update_note: '*Update note',
          note_updated: '*Note updated',
          note: '*Note',
        },
      },
      tutorials: {
        without_retailer_message:
          'Welcome! \n\n To get started you first need to link your store with our app. ',
        without_retailer_button: 'Link your store now',
        with_retailer_message:
          'All your orders are loaded in! Select how you want to send and press BUTTON. \n' +
          '\n' +
          'Tip: Multiple items in 1 order are always at the top. \n' +
          '\n' +
          'For more explanation see the FAQ',
        with_retailer_button: 'To the FAQ',
        internal_reference_info:
          "BlueEngine sets your 'Internal Reference' at the bottom of the shipping label by default. If this field is empty in your account, your product title will be used instead. Please note, this is often very long. Therefore, we advise filling in the field with something recognizable to you.\\n\" + '\\n' + 'For example -> (LEGO-CASTLE-BLUE)'",
      },
      orders: 'Orders',
      bol_order_id: 'Order number',
      order_items: 'Order items',
      recipient: 'Customer',
      store: 'Store',
      cancel: 'Cancel',
      cancellation_request: 'Cancellation request',
      cancellation_requests_approved: 'Cancellation requests processed',
      toasts: {
        label_type_changed: 'Label type changed',
      },
      buttons: {
        start_vvb_pick_session: 'Process VVB labels',
        set_to_mailbox: 'Selection to mailbox',
        set_to_mailbox_success: 'Selection set to mailbox',
        set_to_parcel: 'Selection to package',
        set_to_parcel_success: 'Selection set to package',
        copy: {
          copy_customer_name: '*Copy customer name',
          customer_name_copied: '*Customer name copied',
          copy_zip_code: '*Copy zip code',
          zip_code_copied: '*Zip code copied',
          copy_internal_reference: '*Copy internal reference',
          internal_reference_copied: '*Internal reference copied',
          copy_city: '*Copy city',
          city_copied: '*City copied',
          copy_street_name: '*Copy street name',
          street_name_copied: '*Street name copied',
          copy_house_number: '*Copy house number',
          house_number_copied: '*House number copied',
          copy_house_number_extension: '*Copy house number extension',
          house_number_extension_copied: '*House number extension copied',
        },
      },
      status: {
        open: 'Open',
        open_later: 'Queue',
        pick_session_pending: 'VVB Labels busy',
        shipped: 'Shipped',
        cancelled: 'Cancellations',
        failed: 'Failed',
        can_not_process: 'Unable to process',
      },
      selection_set_to_mailbox: 'Selection to mailbox',
      cancel_order_item: 'Cancel order item',
      order_item_cancelled: 'Order item cancelled',
      order_item_cancellation_explanation: '*Order item cancellation explanation',
      cancellation_reason: {
        cancellation_reason: 'Cancellation Reason',
        out_of_stock: 'Out of stock',
        requested_by_customer: 'Requested by customer',
        bad_condition: 'Bad condition',
        higher_shipcost: 'Higher shipping costs',
        incorrect_price: 'Incorrect price',
        not_avail_in_time: 'Not available in time',
        no_bol_guarantee: 'No bol guarantee',
        ordered_twice: 'Ordered twice',
        retain_item: 'Retain item',
        tech_issue: 'Technical problem',
        unfindable_item: 'Unfindable article',
        other: 'Other',
      },
      can_not_process_external_processed_order:
        'Order is partially processed outside BlueEngine and cannot be processed here.',
      can_not_process_order_with_conflicting_handover_date_times:
        'Conflicting last handover dates; cannot be processed by BlueEngine.',
      can_not_process_order_loading_error: 'Problem loading order.',
      is_vvb_non_vvb_combo: 'The order is partly non-vvb and cannot be processed here.',
      shipment_date_time: '*Shipment date and time',
      latest_handover_date: {
        latest_handover_date: 'Set a different shipping date here',
        explanation:
          'Pay attention! If a VVB order remains open for too long, it will be cancelled by Bol.com.',
        let_bol_decide: 'Set back to original shipping day',
        latest_handover_date_updated: 'Last handover date updated',
      },
      force_do_not_ignore_latest_handover_date_explanation:
        'Due to the pressure on the Bol.com servers, it is temporarily not possible to create VVB labels for days other than the current shipping day.',
      order_placed_date_tooltip: 'Order date',
      repeat_customer_tooltip: 'Repeat customer',
      repeat_customer: 'Repeat customer',
      latest_handover_date_tooltip: 'Shipping date',
      expected_delivery_date_tooltip: '*Expected delivery date',
      approve_customer_cancellations: 'Process customer cancellations',
      errors: {
        request_already_in_progress:
          'Your orders are already being loaded, refresh the page in a few minutes.',
        unknown_error: 'Unknown error, try again later.',
      },
    },
    retailers: {
      no_items: 'No links found',
      tutorials: {
        without_retailer_message:
          'Link your Bol.com store here. \n' +
          '\n' +
          '1. Click on the button below and press ‘Create’ for Client Credentials. Type SYCN8, and press enter. \n' +
          '\n' +
          '2. Then you copy both codes below. Voila! You are ready to start.',
        without_retailer_button: 'To Bol.com credentials',
        with_retailer_dialog_title: 'Successfully linked!',
        with_retailer_dialog_message: 'You have now successfully linked your store!',
        with_retailer_dialog_button: 'Retrieve your VVB orders now',
      },
      retailer: '*Retailer',
      retailers: 'Shops',
      name: 'Shop name',
      created_date: 'Creation date',
      retailer_blocked_explanation:
        'Your sales number already exists or is blocked. Please contact support.',
      zip_code: '*Zip code',
      status: {
        status: 'Status',
        blocked: 'Blocked',
        active: 'Active',
      },
      add_retailer: {
        add_retailer: 'Add link',
        add_retailer_explanation: 'Copy the code here',
        add_retailer_success: 'Retailer added',
        client_id: 'Client ID',
        secret: 'Secret',
        retailer_added: 'Link added',
      },
    },
    reports: {
      reports: '*Reports',
      quarter: '*Quarter',
      download: '*Download',
    },
    pick_sessions: {
      tutorials: {
        message:
          'Here are all your created VVB labels. You can download the packing list and the VVB labels with your reference on them. If there is an error with one of your orders, it will be indicated here. \n' +
          '\n' +
          'The VVB labels are kept for 14 days.',
      },
      no_items: 'No labels found',
      confirm_start_pick_session_explanation:
        'Your shipping labels are being retrieved from Bol.com and immediately set to shipped.',
      confirm_start_pick_session: 'Create shipping labels',
      non_vvb_be_nl_mix_warning: '*Non-VVB BE NL mix warning',
      pick_sessions: 'VVB Labels',
      progress: 'Progress',
      failures: 'Errors',
      phase: {
        phase: 'Phase',
        failed: 'Failed',
        shipping: 'Processing in progress',
        shipped: 'Processed',
        creating_labels: 'Labels are being made',
        shipping_pending: 'Processing in progress',
      },
      buttons: {
        download_pick_list: 'Download pick list',
        download_enriched_labels: 'Download labels',
        download_enriched_labels_disabled: 'Labels not available',
        download_clean_labels: 'Download labels without order info',
        download_clean_labels_disabled: 'Download labels without order info not available',
      },
      label_generation: {
        label_generation: '*Label generation',
        manual: '*Manual',
        buy_from_bol: '*Buy from Bol',
        vvb: '*VVB',
      },
      pick_session_started: 'VVB label processing started',
      transporter_codes: {
        transporter_code: '*Transporter code',
        none: 'None selected',
        tnt: 'PostNL',
        tnt_brief: 'Mailbox post',
        rjp: '*RJP',
        dhlforyou: 'DHL',
        budbee: '*Budbee',
        dhl_sd: 'DHL',
        tnt_extra: 'PostNL',
        tnt_express: 'PostNL',
        dyl: '*DYL',
        dpd_nl: 'DPD',
        dpd_be: 'DPD',
        bpost_be: 'BPOST',
        bpost_brief: '*BPOST brief',
        gls: '*GLS',
        fedex_nl: '*FedEx NL',
        fedex_be: '*FedEx BE',
        dhl_de: 'DHL',
        dhl_global_mail: 'DHL Global Mail',
        other: 'Other',
        tsn: '*TSN',
        transmission: '*Transmission',
        parcel_nl: '*Parcel NL',
        packs: '*Packs',
        courier: '*Courier',
        trunkrs: '*Trunkrs',
        pes: '*PES',
        cycloon: '*Cycloon',
        ups: 'UPS',
        unknown: '*Unknown',
      },
    },
    ltr: {
      tutorials: {
        message:
          'Here you specify how your product should be sent as standard. If a product is unknown or new in our system, we automatically take over the settings from your sales account. \n' +
          '\n' +
          'Does your product get sent as a package for multiple quantities? You can indicate that in the right column.',
      },
      no_items: 'No shipping rules found',
      label_type_rules: '*Shipping rules',
      title: '*Title',
      retailers: '*Stores',
      more_than: '*More than',
      label_type_changed: '*Shipping rule adjusted',
      set_all_to_mailbox: '*Set all to mailbox',
      all_set_to_mailbox: '*All set to mailbox',
      set_all_to_parcel: '*Set all to parcel',
      all_set_to_parcel: '*All set to parcel',
      set_all_to_bol_recommended: '*Set all to sales account',
      all_set_to_bol_recommended: '*All set to sales account',
    },
    email_rules: {
      name: '*Name',
      conditions: '*Conditions',
      email_rules: '*AutoMail',
      product: '*Product',
      distribution_party: '*Distribution party',
      country_code: '*Country code',
      recipient: '*Recipient',
      form: {
        create_email_rule: '*Create email rule',
        update_email_rule: '*Update email rule',
        email_rule_created: '*Email rule created',
        email_rule_updated: '*Email rule updated',
        execute_in_days: '*Execute in days',
        execute_at_time: '*Execute at time',
        trigger_required: '*Trigger required',
        one_country_code_required: '*One country code required',
        one_distribution_party_required: '*One distribution party required',
        email_template_required: '*Email template required',
        hours_required: '*Hours required',
        days_required: '*Days required',
        time_required: '*Time required',
        delay_days: '*Delay days',
        delay_hours: '*Delay hours',
        delay_time: '*Delay time',
      },
      disabled: '*Disabled',
      enabled: '*Enabled',
      update_email_template: '*Update email template',
      update_email_rule: '*Update email rule',
      triggers: {
        trigger: '*Trigger',
        new_order: '*New order',
        order_is_placed_double: '*Order is placed double',
        customer_is_recurring: '*Customer is recurring',
        shipment_track_and_trace_uploaded: '*Shipment track and trace uploaded',
        shipment_send: '*Shipment send',
        shipment_delivered: '*Shipment delivered',
        shipment_at_pickup_point: '*Shipment at pickup point',
        shipment_returned_to_sender: '*Shipment returned to sender',
        return_registered: '*Return registered',
        recoverable_return_item_registered: '*Recoverable return item registered',
        recoverability_unclear_return_item_registered:
          '*Recoverability unclear return item registered',
        not_recoverable_return_item_registered: '*Not recoverable return item registered',
        return_handled: '*Return handled',
        expected_delivery_date_reached: '*Expected delivery date reached',
      },
      delay_type: {
        delay: '*Delay',
        none: '*None',
        hours: '*Hours',
        days: '*Days',
        time: '*Time',
      },
    },
    label_type: {
      label_type: '*Label type',
      parcel: '*Parcel',
      bol_recommended: '*Bol recommended',
      mailbox: '*Mailbox',
    },
    settings: {
      settings: 'Settings',
      automatically_approve_cancel_requests: 'Automatically approve cancellation requests',
      automatically_approve_cancel_requests_tooltip:
        "Automatically process customer cancellations and put them in the 'Cancellations' tab.",
      ignore_latest_handover_date: "Ignore customer's preferred shipping date",
      ignore_latest_handover_date_tooltip:
        "By default, your orders are sorted by desired shipping day, and set in the 'Queue' tab. \n\n\n Do you want to send everything every day, without taking this into account, then turn this function on.",
      save_settings: 'Save settings',
      settings_saved: 'Settings saved',
    },
    pricing: {
      title: 'Effortless packing and shipping for VVB',
      subtitle: 'Transparent prices',
      tier_1: {
        title: 'Starter',
        feature_1: 'Up to 100 VVB orders p.m.',
        feature_2: '1 store',
      },
      tier_2: {
        title: 'Growth',
        feature_1: 'Up to 1000 VVB orders p.m.',
        feature_2: '2 stores',
      },
      tier_3: {
        title: 'Business',
        feature_1: 'More than 1000 VVB orders p.m.',
        feature_2: '3+ stores',
      },
      ex_vat: 'Ex. VAT',
      monthly: 'Per month',
      subscribe: 'Start now!',
      payment_successful: 'Payment successful!',
      payment_failed: 'Payment failed! Try again or contact support.',
      subscription_deactivated:
        'Account deactivated due to termination of payment/subscription. Start new subscription to continue using our services.',
      trial_expired:
        'Your trial period has expired!\n\nChoose one of the subscriptions to continue using the software.',
    },
    shipments: {
      actions: {
        add_track_and_trace: {
          track_and_trace: '*Track and trace',
          track_and_trace_added: '*Track and trace added',
        },
      },
      no_items: 'No shipments found.',
      shipments: 'Delivery status VVB',
      no_shipments_on_this_day: 'No shipments on this day',
      total_shipments_track_and_traceble: '*Total shipments track and traceable',
      total_shipments_non_track_and_traceble: '*Total shipments non-track and traceable',
      total_delivered: 'Delivered',
      total_at_pickup_point: 'Parcelshop',
      total_returned_to_sender: 'Return to sender',
      total_in_transit: 'In transit',
      total_at_transporter: 'At transporter',
      total_inbound_collect: '*Total inbound collect',
      total_pre_announced: '*Total pre-announced',
      is_resent_after_return_tooltip: 'Resent after return',
      customer: 'Customer',
      order_number: 'Order number',
      internal_reference: 'Product',
      transporter: 'Transporter',
      expectedDeliveryDate: 'Promised delivery date',
      retailer: 'Shop',
      distribution_party: {
        bol: 'VVB',
        retailer: 'Non-VVB',
        mixed: 'VVB+Non-VVB',
      },
      status: {
        status: 'Status',
        waiting_for_events: 'Announced.',
        pre_announced: 'Announced.',
        at_transporter: 'At transporter',
        in_transit: 'In transit',
        inbound_collect: 'Inbound collect',
        at_pick_up_point: 'At Pickup shop',
        picked_up_at_pick_up_point: 'Picked up at Parcelshop',
        delivered_at_customer: 'Delivered',
        delivered_at_neighbours: 'Delivered at neighbors',
        returned_to_sender: 'Returned to sender',
        overdue: 'Overdue',
      },
      tabs: {
        all: 'All',
        at_pick_up_point: 'At pickup point',
        returned_to_sender: 'Returned to sender',
        overdue: 'Overdue',
      },
      copy_email: 'Copy email',
      email_copied: 'Email copied',
      load_shipment_events: 'Refresh shipment events',
      loading_shipment_events_started: 'Refreshing shipment events started',
    },
    returns: {
      no_items: '*No items',
      is_recurring: 'Recurring return',
      is_new: '*Is new',
      returns: 'Returns',
      today: 'Today',
      yesterday: 'Yesterday',
      day_before_yesterday: 'Day before yesterday',
      day_old: 'Day old',
      days_old: 'Days old',
      other: '*Other',
      product: '*Product',
      recipient: '*Recipient',
      send_email: '*Send email',
      create_email_template: '*Create email template',
      processing_results: '*Processing results',
      return_reason: '*Return reason',
      rma_id: '*RMA ID',
      track_and_trace: '*Track and trace',
      registration_date_time: '*Registration date and time',
      days_until_expiry: '*Days until expiry',
      quantity: '*Quantity',
      total_unhandled: 'Outstanding returns',
      status: {
        new: 'Overview',
        handled: 'Handled',
        overview: 'Overview',
        awaiting_exchange: 'Awaiting exchange',
        on_hold: 'On-hold',
        awaiting_repair: 'In repair',
      },
      recoverability: {
        recoverable: 'Recoverable',
        not_recoverable: 'Known',
        unclear: 'Unknown',
        recoverability_updated: '*Recoverability updated',
      },
      processing_result: {
        accepted: '*Accepted',
        rejected: '*Rejected',
        cancelled: '*Cancelled',
      },
      handling_result: {
        return_received: '*Return received',
        exchange_product: '*Exchange product',
        return_does_not_meet_conditions: '*Return does not meet conditions',
        repair_product: '*Repair product',
        customer_keeps_product_paid: '*Customer keeps product (paid)',
        still_approved: '*Still approved',
        customer_keeps_product_free_of_charge: '*Customer keeps product (free of charge)',
        return_item_lost: '*Return item lost',
        expired: '*Expired',
        excessive_return: '*Excessive return',
        still_received: '*Still received',
        cancelled_by_customer: '*Cancelled by customer',
        failed_to_create_shipping_label: '*Failed to create shipping label',
        under_investigation: '*Under investigation',
        failed_to_collect_by_transporter: '*Failed to collect by transporter',
      },
      detailed_reason: {
        product_damaged: 'Product damaged',
        product_defective: 'Product defective',
        product_incomplete: 'Product incomplete',
        quality_not_as_expected: 'Quality not as expected',
        delivery_too_late: 'Delivery too late',
        product_information_unclear: 'Product information unclear',
        product_image_unclear: 'Product image unclear',
        too_big: 'Too big',
        too_small: 'Too small',
        received_product_as_gift: 'Received product as gift',
        changed_mind: 'Changed mind',
        unknown: 'Unknown',
      },
      actions: {
        accept: '*Accept',
        return_item_accepted: '*Return item accepted',
        still_accept: '*Still accept',
        processing_result_still_accepted: '*Processing result still accepted',
        reject: '*Reject',
        return_item_rejected: '*Return item rejected',
        set_to_awaiting_exchange: '*Set to awaiting exchange',
        set_to_awaiting_arrival: '*Set to awaiting arrival',
        set_to_on_hold: 'To on-hold',
        set_to_awaiting_repair: '*To in repair',
        set_to_new: 'To overview',
        send_email: '*Send email',
        update_seller_comments: '*Update seller comments',
        seller_comments_updated: '*Seller comments updated',
      },
      dialogs: {
        handle_return_item: {
          dialog_title: '*Handle return item',
          approve_return: '*Approve return',
          reject_return: '*Reject return',
          handle_return_explanation: '*Handle return explanation',
        },
        create_return: {
          dialog_title: '*Create return',
          retailer: '*Retailer',
          bol_order_id: '*Bol order ID',
          bol_order_item_id: '*Bol order item ID',
          quantity_returned: '*Quantity returned',
          handling_result: '*Handling result',
          return_created: '*Return created',
        },
        send_email: {
          send_email: '*Send email',
        },
      },
    },
  },
  customer_order: {
    send_to_pickup_point_thank_you_message:
      'Thank you for letting us know!\n' +
      '\n' +
      'Your package will be brought to the nearest DHL service point. You will receive an email from DHL with the tracking code today or tomorrow. \n' +
      '\n' +
      'The DHL email may end up in your spam or junk mail. So keep an eye on this!',
    send_to_pickup_point_already_shipped_message:
      '"Your package is already on its way and will be delivered to your home!"',
  },
  pd: {
    performance: {
      tabs: {
        offer_performance: '*Offer performance',
        product_performance: '*Product performance',
        ad_search_term_performance: '*Ad search term performance',
      },
      performances: '*Performances',
      no_items: '*No items',
      performance_note_form: {
        note: '*Note',
        update_note: '*Update note',
        note_updated: '*Note updated',
      },
      offer_performances_table: {
        offer_performances: '*Offer performances',
        date: '*Date',
      },
      competitor_product_performances_table: {
        position: '*Position',
        no_data: '*No data',
      },
      timeline_table: {
        seasonals: {
          new_years_day: "*New Year's Day",
          valentines_day: "*Valentine's Day",
          international_womens_day: "*International Women's Day",
          saint_patricks_day: "*Saint Patrick's Day",
          international_day_of_happiness: '*International Day of Happiness',
          april_fools_day: "*April Fool's Day",
          ascension_day: '*Ascension Day',
          mothers_day: "*Mother's Day",
          liberation_day: '*Liberation Day',
          fathers_day: "*Father's Day",
          summer_break: '*Summer Break',
          teachers_day: "*Teacher's Day",
          halloween: '*Halloween',
          christmas_day: '*Christmas Day',
          new_years_eve: "*New Year's Eve",
          saint_nicholas_day: "*Saint Nicholas' Day",
          good_friday: '*Good Friday',
          world_animal_day: '*World Animal Day',
          eid_al_fitr: '*Eid al-Fitr',
          feast_of_sacrifice: '*Feast of Sacrifice',
          carnaval: '*Carnival',
          first_pentecost_day: '*First Pentecost Day',
          second_pentecost_day: '*Second Pentecost Day',
          black_friday: '*Black Friday',
          cyber_monday: '*Cyber Monday',
          chinese_new_year: '*Chinese New Year',
          kings_day: "*King's Day",
          secretary_day: "*Secretary's Day",
        },
      },
      offer_performances_totals: {
        total_profit: '*Total profit',
        total_ad_costs: '*Total ad costs',
        total_visits: '*Total visits',
        total_articles_sold: '*Total articles sold',
        conversion_rate: '*Conversion rate',
        total_revenue: '*Total revenue',
        total_purchase_value: '*Total purchase value',
      },
      volume_discount_indicator: {
        volume_discount: '*Volume discount',
        yes: '*Yes',
        no: '*No',
        price_unknown: '*Price unknown',
      },
      organic_non_organic_table: {
        organic_vs_non_organic: '*Organic vs Non-organic',
        option: {
          all: '*All',
          organic: '*Organic',
          non_organic: '*Non-organic',
        },
        conversion_rate: '*Conversion rate',
        orders_conversion_rate: '*Orders conversion rate',
        articles: '*Articles',
        orders: '*Orders',
        visits: '*Visits',
      },
      offer_performance_search_metrics_table: {
        search_metrics: '*Search metrics',
        impressions: '*Impressions',
        ctr: '*CTR',
        weighted_rank: '*Weighted rank',
      },
      offer_performance_metrics_table: {
        metrics: '*Metrics',
        daily_profit: '*Daily profit',
        yearly_profit: '*Yearly profit',
        ROIDE: '*ROI DE',
      },
      ad_performances_table: {
        ad_performances: '*Ad performances',
        impressions: '*Impressions',
        clicks: '*Clicks',
        ctr: '*CTR',
        conversions: '*Conversions',
        conversion_rate: '*Conversion rate',
        sales: '*Sales',
        acos: '*ACOS',
        cpc: '*CPC',
        spent: '*Spent',
      },
      product_rank_search_term_category_table: {
        type: '*Type',
        label: '*Label',
        average_rank: '*Average rank',
        total_impressions: '*Total impressions',
        total_volume: '*Total volume',
        is_relevant: '*Is relevant',
        is_relevant_updated: '*Is relevant updated',
      },
      ad_group_search_term_performance_search_terms_table: {
        search_term: '*Search term',
        total_impressions: '*Total impressions',
        total_clicks: '*Total clicks',
        impressions_share: '*Impressions share',
        click_share: '*Click share',
        total_conversions: '*Total conversions',
        total_sales: '*Total sales',
        total_spent: '*Total spent',
        average_cpc: '*Average CPC',
        average_acos: '*Average ACOS',
        average_ctr: '*Average CTR',
        average_conversion_rate: '*Average conversion rate',
        average_winning_bid_average: '*Average winning bid average',
        total_search_volume: '*Total search volume',
      },
      search_term_volume_chart: {
        title: '*Title',
        volume: '*Volume',
      },
      assortment_impressions_chart: {
        title: '*Title',
        impressions: '*Impressions',
      },
      assortment_visits_chart: {
        title: '*Title',
        visits: '*Visits',
      },
      ad_group_search_term_performance_chart: {
        ad_group_search_term_performance: '*Ad group search term performance',
        impressions: '*Impressions',
        clicks: '*Clicks',
        ctr: '*CTR',
        conversions: '*Conversions',
        conversion_rate: '*Conversion rate',
        sales: '*Sales',
        spent: '*Spent',
        cpc: '*CPC',
        acos: '*ACOS',
        roas: '*ROAS',
        average_winning_bid: '*Average winning bid',
        search_volume: '*Search volume',
        click_share_percentage: '*Click share percentage',
        impression_share_percentage: '*Impression share percentage',
      },
      ad_group_performance_chart: {
        ad_group_performance: '*Ad group performance',
        impressions: '*Impressions',
        clicks: '*Clicks',
        conversions: '*Conversions',
        sales: '*Sales',
        spent: '*Spent',
        impression_share: '*Impression share',
        click_share: '*Click share',
      },
      product_ranks_chart: {
        product_ranks: '*Product ranks',
        loaded_rank: '*Loaded rank',
        rank: '*Rank',
        impressions: '*Impressions',
      },
      create_offer_shipping_costs_form: {
        date: '*Date',
        shipping_costs: '*Shipping costs',
        create_shipping_costs: '*Create shipping costs',
        shipping_costs_created: '*Shipping costs created',
      },
      update_offer_shipping_costs_form: {
        date: '*Date',
        shipping_costs: '*Shipping costs',
        update_shipping_costs: '*Update shipping costs',
        shipping_costs_updated: '*Shipping costs updated',
      },
      create_product_purchase_costs_form: {
        date: '*Date',
        purchase_costs: '*Purchase costs',
        create_purchase_costs: '*Create purchase costs',
        purchase_costs_created: '*Purchase costs created',
      },
      update_product_purchase_costs_form: {
        date: '*Date',
        purchase_costs: '*Purchase costs',
        update_purchase_costs: '*Update purchase costs',
        purchase_costs_updated: '*Purchase costs updated',
      },
      create_product_purchase_volume_form: {
        date: '*Date',
        purchase_volume: '*Purchase volume',
        create_purchase_volume: '*Create purchase volume',
        purchase_volume_created: '*Purchase volume created',
      },
      update_product_purchase_volume_form: {
        date: '*Date',
        purchase_volume: '*Purchase volume',
        update_purchase_volume: '*Update purchase volume',
        purchase_volume_updated: '*Purchase volume updated',
      },
      create_product_reorder_interval_form: {
        date: '*Date',
        reorder_interval: '*Reorder interval',
        create_reorder_interval: '*Create reorder interval',
        reorder_interval_created: '*Reorder interval created',
      },
      update_product_reorder_interval_form: {
        date: '*Date',
        reorder_interval: '*Reorder interval',
        update_reorder_interval: '*Update reorder interval',
        reorder_interval_updated: '*Reorder interval updated',
      },
      create_offer_performance_overwrite_form: {
        date: '*Date',
        visits: '*Visits',
        create_offer_performance_overwrite: '*Create offer performance overwrite',
        offer_performance_overwrite_created: '*Offer performance overwrite created',
      },
      update_offer_performance_overwrite_form: {
        date: '*Date',
        visits: '*Visits',
        update_offer_performance_overwrite: '*Update offer performance overwrite',
        offer_performance_overwrite_updated: '*Offer performance overwrite updated',
      },
      create_manual_seasonal_event_form: {
        create_manual_seasonal_event: '*Create manual seasonal event',
        manual_seasonal_event_created: '*Manual seasonal event created',
        date: '*Date',
        name: '*Name',
      },
      create_manual_performance_event_form: {
        create_manual_performance_event: '*Create manual performance event',
        manual_performance_event_created: '*Manual performance event created',
        date: '*Date',
        note: '*Note',
      },
    },
    products: {
      products: '*Products',
      no_items: '*No items',
      product: '*Product',
    },
    offer_dropdown_select: {
      label: '*Label',
    },
    store_dropdown_select: {
      label: '*Label',
    },
    category_dropdown_select: {
      label: '*Label',
    },
    campaign_dropdown_select: {
      label: '*Label',
    },
    rolling_average_days_dropdown_select: {
      label: '*Label',
    },
    deviation_chart_type_dropdown_select: {
      label: '*Label',
    },
    ad_group_dropdown_select: {
      label: '*Label',
    },
    search_term_dropdown_select: {
      label: '*Label',
    },
    latest_days_dropdown_select: {
      label: '*Label',
      latest_14_days: '*Latest 14 days',
      latest_30_days: '*Latest 30 days',
      latest_60_days: '*Latest 60 days',
      latest_90_days: '*Latest 90 days',
      latest_150_days: '*Latest 150 days',
    },
    contested_category_dropdown_select: {
      label: '*Label',
      contested_category_activated: '*Contested category activated',
    },
  },
  errors: {
    401: {
      title: '401',
      subtitle: 'You are not logged in, log in to continue.',
    },
    404: {
      title: '404',
      subtitle: 'The page is available, try again later.',
    },
    500: {
      title: '500',
      subtitle: 'Something is going wrong on our side, try again later.',
    },
    back_to_home: 'Back to login',
  },
}) as TranslationTokens;
