import { ReturnDetailedReason } from '../../../../domain/automator/ReturnDetailedReason';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

const useTranslateDetailedReason = (detailedReason?: ReturnDetailedReason) => {
  const { t } = useTranslation();

  switch (detailedReason) {
    case ReturnDetailedReason.PRODUCT_DAMAGED:
      return t(tokens.automator.returns.detailed_reason.product_damaged);
    case ReturnDetailedReason.PRODUCT_DEFECTIVE:
      return t(tokens.automator.returns.detailed_reason.product_defective);
    case ReturnDetailedReason.PRODUCT_INCOMPLETE:
      return t(tokens.automator.returns.detailed_reason.product_incomplete);
    case ReturnDetailedReason.QUALITY_NOT_AS_EXPECTED:
      return t(tokens.automator.returns.detailed_reason.quality_not_as_expected);
    case ReturnDetailedReason.OTHER:
      return t(tokens.automator.returns.detailed_reason.unknown);
    case ReturnDetailedReason.DELIVERY_TOO_LATE:
      return t(tokens.automator.returns.detailed_reason.delivery_too_late);
    case ReturnDetailedReason.PRODUCT_INFORMATION_UNCLEAR:
      return t(tokens.automator.returns.detailed_reason.product_information_unclear);
    case ReturnDetailedReason.PRODUCT_IMAGE_UNCLEAR:
      return t(tokens.automator.returns.detailed_reason.product_image_unclear);
    case ReturnDetailedReason.TOO_BIG:
      return t(tokens.automator.returns.detailed_reason.too_big);
    case ReturnDetailedReason.TOO_SMALL:
      return t(tokens.automator.returns.detailed_reason.too_small);
    case ReturnDetailedReason.RECEIVED_PRODUCT_AS_GIFT:
      return t(tokens.automator.returns.detailed_reason.received_product_as_gift);
    case ReturnDetailedReason.CHANGED_MIND:
      return t(tokens.automator.returns.detailed_reason.changed_mind);
    default:
      return t(tokens.automator.returns.detailed_reason.unknown);
  }
};

export default useTranslateDetailedReason;
