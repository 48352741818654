import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { tokens } from '../../../../../../locales/translationTokens';
import { PerformanceHeaderTableCell } from '../PerformanceHeaderTableCell';
import { useFetchPerformanceEvents } from '../../../../../../api/pd/timeline/useFetchPerformanceEvents';
import { getDateStringFromDate } from '../../../../../../helpers/getDateStringFromDate';
import { SlimTableCell } from '../../../../../../components/SlimTableCell';
import Offer from '../../../../../../domain/pd/Offer';
import { VolumeDiscountIndicator } from '../VolumeDiscountIndicator';
import { useFetchManualPerformanceEvents } from '../../../../../../api/pd/timeline/useFetchManualPerformanceEvents';
import { useFetchManualSeasonalEvents } from '../../../../../../api/pd/timeline/useFetchManualSeasonalEvents';
import { TimelineEvent } from '../TimeLineEventMarker';
import { FixedSeasonalEvent, useFixedSeasonalEvents } from '../services/useFixedSeasonalEvents';
import ManualSeasonalEvent from '../../../../../../domain/pd/ManualSeasonalEvent';
import { TimelineTableRow } from './TimelineTableRow';
import PerformanceEvent from '../../../../../../domain/pd/PerformanceEvent';
import ManualPerformanceEvent from '../../../../../../domain/pd/ManualPerformanceEvent';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useOpenDialog } from '../../../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../../../store/dialogs/DialogId';
import { generateDateRangeList } from '../services/generateDateList';
import { CountryCode } from '../../../../../../domain/automator/CountryCode';

interface TimelineTableProps {
  offer: Offer;
  startDate: Date;
  endDate: Date;
  countryCode: CountryCode | null;
}

export const TimelineTable = ({ offer, startDate, endDate, countryCode }: TimelineTableProps) => {
  const { data: performanceEvents, isLoading: isLoadingPerformanceEvents } =
    useFetchPerformanceEvents(offer.id, startDate, endDate, countryCode);

  const { data: manualPerformanceEvents, isLoading: isLoadingManualPerformanceEvents } =
    useFetchManualPerformanceEvents(offer.id, startDate, endDate);

  const { data: manualSeasonalEvents, isLoading: isLoadingManualSeasonalEvents } =
    useFetchManualSeasonalEvents(startDate, endDate);

  const openCreateManualSeasonalEventDialog = useOpenDialog(DialogId.CREATE_MANUAL_SEASONAL_EVENT);

  const openCreateManualPerformanceEventDialog = useOpenDialog(
    DialogId.CREATE_MANUAL_PERFORMANCE_EVENT
  );

  const fixedSeasonalEvents = useFixedSeasonalEvents();

  const { t } = useTranslation();

  if (
    isLoadingPerformanceEvents ||
    isLoadingManualSeasonalEvents ||
    isLoadingManualPerformanceEvents
  ) {
    return <TableBody />;
  }

  const getTimelineEventsLeft = (date: Date): TimelineEvent[] => {
    const relevantFixedSeasonalEvents = fixedSeasonalEvents.filter((event) => {
      const month = date.getMonth();
      const day = date.getDate();

      const startDate = event.startDate;
      const endDate = event.endDate;

      if (endDate == null && startDate.getMonth() === month && startDate.getDate() === day) {
        return true;
      } else if (endDate != null && startDate.getMonth() <= month && endDate.getMonth() >= month) {
        return true;
      }
    });

    const relevantManualSeasonalEvents = manualSeasonalEvents!.manualSeasonalEvents.filter(
      (event) => {
        const startDate = new Date(event.startDate);
        const endDate = event.endDate ? new Date(event.endDate) : null;

        if (
          endDate == null &&
          startDate.getMonth() === date.getMonth() &&
          startDate.getDate() === date.getDate()
        ) {
          return true;
        } else if (
          endDate != null &&
          startDate.getMonth() <= date.getMonth() &&
          endDate.getMonth() >= date.getMonth()
        ) {
          return true;
        }

        return false;
      }
    );

    const toTimelineEvent = (event: FixedSeasonalEvent | ManualSeasonalEvent): TimelineEvent => {
      const value1 = t(event.name);

      return {
        value1: value1,
        value2: undefined,
        icon: undefined,
        type: undefined,
      };
    };

    return [
      ...relevantFixedSeasonalEvents.map(toTimelineEvent),
      ...relevantManualSeasonalEvents.map(toTimelineEvent),
    ];
  };

  const getTimelineEventsRight = (date: Date): TimelineEvent[] => {
    const relevantPerformanceEvents = performanceEvents!.performanceEvents.filter(
      (event) => event.date === getDateStringFromDate(date)
    );

    const relevantManualPerformanceEvents = manualPerformanceEvents!.manualPerformanceEvents.filter(
      (event) => event.date === getDateStringFromDate(date)
    );

    const manualToTimelineEvent = (event: ManualPerformanceEvent): TimelineEvent => {
      return {
        value1: event.note,
        value2: undefined,
        icon: undefined,
        type: undefined,
      };
    };

    const performanceToTimelineEvent = (event: PerformanceEvent): TimelineEvent => {
      return {
        value1: event.oldValue,
        value2: event.newValue,
        icon: undefined,
        type: event.type,
      };
    };

    return [
      ...relevantPerformanceEvents.map(performanceToTimelineEvent),
      ...relevantManualPerformanceEvents.map(manualToTimelineEvent),
    ];
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <SlimTableCell
            width={1}
            height="75px"
            colSpan={10}
            style={{
              width: '5px',
              whiteSpace: 'nowrap',
            }}
          >
            <VolumeDiscountIndicator bundlePrices={offer.bundlePrices} />
          </SlimTableCell>
        </TableRow>
      </TableHead>

      <TableHead>
        <TableRow>
          <PerformanceHeaderTableCell>
            <Stack
              direction="row"
              spacing={1}
            >
              <Typography
                variant="body1"
                color="textPrimary"
              >
                S
              </Typography>

              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => openCreateManualSeasonalEventDialog()}
                variant="body1"
                color="textSecondary"
              >
                +
              </Typography>
            </Stack>
          </PerformanceHeaderTableCell>
          <PerformanceHeaderTableCell value={tokens.pd.performance.offer_performances_table.date} />
          <PerformanceHeaderTableCell>
            <Stack
              direction="row"
              spacing={1}
            >
              <Typography
                variant="body1"
                color="textPrimary"
              >
                T
              </Typography>

              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => openCreateManualPerformanceEventDialog({ offerId: offer.id })}
                variant="body1"
                color="textSecondary"
              >
                +
              </Typography>
            </Stack>
          </PerformanceHeaderTableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {generateDateRangeList(startDate, endDate).map((date) => {
          return (
            <TimelineTableRow
              key={date.toISOString()}
              date={date}
              timelineEventsLeft={getTimelineEventsLeft(date)}
              timelineEventsRight={getTimelineEventsRight(date)}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
