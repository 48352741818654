import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { OrderSummaryViewHeader } from './OrderSummaryViewHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RecipientTypeChip from '../../resolutions/components/RecipientTypeChip';
import EmailTaskStatusChip from '../../resolutions/components/EmailStatusChip';
import { EmailTaskStatus } from '../../../../domain/automator/EmailTaskStatus';
import { InitialEmailMessageOpenedIndicator } from './InitialEmailMessageOpenedIndicator';
import MessageThread from '../../../../domain/automator/MessageThread';

interface OrderSummaryEmailTasksViewProps {
  messageThreads: MessageThread[];
}

export const OrderSummaryEmailTasksView = ({ messageThreads }: OrderSummaryEmailTasksViewProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
    >
      <OrderSummaryViewHeader label={t(tokens.automator.order_summary.emails)} />

      <Stack
        direction="column"
        gap={1.5}
      >
        {messageThreads.map((messageThread, index) => {
          return (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              gap={1}
            >
              <Typography
                noWrap
                width={300}
              >
                {messageThread.subject}
              </Typography>
              <Box width={30}>
                {messageThread.initialMessageOpenedDateTime && (
                  <InitialEmailMessageOpenedIndicator
                    initialEmailMessageOpenendDateTime={messageThread.initialMessageOpenedDateTime}
                  />
                )}
              </Box>
              <RecipientTypeChip recipientType={messageThread.recipientType} />

              {messageThread.emailTask && (
                <Stack>
                  <EmailTaskStatusChip emailTaskStatus={messageThread.emailTask.status} />
                  {messageThread.emailTask.status === EmailTaskStatus.PENDING && (
                    <Typography>{messageThread.emailTask.scheduledDateTime}</Typography>
                  )}
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
