import Order from '../../../../../domain/automator/Order';
import Stack from '@mui/material/Stack';
import CopyEmailAddressButton from './CopyEmailAddressButton';
import CopyZipCodeButton from './CopyZipCodeButton';
import CopyInternalReferenceButton from './CopyInternalReferenceButton';
import CopyCustomerNameButton from './CopyCustomerNameButton';
import CopyCityButton from './CopyCityButton';
import CopyStreetNameButton from './CopyStreetNameButton';
import CopyHouseNumberButton from './CopyHouseNumberButton';
import CopyHouseNumberExtensionButton from './CopyHouseNumberExtensionButton';

interface OrderCopyButtonsProps {
  order: Order;
}

export const OrderCopyButtons = ({ order }: OrderCopyButtonsProps) => {
  return (
    <Stack
      direction="row"
      gap={0.2}
    >
      <Stack
        direction="column"
        gap={0.2}
      >
        <CopyCustomerNameButton
          aggregateId={order.id}
          customerName={
            order.shipmentDetails.firstName[0] + '.' + ' ' + order.shipmentDetails.surname
          }
        />
        <CopyStreetNameButton
          aggregateId={order.id}
          streetName={order.shipmentDetails.streetName}
        />
        <CopyZipCodeButton
          aggregateId={order.id}
          zipCode={order.shipmentDetails.zipCode}
        />
      </Stack>

      <Stack
        direction="column"
        gap={0.2}
      >
        {order.items.map(
          (item) =>
            item.internalReference && (
              <CopyInternalReferenceButton
                key={item.internalReference}
                aggregateId={order.id}
                internalReference={item.internalReference}
                quantity={item.quantity}
              />
            )
        )}

        <CopyCityButton
          aggregateId={order.id}
          city={order.shipmentDetails.city}
        />

        <CopyHouseNumberButton
          aggregateId={order.id}
          houseNumber={order.shipmentDetails.houseNumber}
        />
      </Stack>
      <Stack
        direction="column"
        gap={0.2}
      >
        <CopyEmailAddressButton
          aggregateId={order.id}
          emailAddress={order.shipmentDetails.email}
        />

        {order.shipmentDetails.houseNumberExtension && (
          <CopyHouseNumberExtensionButton
            aggregateId={order.id}
            houseNumberExtension={order.shipmentDetails.houseNumberExtension}
          />
        )}
      </Stack>
    </Stack>
  );
};
