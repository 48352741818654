import Stack from '@mui/material/Stack';
import TransporterChip from '../../shipments/components/TransporterChip';
import { LocalShipping } from '@mui/icons-material';
import * as React from 'react';
import TransporterInfo from '../../../../domain/automator/TransporterInfo';

interface ReturnItemTransporterChipProps {
  transporterInfo: TransporterInfo;
}

export const ReturnItemTransporterChip = ({ transporterInfo }: ReturnItemTransporterChipProps) => {
  return (
    <TransporterChip
      key={transporterInfo.transporterCode}
      trackAndTrace={transporterInfo.trackAndTraceUrl}
      variant="outlined"
      icon={
        <Stack direction="row">
          <LocalShipping
            style={{
              color: '#2970ff',
              maxHeight: '22px',
              transform: 'scaleX(-1)',
            }}
          />
        </Stack>
      }
    />
  );
};
