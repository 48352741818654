import { ActionIcon } from '../../orders/components/ActionIcon';
import { HubOutlined } from '@mui/icons-material';
import { tokens } from '../../../../locales/translationTokens';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { colors } from '@mui/material';

interface OpenOrderSummaryActionIconProps {
  bolOrderId: string;
}

export const OpenOrderSummaryActionIcon = ({ bolOrderId }: OpenOrderSummaryActionIconProps) => {
  const openDialog = useOpenDialog(DialogId.VIEW_ORDER_SUMMARY);

  return (
    <ActionIcon
      icon={<HubOutlined style={{ color: colors.blue[400] }} />}
      tooltip={tokens.automator.order_summary.order_summary}
      onClick={() => openDialog({ bolOrderId })}
    />
  );
};
