import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { RecipientType } from '../../../../domain/automator/RecipientType';
import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';

interface RecipientTypeToggleButtonGroupProps {
  onSelect: (selectedKey: RecipientType) => void;
  value: RecipientType;
  phase: ThreadPhase;
}

export const RecipientTypeToggleButtonGroup = ({
  onSelect,
  value,
  phase,
}: RecipientTypeToggleButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={(_, value) => onSelect(value as RecipientType)}
      aria-label="Platform"
    >
      <ToggleButton value={RecipientType.BOL}>
        {t(tokens.automator.resolutions.recipient_type.bol)}
      </ToggleButton>
      <ToggleButton value={RecipientType.CUSTOMER}>
        {t(tokens.automator.resolutions.recipient_type.customer)}
      </ToggleButton>
      {phase != ThreadPhase.ORDER && (
        <ToggleButton value={RecipientType.TRANSPORTER}>
          {t(tokens.automator.resolutions.recipient_type.transporter)}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};
