import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { CancelOrderItemForm } from './CancelOrderItemForm';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import OrderItem from '../../../../domain/automator/OrderItem';

export const CancelOrderItemDialog = () => {
  const { isOpen, data } = useIsDialogOpen(DialogId.CANCEL_ORDER_ITEM);
  const closeDialog = useCloseDialog(DialogId.CANCEL_ORDER_ITEM);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={DialogId.CANCEL_ORDER_ITEM}
          title={t(tokens.automator.orders.cancel_order_item)}
        >
          <CancelOrderItemForm
            onClick={() => closeDialog()}
            orderItem={data.orderItem}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CancelOrderItemDialogData {
  orderItem: OrderItem;
}
