import { tokens } from '../../../../locales/translationTokens';
import { useFetchRetailers } from '../../../../api/automator/retailers/useFetchRetailers';
import { DropdownSelect } from '../../shared/DropdownSelect';
import { useEffect } from 'react';

interface RetailerDropdownProps {
  value: number | null;
  onSelect: (id: number) => void;
}

export const RetailerDropdown = ({ value, onSelect }: RetailerDropdownProps) => {
  const { data: retailers } = useFetchRetailers();

  useEffect(() => {
    if (retailers && retailers.length > 0 && !value) {
      onSelect(retailers[0].id);
    }
  }, [retailers, onSelect]);

  const options =
    retailers?.map((retailer) => ({
      key: retailer.id,
      value: retailer.name,
    })) || [];

  return (
    <DropdownSelect
      options={options}
      onSelect={onSelect}
      label={tokens.automator.retailers.retailer}
    />
  );
};
