import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { useUpdateOfferInternalReference } from '../../../../api/automator/orders/useUpdateOfferInternalReference';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

// define a schema using yup

interface UpdateOfferInternalReferenceFormProps {
  onClick?: () => void;
  orderItemId: number;
  initialInternalReference: string;
}

export const UpdateOfferInternalReferenceForm = ({
  onClick,
  orderItemId,
  initialInternalReference,
}: UpdateOfferInternalReferenceFormProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    internalReference: yup
      .string()
      .max(
        20,
        t(
          tokens.automator.orders.dialogs.update_internal_reference
            .internal_reference_max_length_warning
        ) as string
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      internalReference: initialInternalReference,
    },
  });

  const { mutate: update, isLoading } = useUpdateOfferInternalReference(orderItemId);

  const onUpdate = (data: any) => {
    update(
      {
        internal_reference: data.internalReference,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(tokens.automator.orders.dialogs.update_internal_reference.internal_reference_updated)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onUpdate)}
    >
      <TextField
        {...register('internalReference')}
        id="note-field"
        label={t(tokens.automator.orders.dialogs.update_internal_reference.internal_reference)}
        variant="filled"
        fullWidth
        style={{ marginBottom: '1em' }}
        error={!!errors.internalReference}
        helperText={errors.internalReference?.message}
      />

      <ActionButton
        icon={<Email />}
        label={t(
          tokens.automator.orders.dialogs.update_internal_reference.update_internal_reference
        )}
        type="submit"
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};
