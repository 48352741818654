import { tokens } from '../../../locales/translationTokens';
import { LabelTypeRuleListTable } from './components/LabelTypeRuleListTable';
import AccountPage from '../../../components/AccountPage';
import { SetAllLabelTypeRulesToLabelTypeButton } from './components/SetAllLabelTypeRulesToLabelTypeButton';
import { useFetchLabelTypeRules } from '../../../api/automator/label-type-rules/useFetchLabelTypeRules';
import { LabelType } from '../../../domain/automator/LabelType';
import { LabelTypeRulesTutorial } from '../../../components/tutorials/LabelTypeRulesTutorial';

const LabelTypeRulesPage = () => {
  const { data: labelTypeRules } = useFetchLabelTypeRules();

  return (
    <AccountPage
      title={tokens.automator.ltr.label_type_rules}
      tutorialAlert={<LabelTypeRulesTutorial />}
      buttons={[
        <SetAllLabelTypeRulesToLabelTypeButton
          label={tokens.automator.ltr.set_all_to_mailbox}
          labelType={LabelType.MAILBOX}
          toastMessage={tokens.automator.ltr.all_set_to_mailbox}
          labelTypeRules={labelTypeRules}
          key={1}
        />,

        <SetAllLabelTypeRulesToLabelTypeButton
          label={tokens.automator.ltr.set_all_to_parcel}
          labelType={LabelType.PARCEL}
          toastMessage={tokens.automator.ltr.all_set_to_parcel}
          labelTypeRules={labelTypeRules}
          key={2}
        />,

        <SetAllLabelTypeRulesToLabelTypeButton
          label={tokens.automator.ltr.set_all_to_bol_recommended}
          labelType={LabelType.BOL_RECOMMENDED}
          toastMessage={tokens.automator.ltr.all_set_to_bol_recommended}
          labelTypeRules={labelTypeRules}
          key={3}
        />,
      ]}
    >
      <LabelTypeRuleListTable labelTypeRules={labelTypeRules} />
    </AccountPage>
  );
};

export default LabelTypeRulesPage;
