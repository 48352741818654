import ShipmentDetails from '../../../../domain/automator/ShipmentDetails';
import { CountryCode } from '../../../../domain/automator/CountryCode';
import { PickupPointIcon } from './PickupPointIcon';
import Stack from '@mui/material/Stack';
import { FlagIcon } from './FlagIcon';

interface ShipmentDetailsAddressProps {
  shipmentDetails: ShipmentDetails;
  pickupPoint?: boolean;
}

export const ShipmentDetailsAddress = ({
  shipmentDetails,
  pickupPoint,
}: ShipmentDetailsAddressProps) => {
  return (
    <Stack
      display={'flex'}
      flexDirection={'column'}
      gap={0.1}
    >
      <Stack
        direction="row"
        gap={0.5}
        alignItems="center"
      >
        {shipmentDetails.streetName} {shipmentDetails.houseNumber}{' '}
        {shipmentDetails.houseNumberExtension}
      </Stack>
      <Stack
        direction="row"
        gap={0.7}
        alignItems="center"
      >
        {shipmentDetails.zipCode} {shipmentDetails.city}
        {'  '}
        {shipmentDetails.countryCode == CountryCode.BE && <FlagIcon countryCode={CountryCode.BE} />}
        {pickupPoint && <PickupPointIcon />}
      </Stack>
    </Stack>
  );
};
