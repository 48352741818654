import { RETURN_SCANS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import ReturnScans from '../../../domain/automator/ReturnScans';
import { ReturnScansQueryKey } from '../../queryKeys/ReturnScansQueryKey';

export const useFetchReturnScans = () => {
  const url = useAutomatorApiAccountUrl(RETURN_SCANS_PATH);
  return useFetchData<ReturnScans>(url, ReturnScansQueryKey());
};
