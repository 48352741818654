export const tokensWithoutValues = {
  common: {
    language_changed: undefined,
    created_date_time: undefined,
    loading: undefined,
    no_items: undefined,
    actions: {
      actions: undefined,
      delete: undefined,
    },
    netherlands: undefined,
    belgium: undefined,
    close: undefined,
    save: undefined,
    add: undefined,
    confirm: undefined,
    language_change: undefined,
    action_error: undefined,
    select: undefined,
    on: undefined,
    off: undefined,
    required: undefined,
    incorrect: undefined,
    address: {
      address: undefined,
      zip_code: undefined,
      city: undefined,
      house_number: undefined,
      house_number_extension: undefined,
      street_name: undefined,
      country: undefined,
    },
    days_of_the_week: {
      sunday: undefined,
      monday: undefined,
      tuesday: undefined,
      wednesday: undefined,
      thursday: undefined,
      friday: undefined,
      saturday: undefined,
    },
    recipient_type: {
      recipient_type: undefined,
      transporter: undefined,
      seller: undefined,
      customer: undefined,
      bol: undefined,
    },
    email_status: {
      email_status: undefined,
      sent: undefined,
      cancelled: undefined,
      pending: undefined,
    },
    pick_step_type: {
      request_label: undefined,
      fetch_label: undefined,
      approval: undefined,
      request_shipment: undefined,
      fetch_shipment: undefined,
    },
    resend_status: {
      open: undefined,
      handled: undefined,
    },
  },
  nav: {
    orders: undefined,
    vvb_orders: undefined,
    non_vvb_orders: undefined,
    pick_sessions: undefined,
    label_type_rules: undefined,
    retailers: undefined,
    returns: undefined,
    return_scans: undefined,
    email_threads: undefined,
    resolution_dossiers: undefined,
    resends: undefined,
    reports: undefined,
    shipments: undefined,
    login: undefined,
    register: undefined,
    email_rules: undefined,
    error: undefined,
    logout: undefined,
    settings: undefined,
    subscribe: undefined,
    customer_portal: undefined,
    support: undefined,
    pick_sessions_needing_approval: undefined,
  },
  auth: {
    email_address: undefined,
    password: undefined,
    invalid_email_address: undefined,
    email_required: undefined,
    password_required: undefined,
    login: {
      login: undefined,
      incorrect_password: undefined,
      email_address_already_in_use: undefined,
      forgot_password: undefined,
      unknown_error: undefined,
    },
    register: {
      password_min_length: undefined,
      password_alpha: undefined,
      password_numeric: undefined,
      passwords_must_match: undefined,
      confirm_password_required: undefined,
      confirm_password: undefined,
      register: undefined,
    },
    request_new_password: {
      request_new_password: undefined,
      do_request_new_password: undefined,
      set_new_password: undefined,
      token_invalid: undefined,
      email_sent: undefined,
      new_password_set: undefined,
    },
  },
  automator: {
    products: {
      products: undefined,
    },
    order_summary: {
      search_here: undefined,
      order_summary: undefined,
      order: undefined,
      shipment: undefined,
      returns: undefined,
      note: undefined,
      resolution_cases: undefined,
      resends: undefined,
      emails: undefined,
    },
    resolutions: {
      resolution_dossiers: undefined,
      resends: undefined,
      email_sent: undefined,
      no_items: undefined,
      show_all: undefined,
      show_last: undefined,
      response: undefined,
      is_openend: undefined,
      is_not_openend: undefined,
      customer_question: undefined,

      indicator: {
        has_case: undefined,
        has_unread_email: undefined,
        has_opened_email: undefined,
      },

      email_variables: {
        email_variables: undefined,
        customer_name: undefined,
        store_name: undefined,
        transporter_name: undefined,
      },

      dialogs: {
        create_email_template: {
          dialog_title: undefined,
          email_template_created: undefined,
          create_email_template: undefined,
          name: undefined,
          subject: undefined,
          body: undefined,
          attachments: undefined,
        },

        update_email_template: {
          dialog_title: undefined,
          email_template_updated: undefined,
          update_email_template: undefined,
          name: undefined,
          subject: undefined,
          body: undefined,
          attachments: undefined,
        },

        send_email: {
          amount_of_recipients: undefined,
          transporter_unknown: undefined,
          aggregate_id_needs_to_be_selected_error: undefined,
          dialog_title: undefined,
          send_email: undefined,
          forward_email: undefined,
          email_template: undefined,
          order_templates: undefined,
          shipment_templates: undefined,
          return_templates: undefined,
          phase: undefined,
          email_sent: undefined,
          subject: undefined,
          recipient: undefined,
          body: undefined,
          start_with_case: undefined,
          attachments: undefined,
          archive_email_template: undefined,
          email_template_archived: undefined,
        },
        send_email_thread_reply: {
          dialog_title: undefined,
          send_reply: undefined,
          reply_sent: undefined,
          body: undefined,
        },
        create_resend: {
          dialog_title: undefined,
          note: undefined,
          create_resend: undefined,
          resend_created: undefined,
        },
      },
      actions: {
        handle_case: undefined,
        case_handled: undefined,
        handle_resend: undefined,
        resend_handled: undefined,
        case_awaiting_response: undefined,
        case_set_to_awaiting_response: undefined,
      },
      email_type: {
        return_item: undefined,
        order_item: undefined,
        shipment: undefined,
      },
      resolution_dossier_status: {
        open: undefined,
        handled: undefined,
        awaiting_response: undefined,
      },
      resolution_case_status: {
        open: undefined,
        handled: undefined,
        awaiting_response: undefined,
      },

      recipient_type: {
        recipient_type: undefined,
        customer: undefined,
        bol: undefined,
        transporter: undefined,
        seller: undefined,
      },

      phase: {
        order: undefined,
        return: undefined,
        shipment: undefined,
        other: undefined,
      },

      resolution_case_table: {
        subject: undefined,
      },

      resolution_dossier_table: {
        phase: undefined,
        recipient_type: undefined,
        note: undefined,
        order_info: undefined,
        subject: undefined,
        created_date_time: undefined,
        new_reply: undefined,
        awaiting_reply_response: undefined,
        is_old: undefined,
      },

      resend_table: {
        order_items: undefined,
        order_number: undefined,
        shipment_details: undefined,
        note: undefined,
      },

      email_messages_table: {
        sender: undefined,
        recipient: undefined,
        body: undefined,
        sent_date_time: undefined,
      },
    },
    pick_orders: {
      status: undefined,
      approve_pick_orders: undefined,
      pick_orders_rejected: undefined,
      reject_pick_orders: undefined,
      pick_orders_approved: undefined,
    },

    orders: {
      delivery_code: {
        delivery_code: undefined,
        a24uurs_23: undefined,
        a24uurs_22: undefined,
        a24uurs_21: undefined,
        a24uurs_20: undefined,
        a24uurs_19: undefined,
        a24uurs_18: undefined,
        a24uurs_17: undefined,
        a24uurs_16: undefined,
        a24uurs_15: undefined,
        a24uurs_14: undefined,
        a24uurs_13: undefined,
        a24uurs_12: undefined,
        a1_2d: undefined,
        a2_3d: undefined,
        a3_5d: undefined,
        a4_8d: undefined,
        a1_8d: undefined,
        mijnleverbelofte: undefined,
        vvb: undefined,
      },

      no_items: undefined,
      no_shipped_orders_this_days: undefined,
      shipped_orders: undefined,
      open_product_page: undefined,
      bulk_mail: undefined,
      select_items: undefined,
      abort_bulk_mail: undefined,
      dialogs: {
        update_internal_reference: {
          dialog_title: undefined,
          internal_reference: undefined,
          add_internal_reference: undefined,
          update_internal_reference: undefined,
          internal_reference_updated: undefined,
          internal_reference_max_length_warning: undefined,
        },
        update_order_note: {
          dialog_title: undefined,
          update_note: undefined,
          note_updated: undefined,
          note: undefined,
        },
        update_offer: {
          update_offers: undefined,
          price: undefined,
          stock: undefined,
          stock_amount: undefined,
          corrected_stock: undefined,
          delivery_code: undefined,
          listing: undefined,
          offer_updated: undefined,
          one_euro_up: undefined,
          one_euro_down: undefined,
        },
      },
      tutorials: {
        without_retailer_message: undefined,
        without_retailer_button: undefined,
        with_retailer_message: undefined,
        with_retailer_button: undefined,
        internal_reference_info: undefined,
      },
      orders: undefined,
      bol_order_id: undefined,
      order_items: undefined,
      recipient: undefined,
      store: undefined,
      cancel: undefined,
      cancellation_request: undefined,
      cancellation_requests_approved: undefined,
      toasts: {
        label_type_changed: undefined,
      },
      buttons: {
        start_vvb_pick_session: undefined,
        start_non_vvb_pick_session: undefined,
        set_to_mailbox: undefined,
        set_to_mailbox_success: undefined,
        set_to_parcel: undefined,
        set_to_parcel_success: undefined,
        copy: {
          copy_customer_name: undefined,
          customer_name_copied: undefined,
          copy_zip_code: undefined,
          zip_code_copied: undefined,
          copy_internal_reference: undefined,
          internal_reference_copied: undefined,
          copy_city: undefined,
          city_copied: undefined,
          copy_street_name: undefined,
          street_name_copied: undefined,
          copy_house_number: undefined,
          house_number_copied: undefined,
          copy_house_number_extension: undefined,
          house_number_extension_copied: undefined,
        },
      },
      status: {
        open: undefined,
        open_later: undefined,
        pick_session_pending: undefined,
        shipped: undefined,
        cancelled: undefined,
        failed: undefined,
        can_not_process: undefined,
      },
      selection_set_to_mailbox: undefined,
      cancel_order_item: undefined,
      order_item_cancelled: undefined,
      order_item_cancellation_explanation: undefined,
      cancellation_reason: {
        cancellation_reason: undefined,
        out_of_stock: undefined,
        requested_by_customer: undefined,
        bad_condition: undefined,
        higher_shipcost: undefined,
        incorrect_price: undefined,
        not_avail_in_time: undefined,
        no_bol_guarantee: undefined,
        ordered_twice: undefined,
        retain_item: undefined,
        tech_issue: undefined,
        unfindable_item: undefined,
        other: undefined,
      },
      can_not_process_external_processed_order: undefined,
      can_not_process_order_with_conflicting_handover_date_times: undefined,
      can_not_process_order_loading_error: undefined,
      can_not_process_order_with_mixed_distribution_parties: undefined,
      is_vvb_non_vvb_combo: undefined,
      shipment_date_time: undefined,
      latest_handover_date: {
        explanation: undefined,
        latest_handover_date: undefined,
        let_bol_decide: undefined,
        latest_handover_date_updated: undefined,
      },
      force_do_not_ignore_latest_handover_date_explanation: undefined,
      order_placed_date_tooltip: undefined,
      repeat_customer_tooltip: undefined,
      repeat_customer: undefined,
      latest_handover_date_tooltip: undefined,
      expected_delivery_date_tooltip: undefined,
      approve_customer_cancellations: undefined,
      errors: {
        request_already_in_progress: undefined,
        unknown_error: undefined,
      },
    },
    retailers: {
      no_items: undefined,
      tutorials: {
        without_retailer_message: undefined,
        without_retailer_button: undefined,
        with_retailer_dialog_title: undefined,
        with_retailer_dialog_message: undefined,
        with_retailer_dialog_button: undefined,
      },
      retailer: undefined,
      retailers: undefined,
      name: undefined,
      created_date: undefined,
      forward_email_address: undefined,
      retailer_blocked_explanation: undefined,
      status: {
        status: undefined,
        blocked: undefined,
        active: undefined,
      },
      add_retailer: {
        add_retailer: undefined,
        add_retailer_success: undefined,
        add_retailer_explanation: undefined,
        client_id: undefined,
        secret: undefined,
        retailer_added: undefined,
      },
      update_retailer_address: {
        update_retailer_address: undefined,
      },
    },
    reports: {
      reports: undefined,
      quarter: undefined,
      download: undefined,
    },
    pick_sessions: {
      is_shipped_automatically: undefined,
      tutorials: {
        message: undefined,
      },
      no_items: undefined,
      confirm_start_pick_session: undefined,
      non_vvb_be_nl_mix_warning: undefined,
      pick_sessions: undefined,
      progress: undefined,
      failures: undefined,
      phase: {
        phase: undefined,
        failed: undefined,
        shipping: undefined,
        shipped: undefined,
        approval_pending: undefined,
        creating_labels: undefined,
        shipping_pending: undefined,
      },
      pick_order_status: {
        failed: undefined,
        rejected: undefined,
        shipped: undefined,
      },
      buttons: {
        download_pick_list: undefined,
        download_enriched_labels: undefined,
        download_enriched_labels_disabled: undefined,
        download_clean_labels: undefined,
        download_clean_labels_disabled: undefined,
      },
      label_generation: {
        label_generation: undefined,
        manual: undefined,
        buy_from_bol: undefined,
        vvb: undefined,
      },
      pick_session_started: undefined,
      transporter_codes: {
        transporter_code: undefined,
        none: undefined,
        tnt: undefined,
        tnt_brief: undefined,
        rjp: undefined,
        dhlforyou: undefined,
        budbee: undefined,
        dhl_sd: undefined,
        tnt_extra: undefined,
        tnt_express: undefined,
        dyl: undefined,
        dpd_nl: undefined,
        dpd_be: undefined,
        bpost_be: undefined,
        bpost_brief: undefined,
        gls: undefined,
        fedex_nl: undefined,
        fedex_be: undefined,
        dhl_de: undefined,
        dhl_global_mail: undefined,
        other: undefined,
        tsn: undefined,
        transmission: undefined,
        parcel_nl: undefined,
        packs: undefined,
        courier: undefined,
        trunkrs: undefined,
        pes: undefined,
        cycloon: undefined,
        ups: undefined,
        unknown: undefined,
      },
    },
    ltr: {
      tutorials: {
        message: undefined,
      },
      no_items: undefined,
      label_type_rules: undefined,
      title: undefined,
      retailers: undefined,
      more_than: undefined,
      label_type_changed: undefined,
      set_all_to_mailbox: undefined,
      all_set_to_mailbox: undefined,
      set_all_to_parcel: undefined,
      all_set_to_parcel: undefined,
      set_all_to_bol_recommended: undefined,
      all_set_to_bol_recommended: undefined,
    },
    email_rules: {
      name: undefined,
      conditions: undefined,
      email_rules: undefined,
      product: undefined,
      distribution_party: undefined,
      country_code: undefined,
      recipient: undefined,
      form: {
        create_email_rule: undefined,
        update_email_rule: undefined,
        email_rule_created: undefined,
        email_rule_updated: undefined,
        execute_in_days: undefined,
        execute_at_time: undefined,
        trigger_required: undefined,
        one_country_code_required: undefined,
        one_distribution_party_required: undefined,
        email_template_required: undefined,
        hours_required: undefined,
        days_required: undefined,
        time_required: undefined,
        delay_days: undefined,
        delay_hours: undefined,
        delay_time: undefined,
      },
      disabled: undefined,
      enabled: undefined,

      update_email_template: undefined,
      update_email_rule: undefined,

      triggers: {
        trigger: undefined,
        new_order: undefined,
        order_is_placed_double: undefined,
        customer_is_recurring: undefined,
        shipment_track_and_trace_uploaded: undefined,
        shipment_send: undefined,
        shipment_delivered: undefined,
        shipment_at_pickup_point: undefined,
        shipment_returned_to_sender: undefined,
        return_registered: undefined,
        recoverable_return_item_registered: undefined,
        recoverability_unclear_return_item_registered: undefined,
        not_recoverable_return_item_registered: undefined,
        return_handled: undefined,
        expected_delivery_date_reached: undefined,
      },
      delay_type: {
        delay: undefined,
        none: undefined,
        hours: undefined,
        days: undefined,
        time: undefined,
      },
    },
    label_type: {
      label_type: undefined,
      parcel: undefined,
      bol_recommended: undefined,
      mailbox: undefined,
    },
    settings: {
      settings: undefined,
      automatically_approve_cancel_requests: undefined,
      automatically_approve_cancel_requests_tooltip: undefined,
      ignore_latest_handover_date: undefined,
      ignore_latest_handover_date_tooltip: undefined,
      save_settings: undefined,
      settings_saved: undefined,
    },
    pricing: {
      title: undefined,
      subtitle: undefined,
      tier_1: {
        title: undefined,
        feature_1: undefined,
        feature_2: undefined,
      },
      tier_2: {
        title: undefined,
        feature_1: undefined,
        feature_2: undefined,
      },
      tier_3: {
        title: undefined,
        feature_1: undefined,
        feature_2: undefined,
      },
      ex_vat: undefined,
      monthly: undefined,
      subscribe: undefined,
      payment_successful: undefined,
      payment_failed: undefined,
      subscription_deactivated: undefined,
      trial_expired: undefined,
    },
    shipments: {
      actions: {
        add_track_and_trace: {
          track_and_trace: undefined,
          track_and_trace_added: undefined,
        },
      },

      has_returned_to_sender: undefined,

      no_items: undefined,
      shipments: undefined,
      no_shipments_on_this_day: undefined,
      total_shipments_track_and_traceble: undefined,
      total_shipments_non_track_and_traceble: undefined,
      total_delivered: undefined,
      total_at_pickup_point: undefined,
      total_returned_to_sender: undefined,
      total_in_transit: undefined,
      total_at_transporter: undefined,
      total_inbound_collect: undefined,
      total_pre_announced: undefined,
      is_resent_after_return_tooltip: undefined,
      customer: undefined,
      internal_reference: undefined,
      transporter: undefined,
      expectedDeliveryDate: undefined,
      retailer: undefined,
      distribution_party: {
        bol: undefined,
        retailer: undefined,
        mixed: undefined,
      },
      status: {
        status: undefined,
        waiting_for_events: undefined,
        pre_announced: undefined,
        at_transporter: undefined,
        in_transit: undefined,
        inbound_collect: undefined,
        at_pick_up_point: undefined,
        picked_up_at_pick_up_point: undefined,
        delivered_at_customer: undefined,
        delivered_at_neighbours: undefined,
        returned_to_sender: undefined,
        not_applicable: undefined,
        overdue: undefined,
      },
      tabs: {
        all: undefined,
        at_pick_up_point: undefined,
        returned_to_sender: undefined,
        overdue: undefined,
      },
      copy_email: undefined,
      email_copied: undefined,
      load_shipment_events: undefined,
      loading_shipment_events_started: undefined,
    },
    return_scans: {
      return_scans: undefined,
      recipient: undefined,
      status: undefined,
      comments: undefined,
      actions: undefined,
      scanned_date: undefined,
      view_photos: undefined,
    },

    returns: {
      refreshed_return: undefined,
      no_items: undefined,
      is_outside_return_window: undefined,
      is_recurring: undefined,
      is_new: undefined,
      returns: undefined,
      today: undefined,
      yesterday: undefined,
      day_before_yesterday: undefined,
      day_old: undefined,
      days_old: undefined,
      other: undefined,
      product: undefined,
      recipient: undefined,
      send_email: undefined,
      create_email_template: undefined,
      processing_results: undefined,
      return_reason: undefined,
      rma_id: undefined,
      track_and_trace: undefined,
      registration_date_time: undefined,
      days_until_expiry: undefined,
      quantity: undefined,
      total_unhandled: undefined,
      status: {
        new: undefined,
        handled: undefined,
        overview: undefined,
        awaiting_exchange: undefined,
        exchanged: undefined,
        on_hold: undefined,
        awaiting_repair: undefined,
        repaired: undefined,
      },
      recoverability: {
        recoverable: undefined,
        not_recoverable: undefined,
        unclear: undefined,
        recoverability_updated: undefined,
      },
      processing_result: {
        accepted: undefined,
        rejected: undefined,
        cancelled: undefined,
      },
      handling_result: {
        return_received: undefined,
        exchange_product: undefined,
        return_does_not_meet_conditions: undefined,
        repair_product: undefined,
        customer_keeps_product_paid: undefined,
        still_approved: undefined,
        customer_keeps_product_free_of_charge: undefined,
        return_item_lost: undefined,
        expired: undefined,
        excessive_return: undefined,
        still_received: undefined,
        cancelled_by_customer: undefined,
        failed_to_create_shipping_label: undefined,
        under_investigation: undefined,
        failed_to_collect_by_transporter: undefined,
      },
      detailed_reason: {
        product_damaged: undefined,
        product_defective: undefined,
        product_incomplete: undefined,
        quality_not_as_expected: undefined,
        delivery_too_late: undefined,
        product_information_unclear: undefined,
        product_image_unclear: undefined,
        too_big: undefined,
        too_small: undefined,
        received_product_as_gift: undefined,
        changed_mind: undefined,
        unknown: undefined,
      },
      actions: {
        accept: undefined,
        return_item_accepted: undefined,
        still_accept: undefined,
        processing_result_still_accepted: undefined,
        reject: undefined,
        return_item_rejected: undefined,
        set_to_awaiting_exchange: undefined,
        set_to_awaiting_arrival: undefined,
        set_to_on_hold: undefined,
        set_to_awaiting_repair: undefined,
        set_to_new: undefined,
        send_email: undefined,

        update_seller_comments: undefined,
        seller_comments_updated: undefined,
      },
      dialogs: {
        handle_return_item: {
          dialog_title: undefined,
          approve_return: undefined,
          reject_return: undefined,
          handle_return_explanation: undefined,
        },
        create_return: {
          dialog_title: undefined,
          retailer: undefined,
          bol_order_id: undefined,
          bol_order_item_id: undefined,
          quantity_returned: undefined,
          handling_result: undefined,
          return_created: undefined,
        },
        send_email: {
          send_email: undefined,
        },
      },
    },
  },
  customer_order: {
    send_to_pickup_point_thank_you_message: undefined,
    send_to_pickup_point_already_shipped_message: undefined,
  },
  pd: {
    performance: {
      tabs: {
        offer_performance: undefined,
        product_performance: undefined,
        ad_search_term_performance: undefined,
      },
      performances: undefined,
      no_items: undefined,

      performance_note_form: {
        note: undefined,
        update_note: undefined,
        note_updated: undefined,
      },

      offer_performances_table: {
        offer_performances: undefined,
        date: undefined,
      },

      competitor_product_performances_table: {
        position: undefined,
        no_data: undefined,
      },

      timeline_table: {
        seasonals: {
          new_years_day: undefined,
          valentines_day: undefined,
          international_womens_day: undefined,
          saint_patricks_day: undefined,
          international_day_of_happiness: undefined,
          april_fools_day: undefined,
          ascension_day: undefined,
          mothers_day: undefined,
          liberation_day: undefined,
          fathers_day: undefined,
          summer_break: undefined,
          teachers_day: undefined,
          halloween: undefined,
          christmas_day: undefined,
          new_years_eve: undefined,
          saint_nicholas_day: undefined,
          good_friday: undefined,
          world_animal_day: undefined,
          eid_al_fitr: undefined,
          feast_of_sacrifice: undefined,
          carnaval: undefined,
          first_pentecost_day: undefined,
          second_pentecost_day: undefined,
          black_friday: undefined,
          cyber_monday: undefined,
          chinese_new_year: undefined,
          kings_day: undefined,
          secretary_day: undefined,
        },
      },

      offer_performances_totals: {
        total_profit: undefined,
        total_ad_costs: undefined,
        total_visits: undefined,
        total_articles_sold: undefined,
        conversion_rate: undefined,
        total_revenue: undefined,
        total_purchase_value: undefined,
      },

      volume_discount_indicator: {
        volume_discount: undefined,
        yes: undefined,
        no: undefined,
        price_unknown: undefined,
      },

      organic_non_organic_table: {
        organic_vs_non_organic: undefined,
        option: {
          all: undefined,
          organic: undefined,
          non_organic: undefined,
        },
        conversion_rate: undefined,
        orders_conversion_rate: undefined,

        articles: undefined,
        orders: undefined,
        visits: undefined,
      },

      offer_performance_search_metrics_table: {
        search_metrics: undefined,
        impressions: undefined,
        ctr: undefined,
        weighted_rank: undefined,
      },

      offer_performance_metrics_table: {
        metrics: undefined,
        daily_profit: undefined,
        yearly_profit: undefined,
        ROIDE: undefined,
      },

      ad_performances_table: {
        ad_performances: undefined,
        impressions: undefined,
        clicks: undefined,
        ctr: undefined,
        conversions: undefined,
        conversion_rate: undefined,
        sales: undefined,
        acos: undefined,
        cpc: undefined,
        spent: undefined,
      },

      product_rank_search_term_category_table: {
        type: undefined,
        label: undefined,
        average_rank: undefined,
        total_impressions: undefined,
        total_volume: undefined,
        is_relevant: undefined,
        is_relevant_updated: undefined,
      },

      ad_group_search_term_performance_search_terms_table: {
        search_term: undefined,
        total_impressions: undefined,
        total_clicks: undefined,
        impressions_share: undefined,
        click_share: undefined,
        total_conversions: undefined,
        total_sales: undefined,
        total_spent: undefined,
        average_cpc: undefined,
        average_acos: undefined,
        average_ctr: undefined,
        average_conversion_rate: undefined,
        average_winning_bid_average: undefined,
        total_search_volume: undefined,
      },

      search_term_volume_chart: {
        title: undefined,
        volume: undefined,
      },

      assortment_impressions_chart: {
        title: undefined,
        impressions: undefined,
      },
      assortment_visits_chart: {
        title: undefined,
        visits: undefined,
      },
      ad_group_search_term_performance_chart: {
        ad_group_search_term_performance: undefined,
        impressions: undefined,
        clicks: undefined,
        ctr: undefined,
        conversions: undefined,
        conversion_rate: undefined,
        sales: undefined,
        spent: undefined,
        cpc: undefined,
        acos: undefined,
        roas: undefined,
        average_winning_bid: undefined,
        search_volume: undefined,
        click_share_percentage: undefined,
        impression_share_percentage: undefined,
      },

      ad_group_performance_chart: {
        ad_group_performance: undefined,
        impressions: undefined,
        clicks: undefined,
        conversions: undefined,
        sales: undefined,
        spent: undefined,
        impression_share: undefined,
        click_share: undefined,
      },

      product_ranks_chart: {
        product_ranks: undefined,
        loaded_rank: undefined,
        rank: undefined,
        impressions: undefined,
      },

      create_offer_shipping_costs_form: {
        date: undefined,
        shipping_costs: undefined,
        create_shipping_costs: undefined,
        shipping_costs_created: undefined,
      },

      update_offer_shipping_costs_form: {
        date: undefined,
        shipping_costs: undefined,
        update_shipping_costs: undefined,
        shipping_costs_updated: undefined,
      },

      create_product_purchase_costs_form: {
        date: undefined,
        purchase_costs: undefined,
        create_purchase_costs: undefined,
        purchase_costs_created: undefined,
      },

      update_product_purchase_costs_form: {
        date: undefined,
        purchase_costs: undefined,
        update_purchase_costs: undefined,
        purchase_costs_updated: undefined,
      },

      create_product_purchase_volume_form: {
        date: undefined,
        purchase_volume: undefined,
        create_purchase_volume: undefined,
        purchase_volume_created: undefined,
      },

      update_product_purchase_volume_form: {
        date: undefined,
        purchase_volume: undefined,
        update_purchase_volume: undefined,
        purchase_volume_updated: undefined,
      },

      create_product_reorder_interval_form: {
        date: undefined,
        reorder_interval: undefined,
        create_reorder_interval: undefined,
        reorder_interval_created: undefined,
      },

      update_product_reorder_interval_form: {
        date: undefined,
        reorder_interval: undefined,
        update_reorder_interval: undefined,
        reorder_interval_updated: undefined,
      },

      create_offer_performance_overwrite_form: {
        date: undefined,
        visits: undefined,
        create_offer_performance_overwrite: undefined,
        offer_performance_overwrite_created: undefined,
      },

      update_offer_performance_overwrite_form: {
        date: undefined,
        visits: undefined,
        update_offer_performance_overwrite: undefined,
        offer_performance_overwrite_updated: undefined,
      },

      create_manual_seasonal_event_form: {
        create_manual_seasonal_event: undefined,
        manual_seasonal_event_created: undefined,
        date: undefined,
        name: undefined,
      },

      create_manual_performance_event_form: {
        create_manual_performance_event: undefined,
        manual_performance_event_created: undefined,
        date: undefined,
        note: undefined,
      },
    },
    products: {
      products: undefined,
      no_items: undefined,
      product: undefined,
    },

    offer_dropdown_select: {
      label: undefined,
    },
    store_dropdown_select: {
      label: undefined,
    },
    category_dropdown_select: {
      label: undefined,
    },
    campaign_dropdown_select: {
      label: undefined,
    },
    rolling_average_days_dropdown_select: {
      label: undefined,
    },
    deviation_chart_type_dropdown_select: {
      label: undefined,
    },
    ad_group_dropdown_select: {
      label: undefined,
    },
    search_term_dropdown_select: {
      label: undefined,
    },
    latest_days_dropdown_select: {
      label: undefined,
      latest_14_days: undefined,
      latest_30_days: undefined,
      latest_60_days: undefined,
      latest_90_days: undefined,
      latest_150_days: undefined,
    },

    contested_category_dropdown_select: {
      label: undefined,
      contested_category_activated: undefined,
    },
  },

  errors: {
    401: {
      title: undefined,
      subtitle: undefined,
    },
    404: {
      title: undefined,
      subtitle: undefined,
    },
    500: {
      title: undefined,
      subtitle: undefined,
    },
    back_to_home: undefined,
  },
};

const generateTokens = (obj: any, parentKey = ''): any => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (value === undefined) {
        return [key, newKey];
      }
      return [key, generateTokens(value, newKey)];
    })
  );
};

type GenerativeType<T> = {
  [K in keyof T]: T[K] extends Record<string, any> ? GenerativeType<T[K]> : string;
};

export type TranslationTokens = GenerativeType<typeof tokensWithoutValues>;

export const tokens: TranslationTokens = generateTokens(tokensWithoutValues);

const USE_SHORT_KEYS = true;

export function fillMissingKeys(
  template: TranslationTokens,
  data: any | undefined,
  path: string[] = [] // Add a new parameter to keep track of the nested path
): TranslationTokens {
  return Object.fromEntries(
    Object.entries(template).map(([key, value]) => {
      const currentPath = [...path, key]; // Append the current key to the path
      const dataValue = data ? (data as any)[key] : undefined;
      if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
        // Recursively call fillMissingKeys for nested objects, pass the currentPath
        return [key, fillMissingKeys(value as any, dataValue as any, currentPath)];
      } else {
        // If the data value is missing, log the full path
        if (dataValue == undefined) {
          console.log('Missing translation value: ', currentPath.join('.'));
        }
        return [key, dataValue !== undefined ? dataValue : USE_SHORT_KEYS ? key : value];
      }
    })
  ) as TranslationTokens;
}
