import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import { useRejectPickOrders } from '../../../../api/automator/pick-sessions/useRejectPickOrders';
import { toast } from 'react-hot-toast';

interface RejectPickOrdersButtonProps {
  onClick?: () => void;
  pickSessionId: number;
  selectedPickOrdersIds: number[];
  showAmount: boolean;
}

const RejectPickOrdersButton = ({
  onClick,
  pickSessionId,
  selectedPickOrdersIds,
  showAmount,
}: RejectPickOrdersButtonProps) => {
  const { t } = useTranslation();

  const { mutate: mutateReject, isLoading: isLoadingReject } = useRejectPickOrders(pickSessionId);

  const handleReject = (pickOrderIds: number[]) => {
    const data = {
      pick_order_ids: pickOrderIds,
    };
    mutateReject(data, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.pick_orders.pick_orders_rejected));
      },
    });
  };

  return (
    <ActionButton
      size="small"
      isLoading={isLoadingReject}
      onClick={() => {
        handleReject(selectedPickOrdersIds);
        onClick && onClick();
      }}
      label={
        t(tokens.automator.pick_orders.reject_pick_orders) +
        (showAmount ? '  (' + selectedPickOrdersIds.length + ')' : '')
      }
    />
  );
};

export default RejectPickOrdersButton;
