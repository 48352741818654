import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { FormControlLabel } from '@mui/material';
import { useCreatePickSession } from '../../../../api/automator/pick-sessions/useCreatePickSession';
import { useRouter } from '../../../../../devias/src/hooks/use-router';
import { usePaths } from '../../../../routes/usePaths';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import ActionButton from '../../../../components/ActionButton';
import { OrdersQueryKey } from '../../../../api/queryKeys/OrdersQueryKey';
import { OrderStatus } from '../../../../domain/automator/OrderStatus';
import { LabelGeneration } from '../../../../domain/automator/LabelGeneration';
import Order from '../../../../domain/automator/Order';
import Stack from '@mui/material/Stack';
import { DistributionParty } from '../../../../domain/automator/DistributionParty';
import { CountryCode } from '../../../../domain/automator/CountryCode';
import { LabelGenerationDropdown } from '../../pick-sessions/components/LabelGenerationDropdown';
import { useState } from 'react';
import { TransporterCode } from '../../../../domain/automator/TransporterCode';
import { TransporterCodeDropdown } from '../../pick-sessions/components/TransporterCodeDropdown';
import Checkbox from '@mui/material/Checkbox';
import { CreatePickSessionCountrySummary } from './CreatePickSessionCountrySummary';
import Typography from '@mui/material/Typography';

interface ConfirmCreatePickSessionFormProps {
  orders: Order[];
  distributionParty: DistributionParty;
  quickTransporterCode: TransporterCode | null;
  labelGeneration?: LabelGeneration;
  onClick?: () => void;
}

export const ConfirmCreatePickSessionForm = ({
  orders,
  distributionParty,
  quickTransporterCode,
  onClick,
  labelGeneration: labelGeneration_,
}: ConfirmCreatePickSessionFormProps) => {
  const [labelGeneration, setLabelGeneration] = useState<LabelGeneration>(
    distributionParty == DistributionParty.BOL
      ? LabelGeneration.VVB
      : labelGeneration_
      ? labelGeneration_
      : LabelGeneration.MANUAL
  );

  const [transporterCode, setTransporterCode] = useState<TransporterCode | null>(
    quickTransporterCode ? quickTransporterCode : null
  );

  const [isShippedAutomatically, setIsShippedAutomatically] = useState<boolean>(false);

  const { t } = useTranslation();
  const paths = usePaths();
  const router = useRouter();

  const { mutate: createPickSession, isLoading } = useCreatePickSession();

  const queryClient = useQueryClient();

  const belgiumOrders = orders.filter(
    (order) => order.shipmentDetails.countryCode === CountryCode.BE
  );

  const netherlandsOrders = orders.filter(
    (order) => order.shipmentDetails.countryCode === CountryCode.NL
  );

  const hasBelgiumOrder = belgiumOrders.length > 0;

  const hasNetherlandsOrder = netherlandsOrders.length > 0;

  const showCanOnlyDoManualLabelGenerationWarning =
    hasBelgiumOrder && hasNetherlandsOrder && distributionParty === DistributionParty.RETAILER;

  const canSelectLabelGeneration =
    hasBelgiumOrder && !hasNetherlandsOrder && distributionParty === DistributionParty.RETAILER;

  const doCreate = () => {
    createPickSession(
      {
        order_ids: orders.map((order) => order.id),
        label_generation: labelGeneration,
        transporter_code: transporterCode,
        is_shipped_automatically: isShippedAutomatically,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(OrdersQueryKey(OrderStatus.OPEN, distributionParty));
          await queryClient.invalidateQueries(
            OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, distributionParty)
          );
          onClick?.();
          toast.success(t(tokens.automator.pick_sessions.pick_session_started));
          router.push(paths.automator.pick_sessions);
        },
      }
    );
  };

  return (
    <Stack
      direction={'column'}
      gap={5}
      alignItems="center"
    >
      {canSelectLabelGeneration ||
        (labelGeneration == LabelGeneration.MANUAL && (
          <Stack
            paddingTop={2}
            direction="row"
            gap={1}
            width={300}
            justifyContent="center"
          >
            {canSelectLabelGeneration && (
              <LabelGenerationDropdown
                selected={labelGeneration}
                onSelect={setLabelGeneration}
              />
            )}

            {labelGeneration == LabelGeneration.MANUAL && (
              <TransporterCodeDropdown
                selected={transporterCode}
                onSelect={setTransporterCode}
              />
            )}
          </Stack>
        ))}

      <Stack
        paddingTop={2}
        direction="column"
        alignItems="center"
        gap={2}
      >
        {hasBelgiumOrder && (
          <CreatePickSessionCountrySummary
            countryCode={CountryCode.BE}
            amount={belgiumOrders.length}
            transporterCode={transporterCode}
            distributionParty={distributionParty}
          />
        )}

        {hasNetherlandsOrder && (
          <CreatePickSessionCountrySummary
            countryCode={CountryCode.NL}
            amount={netherlandsOrders.length}
            transporterCode={transporterCode}
            distributionParty={distributionParty}
          />
        )}

        {showCanOnlyDoManualLabelGenerationWarning && (
          <Typography
            fontWeight={500}
            style={{ color: 'darkorange' }}
          >
            {t(tokens.automator.pick_sessions.non_vvb_be_nl_mix_warning)}
          </Typography>
        )}
      </Stack>

      <Stack
        direction="column"
        alignItems="center"
        gap={1}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isShippedAutomatically}
              onChange={(event) => setIsShippedAutomatically(event.target.checked)}
              name="isShippedAutomatically"
              color="primary"
            />
          }
          label={t(tokens.automator.pick_sessions.is_shipped_automatically)}
        />

        <ActionButton
          variant="contained"
          onClick={() => doCreate()}
          label={tokens.common.confirm}
          isLoading={isLoading}
        />
      </Stack>
    </Stack>
  );
};
