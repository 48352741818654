import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

const useEmailPhaseTranslations = (phase: ThreadPhase) => {
  const { t } = useTranslation();

  switch (phase) {
    case ThreadPhase.ORDER:
      return t(tokens.automator.resolutions.phase.order);
    case ThreadPhase.SHIPMENT:
      return t(tokens.automator.resolutions.phase.shipment);
    case ThreadPhase.RETURN:
      return t(tokens.automator.resolutions.phase.return);
    case ThreadPhase.OTHER:
      return t(tokens.automator.resolutions.phase.other);
  }
};

export default useEmailPhaseTranslations;
