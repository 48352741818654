import { SHIPMENTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useShipmentQueryKeysUpdater } from './useShipmentsQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';
import Shipments, { AGGREGATE_KEY } from '../../../domain/automator/Shipments';

export const useUpdateShipmentIsResentAfterReturn = (shipmentId: number) => {
  const url = useAutomatorApiAccountUrl(
    SHIPMENTS_PATH + `/` + shipmentId + `/is-resent-after-return`
  );

  const updater = useShipmentQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (shipments: Shipments) => {
    updateData(shipments);
  };

  return useAuthorizedPost<Shipments, UpdateIsResentAfterReturnData>(url, [], undefined, onSuccess);
};

export interface UpdateIsResentAfterReturnData {
  is_resent_after_return: boolean;
}
