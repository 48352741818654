import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { RecipientType } from '../../../../domain/automator/RecipientType';

interface RecipientTypeTypographyProps {
  recipientType: RecipientType;
  minWidth?: number;
}

export const RecipientTypeTypography = ({
  recipientType,
  minWidth,
}: RecipientTypeTypographyProps) => {
  const { t } = useTranslation();

  const determineLabel = (recipientType: RecipientType): string => {
    switch (recipientType) {
      case RecipientType.TRANSPORTER:
        return t(tokens.common.recipient_type.transporter);
      case RecipientType.BOL:
        return t(tokens.common.recipient_type.bol);
      case RecipientType.CUSTOMER:
        return t(tokens.common.recipient_type.customer);
      case RecipientType.SELLER:
        return t(tokens.common.recipient_type.seller);
      default:
        return '';
    }
  };

  const determineColor = (recipientType: RecipientType) => {
    switch (recipientType) {
      case RecipientType.SELLER:
        return 'success';
      case RecipientType.CUSTOMER:
        return 'primary';
      case RecipientType.BOL:
        return 'secondary';
      case RecipientType.TRANSPORTER:
        return 'info';
      default:
        return 'default';
    }
  };

  return (
    <Typography
      minWidth={minWidth}
      fontWeight={600}
      color={determineColor(recipientType)}
    >
      {determineLabel(recipientType)}
    </Typography>
  );
};
