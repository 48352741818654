import { ORDER_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Orders from '../../../domain/automator/Orders';

export const useUpdateOfferInternalReference = (orderItemId: number) => {
  const url = useAutomatorApiAccountUrl(
    ORDER_ITEMS_PATH + '/' + orderItemId + `/update-internal-reference`
  );
  return useAuthorizedPost<Orders, UpdateOfferInternalReferenceData>(url, undefined, undefined);
};

export interface UpdateOfferInternalReferenceData {
  internal_reference: string;
}
