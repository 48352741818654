import { RETURN_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/Returns';
import { OrderSummaryQueryKey } from '../../queryKeys/OrderSummaryQueryKey';
import { ReturnScansQueryKey } from '../../queryKeys/ReturnScansQueryKey';

export const useRejectReturnItem = (returnItemId: number) => {
  const url = useAutomatorApiAccountUrl(RETURN_ITEMS_PATH + '/' + returnItemId + `/reject`);

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns, RejectReturnItemData>(
    url,
    [OrderSummaryQueryKey(undefined), ReturnScansQueryKey()],
    undefined,
    onSuccess
  );
};

export interface RejectReturnItemData {
  quantity_returned: number;
}
