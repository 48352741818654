import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ReturnItem from '../../../../domain/automator/ReturnItem';
import { ReturnRecipient } from './ReturnRecipient';
import Return from '../../../../domain/automator/Return';
import { CountryCode } from '../../../../domain/automator/CountryCode';
import { FlagIcon } from '../../orders/components/FlagIcon';
import Chip from '@mui/material/Chip';
import { ProductThumbnail } from '../../orders/components/ProductThumbnail';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import * as React from 'react';
import { ReturnItemTransporterChip } from './ReturnItemTransporterChip';
import { HasOpenResolutionCaseIndicator } from '../../resolutions/components/HasOpenResolutionCaseIndicator';
import { OpenOrderSummaryActionIcon } from '../../orderSummaries/components/OpenOrderSummaryActionIcon';
import { OpenStartReturnItemEmailThreadDialogButton } from './OpenStartReturnItemEmailThreadDialogButton';
import { ActionType } from '../../orders/components/copyButtons/ActionType';
import { useRefreshReturn } from '../../../../api/automator/returns/useRefreshReturn';
import { toast } from 'react-hot-toast';

interface ReturnItemCardProps {
  quantity: number;
  return_: Return;
  returnItem: ReturnItem;
  showTransporterInfo: boolean;
}

export const ReturnItemCard = ({
  quantity,
  returnItem,
  return_,
  showTransporterInfo,
}: ReturnItemCardProps) => {
  const { t } = useTranslation();

  const { mutate: refreshReturn } = useRefreshReturn(return_.id);

  const viewedDateTime = returnItem.viewedDateTime ? new Date(returnItem.viewedDateTime) : null;
  const isNew = viewedDateTime && viewedDateTime > new Date(Date.now() - 1000 * 60 * 15);

  const onRefreshReturn = () => {
    refreshReturn(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.returns.refreshed_return));
      },
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1.5}
      position="relative"
    >
      {quantity > 1 && (
        <Chip
          size="small"
          sx={{
            height: '20px',
            width: '40px',
            position: 'absolute',
            top: 0.2,
            zIndex: 1,
          }}
          variant="filled"
          color="primary"
          label={<strong>{quantity}X</strong>}
        />
      )}

      <ProductThumbnail
        height={40}
        thumbnailUrl={returnItem.thumbnailUrl}
      />

      <Stack
        alignItems="left"
        direction="column"
        minWidth={225}
        gap={0.5}
      >
        {returnItem.isRecurring && (
          <Chip
            style={{ borderRadius: 6, width: 'fit-content' }}
            size="small"
            label={t(tokens.automator.returns.is_recurring)}
            color={'info'}
            variant="filled"
          />
        )}

        {returnItem.isOutsideReturnWindow && (
          <Chip
            style={{ borderRadius: 6, width: 'fit-content' }}
            color="error"
            size="small"
            label={t(tokens.automator.returns.is_outside_return_window)}
          />
        )}

        <Stack>
          <Stack
            direction="row"
            gap={1}
            minWidth={205}
            alignItems="center"
          >
            {return_.shipmentDetails.countryCode == CountryCode.BE && (
              <FlagIcon countryCode={CountryCode.BE} />
            )}

            <ReturnRecipient shipmentDetails={return_.shipmentDetails} />

            {isNew && (
              <Chip
                style={{ width: 70, borderRadius: 6, height: 13 }}
                size="small"
                label={t(tokens.automator.returns.is_new)}
                color="warning"
                variant="filled"
              />
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems={'center'}
            gap={1}
          >
            <RetailerLooksIcon retailerId={return_.retailerId} />

            <Typography
              variant="body2"
              color="textSecondary"
              minWidth={74}
            >
              {returnItem.bolOrderId}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        gap={1}
        direction="row"
        alignItems="center"
      >
        <OpenOrderSummaryActionIcon bolOrderId={returnItem.bolOrderId} />

        <OpenStartReturnItemEmailThreadDialogButton
          returns={[return_]}
          returnItemId={returnItem.id}
          body={null}
          type={ActionType.ICON}
        />

        {showTransporterInfo &&
          (returnItem.transporterInfos.length > 0 ? (
            returnItem.transporterInfos.map((info) => {
              return (
                info.trackAndTraceUrl && (
                  <ReturnItemTransporterChip
                    key={info.trackAndTraceUrl}
                    transporterInfo={info}
                  />
                )
              );
            })
          ) : (
            <Chip
              style={{ cursor: 'pointer' }}
              onClick={onRefreshReturn}
              size="small"
              label={'TT'}
              color="info"
              variant="filled"
            />
          ))}

        {returnItem.resolutionCases.map((resolutionCase) => {
          return (
            <HasOpenResolutionCaseIndicator
              key={resolutionCase.id}
              reducedResolutionCase={resolutionCase}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
