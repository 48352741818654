enum EmailRuleTrigger {
  NEW_ORDER = 'NEW_ORDER',
  ORDER_IS_PLACED_DOUBLE = 'ORDER_IS_PLACED_DOUBLE',
  CUSTOMER_IS_RECURRING = 'CUSTOMER_IS_RECURRING',

  SHIPMENT_SHIPPED = 'SHIPMENT_SHIPPED',
  SHIPMENT_TRACK_AND_TRACE_UPLOADED = 'SHIPMENT_TRACK_AND_TRACE_UPLOADED',
  SHIPMENT_DELIVERED = 'SHIPMENT_DELIVERED',
  SHIPMENT_DELIVERED_AT_PICKUP_POINT = 'SHIPMENT_DELIVERED_AT_PICKUP_POINT',
  SHIPMENT_RETURNED_TO_SENDER = 'SHIPMENT_RETURNED_TO_SENDER',

  EXPECTED_DELIVERY_DATE_REACHED = 'EXPECTED_DELIVERY_DATE_REACHED',

  RETURN_REGISTERED = 'RETURN_REGISTERED',
  RETURN_HANDLED = 'RETURN_HANDLED',
  RECOVERABLE_RETURN_ITEM_REGISTERED = 'RECOVERABLE_RETURN_ITEM_REGISTERED',
  NOT_RECOVERABLE_RETURN_ITEM_REGISTERED = 'NOT_RECOVERABLE_RETURN_ITEM_REGISTERED',
  RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED = 'RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED',
}

export default EmailRuleTrigger;
