import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import OrderSummary from '../../../../domain/automator/OrderSummary';
import ActionButton from '../../../../components/ActionButton';

interface OpenStartEmailThreadDialogButtonProps {
  orderSummary: OrderSummary;
  icon: React.ReactNode;
  label: string;
  body: string | null;
}

export const OpenStartEmailThreadDialogButton = ({
  orderSummary,
  icon,
  label,
  body,
}: OpenStartEmailThreadDialogButtonProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  return (
    <ActionButton
      icon={icon}
      label={t(label)}
      onClick={() =>
        openDialog({
          aggregateId: null,
          orderSummary: orderSummary,
          phase: null,
          body: body,
        })
      }
    />
  );
};
