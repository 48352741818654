import Order from '../../../../domain/automator/Order';
import { tokens } from '../../../../locales/translationTokens';
import SvgIcon from '@mui/material/SvgIcon';
import { CalendarMonthSharp } from '@mui/icons-material';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import Account from '../../../../domain/automator/Account';
import { DateChipAndText } from './DateChipAndText';

interface LatestHandoverDateTextProps {
  order: Order;
  account: Account;
}

export const LatestHandoverDateText = ({ order, account }: LatestHandoverDateTextProps) => {
  const openDialog = useOpenDialog(DialogId.CHANGE_ORDER_HANDOVER_DATE);

  return (
    <DateChipAndText
      chip={'V'}
      date={order.latestHandoverDate!}
      tooltip={tokens.automator.orders.latest_handover_date_tooltip}
      onClick={() => !account.settings.ignoreLatestHandoverDate && openDialog({ orders: [order] })}
      icon={
        <SvgIcon sx={{ fontSize: 20, color: 'cornflowerblue' }}>
          <CalendarMonthSharp />
        </SvgIcon>
      }
    />
  );
};
