import { TableCell, TableRow } from '@mui/material';
import Shipment from '../../../../domain/automator/Shipment';
import Typography from '@mui/material/Typography';
import ShipmentStatusChip from './ShipmentEventStatus';
import Stack from '@mui/material/Stack';
import { TransportEventCode } from '../../../../domain/automator/TransportEventCode';
import DaysUntilReturnText from './DaysUntilReturnText';
import ShipmentOverdueChip from './ShipmentOverdueChip';
import IsResentAfterReturnCheckbox from './IsResentAfterReturnCheckbox';
import LoadShipmentEventsReport from '../../../../domain/automator/LoadShipmentEventsReport';
import { ShipmentEventLoadingStatus } from '../../../../domain/automator/ShipmentEventLoadingStatus';
import ShipmentTransportInfo from './ShipmentTransporterInfo';
import { ShipmentTrackAndTraceTextField } from './ShipmentTrackAndTraceTextField';
import { OpenOrderSummaryActionIcon } from '../../orderSummaries/components/OpenOrderSummaryActionIcon';
import { HasOpenResolutionCaseIndicator } from '../../resolutions/components/HasOpenResolutionCaseIndicator';
import { TransporterCode } from '../../../../domain/automator/TransporterCode';
import { OpenStartShipmentEmailThreadDialogButton } from './OpenStartShipmentEmailThreadDialogButton';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import { ActionType } from '../../orders/components/copyButtons/ActionType';
import ShipmentCard from './ShipmentCard';
import HasReturnedToSenderIndicator from './HasReturnedToSenderIndicator';

interface ShipmentRowProps {
  shipment: Shipment;
  loadShipmentEventsReport: LoadShipmentEventsReport;
}

const ShipmentRow = ({ shipment, loadShipmentEventsReport }: ShipmentRowProps) => {
  const determineShipmentEventLoadingStatus = (
    shipment: Shipment,
    loadShipmentEventsReport: LoadShipmentEventsReport
  ) => {
    if (loadShipmentEventsReport.pendingBolShipmentIds.includes(shipment.bolShipmentId)) {
      return ShipmentEventLoadingStatus.PENDING;
    } else if (loadShipmentEventsReport.finishedBolShipmentIds.includes(shipment.bolShipmentId)) {
      return ShipmentEventLoadingStatus.FINISHED;
    } else if (loadShipmentEventsReport.failedBolShipmentIds.includes(shipment.bolShipmentId)) {
      return ShipmentEventLoadingStatus.FAILED;
    } else if (loadShipmentEventsReport.inProgressBolShipmentIds.includes(shipment.bolShipmentId)) {
      return ShipmentEventLoadingStatus.IN_PROGRESS;
    } else {
      return ShipmentEventLoadingStatus.NOT_APPLICABLE;
    }
  };

  const hasTrackAndTraceUrl = shipment.transporterInfos.some((t) => t.trackAndTraceUrl != null);

  return (
    <TableRow key={shipment.id}>
      <TableCell>
        <ShipmentCard shipment={shipment} />
      </TableCell>

      <TableCell>
        {!hasTrackAndTraceUrl && <ShipmentTrackAndTraceTextField shipmentId={shipment.id} />}
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={0.25}
        >
          {shipment.products.map((product) => {
            return (
              <TooltippedProductThumbnail
                key={product.id}
                thumbnailUrl={product.thumbnailUrl}
                productTitle={product.title}
                height={40}
              />
            );
          })}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          gap={0.5}
          direction="row"
          justifyContent={'flex-end'}
        >
          <OpenOrderSummaryActionIcon bolOrderId={shipment.bolOrderId} />

          {shipment.transporterInfos.map((transporterInfo) => (
            <Stack
              direction={'row'}
              alignItems="center"
              gap={0.5}
              key={transporterInfo.transporterCode}
            >
              {(transporterInfo.transporterCode == TransporterCode.TNT ||
                transporterInfo.transporterCode == TransporterCode.DHLFORYOU ||
                transporterInfo.transporterCode == TransporterCode.TNT_BRIEF) && (
                <OpenStartShipmentEmailThreadDialogButton
                  shipment={shipment}
                  body={null}
                  type={ActionType.ICON}
                />
              )}
            </Stack>
          ))}

          {shipment.resolutionCases.map((resolutionCase) => {
            return (
              <HasOpenResolutionCaseIndicator
                key={resolutionCase.id}
                reducedResolutionCase={resolutionCase}
              />
            );
          })}

          {shipment.hasReturnedToSender && <HasReturnedToSenderIndicator />}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          alignItems={'center'}
        >
          <ShipmentStatusChip
            shipmentEventsLoadingStatus={determineShipmentEventLoadingStatus(
              shipment,
              loadShipmentEventsReport
            )}
            shipment={shipment}
          />
          {shipment.latestEvent?.eventCode == TransportEventCode.RETURNED_TO_SENDER && (
            <IsResentAfterReturnCheckbox shipment={shipment} />
          )}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          gap={'0.5rem'}
          direction={'row'}
        >
          <Typography>{shipment.expectedDeliveryDate}</Typography>

          {shipment.isTrackAndTraceable && (
            <>
              {shipment.daysUntilReturn != null && (
                <DaysUntilReturnText daysUntilReturn={shipment.daysUntilReturn!} />
              )}
              {isOverDue(shipment) && <ShipmentOverdueChip />}
            </>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <ShipmentTransportInfo shipment={shipment} />
      </TableCell>
    </TableRow>
  );
};

export const isOverDue = (shipment: Shipment) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  return (
    new Date(shipment.expectedDeliveryDate) < currentDate &&
    shipment.latestEvent?.eventCode !== TransportEventCode.DELIVERED_AT_NEIGHBOURS &&
    shipment.latestEvent?.eventCode !== TransportEventCode.DELIVERED_AT_CUSTOMER &&
    shipment.latestEvent?.eventCode !== TransportEventCode.PICKED_UP_AT_PICK_UP_POINT
  );
};

export default ShipmentRow;
