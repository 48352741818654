import Stack from '@mui/material/Stack';
import { OrderSummaryOrderView } from './OrderSummaryOrderView';
import { OrderSummaryShipmentView } from './OrderSummaryShipmentView';
import { OrderSummaryReturnView } from './OrderSummaryReturnView';
import OrderNoteTypography from '../../orders/components/OrderNoteTypography';
import { OrderSummaryViewHeader } from './OrderSummaryViewHeader';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { OrderSummaryDossierView } from './OrderSummaryDossierView';
import { OpenStartEmailThreadDialogButton } from '../../returns/components/OpenStartEmailThreadDialogButton';
import { useFetchOrderSummary } from '../../../../api/automator/orderSummaries/useFetchOrderSummary';
import { OrderSummaryResendsView } from './OrderSummaryResendsView';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { OrderSummaryEmailTasksView } from './OrderSummaryEmailTasksView';
import OpenCreateResendDialogButton from '../../resolutions/components/OpenCreateResendDialogButton';

interface OrderSummaryViewProps {
  bolOrderId: string;
}

export const OrderSummaryView = ({ bolOrderId: initialBolOrderId }: OrderSummaryViewProps) => {
  const [bolOrderId, setBolOrderId] = useState(initialBolOrderId);

  const { data: orderSummary, isLoading } = useFetchOrderSummary(bolOrderId);

  const { t } = useTranslation();

  if (isLoading || !orderSummary) {
    return <>...loading</>;
  }

  return (
    <Stack
      direction="column"
      gap={5}
      paddingBottom={5}
    >
      {orderSummary.previousBolOrderIds.length > 0 && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
        >
          {orderSummary.previousBolOrderIds.map((previousBolOrderId, index) => {
            return (
              <Stack
                key={index}
                direction="row"
                gap={1}
              >
                <Chip
                  color="info"
                  label={t(tokens.automator.orders.repeat_customer)}
                />
                <Typography> {'>>'} </Typography>
                <Typography
                  onClick={() => setBolOrderId(previousBolOrderId)}
                  style={{ cursor: 'pointer' }}
                >
                  {previousBolOrderId}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      )}

      <OrderSummaryOrderView order={orderSummary.order!} />

      <OrderSummaryShipmentView shipment={orderSummary.shipment} />

      <OrderSummaryReturnView returns={orderSummary.returns} />

      <OrderSummaryResendsView resends={orderSummary.resends} />

      <OrderSummaryEmailTasksView messageThreads={orderSummary.messageThreads} />

      <OrderSummaryDossierView resolutionDossier={orderSummary.resolutionDossier} />

      <Stack
        direction="row"
        gap={2}
        alignItems="center"
      >
        <OrderSummaryViewHeader label={t(tokens.automator.order_summary.note)} />
        <OrderNoteTypography orderId={orderSummary.order!.id} />
      </Stack>

      <Stack
        direction="row"
        gap={2}
        alignItems="center"
      >
        <OrderSummaryViewHeader label="" />
        <OpenStartEmailThreadDialogButton
          label={tokens.automator.resolutions.dialogs.send_email.send_email}
          orderSummary={orderSummary}
          body={null}
          icon={null}
        />

        <OpenCreateResendDialogButton orderId={orderSummary.order!.id} />
      </Stack>
    </Stack>
  );
};
