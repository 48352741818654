import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import EmailRuleForm from '../components/EmailRuleForm';
import { DistributionParty } from '../../../../domain/automator/DistributionParty';
import { CountryCode } from '../../../../domain/automator/CountryCode';
import { RecipientType } from '../../../../domain/automator/RecipientType';
import EmailRuleTrigger from '../../../../domain/automator/EmailRuleTrigger';
import { toast } from 'react-hot-toast';
import { useCreateEmailRule } from '../../../../api/automator/email-rules/useCreateEmailRule';

export const CreateEmailRuleDialog = () => {
  const { mutate: createEmailRule, isLoading } = useCreateEmailRule();

  const id = DialogId.CREATE_EMAIL_RULE;
  const { isOpen } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  const onCreate = (
    productIds: number[],
    messageTemplateId: number,
    distributionParty: DistributionParty | null,
    countryCode: CountryCode | null,
    recipientType: RecipientType,
    trigger: EmailRuleTrigger,
    delayHours: number | null,
    executeAtTime: string | null,
    isStartWithCase: boolean
  ) => {
    createEmailRule(
      {
        product_ids: productIds,
        email_template_id: messageTemplateId,
        distribution_party: distributionParty,
        country_code: countryCode,
        recipient_type: recipientType,
        trigger: trigger,
        execute_at_time: executeAtTime,
        execute_in_hours: delayHours,
        is_start_with_resolution_case: isStartWithCase,
      },
      {
        onSuccess: async () => {
          toast.success(tokens.automator.email_rules.form.email_rule_created);
          closeDialog();
        },
      }
    );
  };

  return (
    <>
      {isOpen && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.email_rules.form.create_email_rule)}
        >
          <EmailRuleForm
            emailRule={null}
            onSave={onCreate}
            isLoading={isLoading}
          />
        </ApplicationDialog>
      )}
    </>
  );
};
