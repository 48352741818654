import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { tss } from 'tss-react/mui-compat';

interface SlimTableCellProps extends TableCellProps {
  hasBorderBottom?: boolean;
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
  isSticky?: boolean;
  hasBorderTop?: boolean;
}

export const SlimTableCell = ({
  hasBorderBottom = true,
  hasBorderTop = true,
  children,
  style,
  isSticky,
  ...props
}: SlimTableCellProps) => {
  const { cx, classes } = useStyles();

  return (
    <TableCell
      {...props}
      style={{
        position: isSticky ? 'sticky' : 'relative',
        left: isSticky ? 0 : undefined,
        zIndex: isSticky ? 2 : 1,
        backgroundColor: 'white',
        overflow: 'hidden',
        borderBottom: hasBorderBottom ? undefined : 'none',
        borderTop: hasBorderTop ? undefined : 'none',
        ...style,
      }}
      className={cx(classes.slimTableCell)}
    >
      {children}
    </TableCell>
  );
};

const useStyles = tss.create(() => ({
  slimTableCell: {
    paddingTop: 4,
    paddingBottom: 4,
  },
}));
