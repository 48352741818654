import { EMAIL_RULES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import EmailRules from '../../../domain/automator/EmailRules';
import { EmailRulesQueryKey } from '../../queryKeys/EmailRulesQueryKey';

export const useFetchEmailRules = () => {
  const url = useAutomatorApiAccountUrl(EMAIL_RULES_PATH);

  return useFetchData<EmailRules>(url, EmailRulesQueryKey());
};
