import { LABEL_TYPE_RULES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { useFetchData } from '../../useFetchData';
import LabelTypeRule from '../../../domain/automator/LabelTypeRule';

export const useFetchLabelTypeRules = () => {
  const url = useAutomatorApiAccountUrl(LABEL_TYPE_RULES_PATH);
  return useFetchData<LabelTypeRule[]>(url, EndpointQueryKey.LABEL_TYPE_RULES);
};
