import { ORDER_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { CancellationReason } from '../../../domain/automator/CancellationReason';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Orders from '../../../domain/automator/Orders';
import { useOrderQueryKeysUpdater } from './useOrderQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';
import { PickSessionPickOrdersQueryKey } from '../../queryKeys/PickSessionPickOrdersQueryKey';

export const useCancelOrderItem = (orderItemId: number) => {
  const url = useAutomatorApiAccountUrl(ORDER_ITEMS_PATH + '/' + orderItemId + `/cancel`);

  const updater = useOrderQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'orders');

  const onSuccess = (orders: Orders) => {
    updateData(orders);
  };

  return useAuthorizedPost<Orders, CancelOrderItemData>(
    url,
    [PickSessionPickOrdersQueryKey()],
    undefined,
    onSuccess
  );
};

export interface CancelOrderItemData {
  cancellation_reason: CancellationReason;
}
