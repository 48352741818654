import { MESSAGE_TEMPLATES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ThreadPhase } from '../../../domain/automator/ThreadPhase';
import { ReturnRecoverability } from '../../../domain/automator/ReturnRecoverability';
import FileUpload from '../../../pages/automator/shared/FileUpload';
import { MessageTemplatesQueryKey } from '../../queryKeys/MessageTemplatesQueryKey';
import { MessageTemplateType } from '../../../domain/automator/MessageTemplateType';
import MessageTemplate from '../../../domain/automator/MessageTemplate';

export const useCreateMessageTemplate = () => {
  const url = useAutomatorApiAccountUrl(MESSAGE_TEMPLATES_PATH);

  return useAuthorizedPost<MessageTemplate, CreateMessageTemplateData>(
    url,
    [
      MessageTemplatesQueryKey(MessageTemplateType.MANUAL, ThreadPhase.ORDER, null),
      MessageTemplatesQueryKey(MessageTemplateType.MANUAL, ThreadPhase.SHIPMENT, null),
      MessageTemplatesQueryKey(MessageTemplateType.MANUAL, ThreadPhase.RETURN, null),
      MessageTemplatesQueryKey(
        MessageTemplateType.MANUAL,
        ThreadPhase.RETURN,
        ReturnRecoverability.NOT_RECOVERABLE
      ),
      MessageTemplatesQueryKey(
        MessageTemplateType.MANUAL,
        ThreadPhase.RETURN,
        ReturnRecoverability.RECOVERABLE
      ),
      MessageTemplatesQueryKey(
        MessageTemplateType.MANUAL,
        ThreadPhase.RETURN,
        ReturnRecoverability.UNCLEAR
      ),
      MessageTemplatesQueryKey(MessageTemplateType.RULE, ThreadPhase.ORDER, null),
      MessageTemplatesQueryKey(MessageTemplateType.RULE, ThreadPhase.SHIPMENT, null),
      MessageTemplatesQueryKey(MessageTemplateType.RULE, ThreadPhase.RETURN, null),
      MessageTemplatesQueryKey(
        MessageTemplateType.RULE,
        ThreadPhase.RETURN,
        ReturnRecoverability.NOT_RECOVERABLE
      ),
      MessageTemplatesQueryKey(
        MessageTemplateType.RULE,
        ThreadPhase.RETURN,
        ReturnRecoverability.RECOVERABLE
      ),
      MessageTemplatesQueryKey(
        MessageTemplateType.RULE,
        ThreadPhase.RETURN,
        ReturnRecoverability.UNCLEAR
      ),
    ],
    undefined
  );
};

export interface CreateMessageTemplateData {
  name: string;
  type: MessageTemplateType;
  subject: string;
  body: string;
  phase: ThreadPhase;
  return_recoverability: ReturnRecoverability | null;
  files: FileUpload[];
}
