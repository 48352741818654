import { useMemo } from 'react';
import { useAuth } from '../store/auth/useAuth';
import { useAccountIdFromUrl } from '../api/useAccountIdFromUrl';

const paths = {
  index: '/',
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    request_new_password: '/auth/request-new-password',
    new_password: '/auth/new-password',
    not_authorized: '/401',
    not_found: '/404',
    server_error: '/500',
  },
  automator: {
    vvb_orders: '/automator/accounts/:accountId/vvb-orders',
    non_vvb_orders: '/automator/accounts/:accountId/non-vvb-orders',
    pick_sessions: '/automator/accounts/:accountId/pick-sessions',
    label_type_rules: '/automator/accounts/:accountId/label-type-rules',
    retailers: '/automator/accounts/:accountId/retailers',
    shipments: '/automator/accounts/:accountId/shipments',
    settings: '/automator/accounts/:accountId/settings',
    returns: '/automator/accounts/:accountId/returns',
    return_scans: '/automator/accounts/:accountId/return-scans',
    pricing: '/automator/accounts/:accountId/pricing',
    email_threads: '/automator/accounts/:accountId/email-threads',
    resolution_dossiers: '/automator/accounts/:accountId/resolution-dossiers',
    resends: '/automator/accounts/:accountId/resends',
    reports: '/automator/accounts/:accountId/reports',
    email_rules: '/automator/accounts/:accountId/email-rules',
  },
  pd: {
    products: '/pd/accounts/:accountId/products',
  },
};

type Paths = typeof paths;

const replacePlaceholder = (value: string, accountId: number): string => {
  return value.replace(/:accountId/g, accountId.toString());
};

const recursiveReplace = (obj: any, accountId: number): any => {
  const result: any = {};
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      result[key] = recursiveReplace(obj[key], accountId);
    } else {
      result[key] = replacePlaceholder(obj[key], accountId);
    }
  }
  return result;
};

export const generatePaths = (accountId: number | null) => {
  if (accountId == null) {
    return paths;
  } else {
    return recursiveReplace(paths, accountId);
  }
};

export const usePaths = (): Paths => {
  const accountId = useAuth().accountId;
  const urlAccountId = useAccountIdFromUrl();
  return useMemo(() => {
    return generatePaths(urlAccountId ? parseInt(urlAccountId) : accountId);
  }, [accountId, urlAccountId]);
};
