import { RETURN_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/Returns';
import { ReturnItemQueryKey } from '../../queryKeys/ReturnItemQueryKey';
import { useQueryClient } from 'react-query';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { OrderSummaryQueryKey } from '../../queryKeys/OrderSummaryQueryKey';
import { ReturnScansQueryKey } from '../../queryKeys/ReturnScansQueryKey';

export const useReturnItemOnHold = (returnItemId: number) => {
  const url = useAutomatorApiAccountUrl(RETURN_ITEMS_PATH + '/' + returnItemId + `/on-hold`);

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const queryClient = useQueryClient();

  const onSuccess = (returns: Returns) => {
    updateData(returns);
    // Invalidate queries with a prefix regardless of postfix
    queryClient
      .invalidateQueries({
        predicate: (query) => {
          return (query.queryKey[0] as string).startsWith(EndpointQueryKey.ORDER_SUMMARY);
        },
      })
      .then((_) => {
        console.log('Invalidated order summary');
      });
  };

  return useAuthorizedPost<Returns>(
    url,
    [ReturnItemQueryKey(returnItemId), OrderSummaryQueryKey(undefined), ReturnScansQueryKey()],
    undefined,
    onSuccess
  );
};
