import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { PickSessionListTable } from './components/PickSessionListTable';
import { PickSessionsTutorial } from '../../../components/tutorials/PickSessionsTutorial';

const PickSessionsPage = () => {
  return (
    <AccountPage
      tutorialAlert={<PickSessionsTutorial />}
      title={tokens.automator.pick_sessions.pick_sessions}
    >
      <PickSessionListTable />
    </AccountPage>
  );
};

export default PickSessionsPage;
