import {
  SeverityPill,
  SeverityPillColor,
} from '../../../../../devias/src/components/severity-pill';
import { PickSessionPhase } from '../../../../domain/automator/PickSessionPhase';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import PickSessionProgress from '../../../../domain/automator/PickSessionProgress';
import { PickSessionStatus } from '../../../../domain/automator/PickSessionStatus';

interface PickSessionProgressPillProps {
  progress: PickSessionProgress;
}

const PickSessionProgressPill = ({ progress }: PickSessionProgressPillProps) => {
  const { t } = useTranslation();

  const getColor = (): SeverityPillColor => {
    if (progress.status == PickSessionStatus.FINISHED && progress.failures.length == 0) {
      return 'success';
    }

    if (progress.status == PickSessionStatus.FINISHED && progress.failures.length > 0) {
      return 'warning';
    }

    switch (progress.phase) {
      case PickSessionPhase.PICK_LIST:
        return 'secondary';
      case PickSessionPhase.CLEAN_LABELS:
        return 'primary';
      case PickSessionPhase.ENRICHED_LABELS:
        return 'primary';
      case PickSessionPhase.LABELS:
        return 'primary';
      case PickSessionPhase.SHIPMENTS:
        return 'info';
    }

    return 'info';
  };

  const getToken = (): string => {
    if (progress.status == PickSessionStatus.FINISHED && progress.failures.length == 0) {
      return tokens.automator.pick_sessions.phase.shipped;
    }

    if (progress.status == PickSessionStatus.FINISHED && progress.failures.length > 0) {
      return tokens.automator.pick_sessions.phase.failed;
    }

    switch (progress.phase) {
      case PickSessionPhase.SHIPMENTS:
        return tokens.automator.pick_sessions.phase.shipping;
      case PickSessionPhase.PICK_LIST:
        return tokens.automator.pick_sessions.phase.creating_labels;
      case PickSessionPhase.ENRICHED_LABELS:
        return tokens.automator.pick_sessions.phase.creating_labels;
      case PickSessionPhase.CLEAN_LABELS:
        return tokens.automator.pick_sessions.phase.creating_labels;
      case PickSessionPhase.LABELS:
        return tokens.automator.pick_sessions.phase.creating_labels;
    }

    return '';
  };

  return <SeverityPill color={getColor()}>{t(getToken())}</SeverityPill>;
};

export default PickSessionProgressPill;
