import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { SendMessageThreadReplyForm } from '../components/SendMessageThreadReplyForm';
import MessageTemplate from '../../../../domain/automator/MessageTemplate';
import MessageThread from '../../../../domain/automator/MessageThread';

export const SendEmailThreadReplyDialog = () => {
  const id = DialogId.SEND_MESSAGE_THREAD_REPLY;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          maxWidth="md"
          dialogId={id}
          title={t(tokens.automator.resolutions.dialogs.send_email_thread_reply.dialog_title)}
        >
          <SendMessageThreadReplyForm
            messageThread={data.messageThread}
            messageTemplate={data.messageTemplate}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface SendEmailThreadReplyDialogData {
  messageThread: MessageThread;
  messageTemplate: MessageTemplate | null;
}
