import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ResolutionCase from '../../../../domain/automator/ResolutionCase';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import ShipmentStatusChip from '../../shipments/components/ShipmentEventStatus';
import { ShipmentEventLoadingStatus } from '../../../../domain/automator/ShipmentEventLoadingStatus';
import { getDateStringFromDate } from '../../../../helpers/getDateStringFromDate';
import ShipmentTransporterInfo from '../../shipments/components/ShipmentTransporterInfo';

interface ResolutionCaseInfoCardProps {
  resolutionCase: ResolutionCase;
}

export const ResolutionCaseInfoCard = ({ resolutionCase }: ResolutionCaseInfoCardProps) => {
  const hasBolCaseNumber = resolutionCase.bolCaseNumber !== null;

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack
          direction="column"
          gap={1}
        >
          {hasBolCaseNumber && (
            <Stack
              alignItems="center"
              alignContent="center"
              direction="row"
              justifyContent="space-between"
              gap={1}
            >
              <Typography variant="body2">Case</Typography>
              <Typography variant="body2">{resolutionCase.bolCaseNumber}</Typography>
            </Stack>
          )}

          <Stack
            alignItems="center"
            alignContent="center"
            direction="row"
            justifyContent="space-between"
            gap={1}
          >
            <Typography variant="body2">Type</Typography>
            <DistributionPartyChip
              distributionParty={resolutionCase.messageThread.order.distributionParty}
            />
          </Stack>

          {resolutionCase.messageThread.shipment && (
            <Stack
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
              direction="row"
              gap={1}
            >
              <Typography variant="body2">Status</Typography>
              <ShipmentStatusChip
                shipmentEventsLoadingStatus={ShipmentEventLoadingStatus.NOT_APPLICABLE}
                shipment={resolutionCase.messageThread.shipment}
              />
            </Stack>
          )}

          {resolutionCase.messageThread.shipment && (
            <Stack
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
              direction="row"
              gap={1}
            >
              <Typography variant="body2">Leverdatum</Typography>
              <Typography variant="body2">
                {getDateStringFromDate(
                  new Date(resolutionCase.messageThread.shipment.expectedDeliveryDate),
                  false
                )}
              </Typography>
            </Stack>
          )}

          {resolutionCase.messageThread.shipment && (
            <Stack
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
              direction="row"
              gap={1}
            >
              <Typography variant="body2">Tracking</Typography>

              <ShipmentTransporterInfo shipment={resolutionCase.messageThread.shipment!} />
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
