import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import EmailRuleTrigger from '../../../../domain/automator/EmailRuleTrigger';

interface EmailRuleTriggerChipProps {
  trigger: EmailRuleTrigger;
}

const EmailRuleTriggerChip = ({ trigger }: EmailRuleTriggerChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (trigger: EmailRuleTrigger) => {
    switch (trigger) {
      case EmailRuleTrigger.NEW_ORDER:
        return tokens.automator.email_rules.triggers.new_order;
      case EmailRuleTrigger.ORDER_IS_PLACED_DOUBLE:
        return tokens.automator.email_rules.triggers.order_is_placed_double;
      case EmailRuleTrigger.CUSTOMER_IS_RECURRING:
        return tokens.automator.email_rules.triggers.customer_is_recurring;
      case EmailRuleTrigger.SHIPMENT_TRACK_AND_TRACE_UPLOADED:
        return tokens.automator.email_rules.triggers.shipment_track_and_trace_uploaded;
      case EmailRuleTrigger.SHIPMENT_SHIPPED:
        return tokens.automator.email_rules.triggers.shipment_send;
      case EmailRuleTrigger.SHIPMENT_DELIVERED:
        return tokens.automator.email_rules.triggers.shipment_delivered;
      case EmailRuleTrigger.SHIPMENT_DELIVERED_AT_PICKUP_POINT:
        return tokens.automator.email_rules.triggers.shipment_at_pickup_point;
      case EmailRuleTrigger.SHIPMENT_RETURNED_TO_SENDER:
        return tokens.automator.email_rules.triggers.shipment_returned_to_sender;
      case EmailRuleTrigger.RETURN_REGISTERED:
        return tokens.automator.email_rules.triggers.return_registered;
      case EmailRuleTrigger.RETURN_HANDLED:
        return tokens.automator.email_rules.triggers.return_handled;
      case EmailRuleTrigger.RECOVERABLE_RETURN_ITEM_REGISTERED:
        return tokens.automator.email_rules.triggers.recoverable_return_item_registered;
      case EmailRuleTrigger.RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED:
        return tokens.automator.email_rules.triggers.recoverability_unclear_return_item_registered;
      case EmailRuleTrigger.NOT_RECOVERABLE_RETURN_ITEM_REGISTERED:
        return tokens.automator.email_rules.triggers.not_recoverable_return_item_registered;
      case EmailRuleTrigger.EXPECTED_DELIVERY_DATE_REACHED:
        return tokens.automator.email_rules.triggers.expected_delivery_date_reached;

      default:
        return trigger + '.';
    }
  };

  const determineColor = (trigger: EmailRuleTrigger) => {
    switch (trigger) {
      case EmailRuleTrigger.NEW_ORDER:
      case EmailRuleTrigger.CUSTOMER_IS_RECURRING:
      case EmailRuleTrigger.ORDER_IS_PLACED_DOUBLE:
      case EmailRuleTrigger.EXPECTED_DELIVERY_DATE_REACHED:
        return 'primary';
      case EmailRuleTrigger.RETURN_HANDLED:
      case EmailRuleTrigger.RETURN_REGISTERED:
      case EmailRuleTrigger.RECOVERABLE_RETURN_ITEM_REGISTERED:
      case EmailRuleTrigger.RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED:
      case EmailRuleTrigger.NOT_RECOVERABLE_RETURN_ITEM_REGISTERED:
        return 'info';
      case EmailRuleTrigger.SHIPMENT_SHIPPED:
      case EmailRuleTrigger.SHIPMENT_TRACK_AND_TRACE_UPLOADED:
      case EmailRuleTrigger.SHIPMENT_DELIVERED:
      case EmailRuleTrigger.SHIPMENT_DELIVERED_AT_PICKUP_POINT:
      case EmailRuleTrigger.SHIPMENT_RETURNED_TO_SENDER:
        return 'secondary';
      default:
        return 'error';
    }
  };

  return (
    <Chip
      style={{ borderRadius: 4 }}
      size="small"
      label={t(determineLabel(trigger))}
      variant="filled"
      color={determineColor(trigger)}
    />
  );
};

export default EmailRuleTriggerChip;
