import ReturnItem from '../../../../domain/automator/ReturnItem';
import ProcessingResultChip from './ProcessingResultChip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import StillAcceptReturnProcessingResultButton from './StillAcceptReturnProcessingResultButton';

interface MinimalReturnProcessingResultStackProps {
  returnItem: ReturnItem;
}

export const MinimalReturnProcessingResultStack = ({
  returnItem,
}: MinimalReturnProcessingResultStackProps) => {
  const returnProcessingResults = returnItem.returnProcessingResults;

  return (
    <Stack
      direction="column"
      gap={1}
    >
      {returnProcessingResults.map((returnProcessingResult, index) => {
        return (
          <Stack
            direction="row"
            gap={1.5}
            key={index}
            alignItems="center"
          >
            <Typography variant="subtitle1">{returnProcessingResult.quantity}x</Typography>
            <ProcessingResultChip processingResult={returnProcessingResult.processingResult} />
            <StillAcceptReturnProcessingResultButton
              returnProcessingResult={returnProcessingResult}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};
