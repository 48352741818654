import { tokens } from '../../../../locales/translationTokens';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface EmailVariableSelectionProps {
  onSelectVariable: (variable: string) => void;
}

export const EmailVariableSelection = ({ onSelectVariable }: EmailVariableSelectionProps) => {
  const { t } = useTranslation();

  const variables = [
    '[[CUSTOMER_NAME]]',
    '[[TRANSPORTER_NAME]]',
    '[[STORE_NAME]]',
    '[[BOL_ORDER_ID]]',
    '[[BOL_RETAILER_ID]]',
    '[[SHIPMENT_TRACKING_LINK]]',
    '[[ORDER_EXPECTED_DELIVERY_DATE]]',
  ];

  return (
    <Stack gap={1}>
      <Typography
        paddingBottom={1}
        variant="h6"
      >
        {t(tokens.automator.resolutions.email_variables.email_variables)}
      </Typography>
      {variables.map((variable) => (
        <Typography
          style={{ cursor: 'pointer' }}
          key={variable}
          onClick={() => onSelectVariable(variable)}
        >
          {variable}
        </Typography>
      ))}
    </Stack>
  );
};
