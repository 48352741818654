import { Tooltip } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';

const RepeatCustomerChip = () => {
  const { t } = useTranslation();

  return (
    <Tooltip
      enterDelay={2000}
      enterNextDelay={2000}
      title={t(tokens.automator.orders.repeat_customer_tooltip)}
      placement={'right'}
    >
      <Chip
        size="small"
        label={t(tokens.automator.orders.repeat_customer)}
        color="warning"
        variant="filled"
      />
    </Tooltip>
  );
};

export default RepeatCustomerChip;
