import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useFetchOrderNote } from '../../../../api/automator/orders/useFetchOrderNote';
import { ActionIcon } from './ActionIcon';
import CommentOutlined from '@mui/icons-material/CommentOutlined';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { colors } from '@mui/material';
import { Box } from '@mui/system';

interface OpenUpdateOrderNoteDialogButtonProps {
  orderId: number;
}

const OpenUpdateOrderNoteDialogButton = ({ orderId }: OpenUpdateOrderNoteDialogButtonProps) => {
  const { data: orderNote } = useFetchOrderNote(orderId);
  const { t } = useTranslation();
  const openDialog = useOpenDialog(DialogId.UPDATE_ORDER_NOTE_DIALOG);

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <ActionIcon
        icon={<CommentOutlined style={{ color: colors.blue[400] }} />}
        onClick={() => openDialog({ orderId: orderId })}
        tooltip={t(tokens.automator.orders.dialogs.update_order_note.note)}
      />
      {orderNote?.note !== '' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: 14,
            height: 14,
            bgcolor: colors.green[400],
            borderRadius: '50%',
            border: '1px solid white',
          }}
        />
      )}
    </Box>
  );
};

export default OpenUpdateOrderNoteDialogButton;
