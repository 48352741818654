import { useTranslation } from 'react-i18next';
import { TableTabs, TabOption } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';
import { ReturnItemFilter } from '../../../../domain/automator/ReturnItemFilter';
import ReturnsReport from '../../../../domain/automator/ReturnReport';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface ReturnItemTabsProps {
  report?: ReturnsReport;
  currentTab: ReturnItemFilter;
  setCurrentTab: (tab: ReturnItemFilter) => void;
}

const ReturnItemTabs = ({ currentTab, setCurrentTab, report }: ReturnItemTabsProps) => {
  const { t } = useTranslation();

  const tabOptions: TabOption<ReturnItemFilter>[] = [];
  tabOptions.push(
    ...[
      {
        label: t(tokens.automator.returns.status.overview),
        value: ReturnItemFilter.OVERVIEW,
        amount: report?.totalProcessingNeeded,
      },
      {
        label: t(tokens.automator.returns.status.on_hold),
        value: ReturnItemFilter.ON_HOLD,
        amount: report?.totalOnHold,
      },
      {
        label: t(tokens.automator.returns.status.awaiting_repair),
        value: ReturnItemFilter.REPAIR,
        amount: report?.totalAwaitingRepair,
      },
      {
        label: t(tokens.automator.returns.status.awaiting_exchange),
        value: ReturnItemFilter.EXCHANGE,
        amount: report?.totalAwaitingExchange,
      },
      {
        label: t(tokens.automator.returns.status.handled),
        value: ReturnItemFilter.HANDLED,
      },
    ]
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <TableTabs
        value={currentTab}
        onChange={(tab: ReturnItemFilter) => {
          setCurrentTab(tab);
        }}
        tabOptions={tabOptions}
      />

      {report && (
        <Typography
          paddingRight={5}
          variant="body2"
        >
          {t(tokens.automator.returns.total_unhandled)} : {report.totalProcessingNeeded}
        </Typography>
      )}
    </Stack>
  );
};

export default ReturnItemTabs;
