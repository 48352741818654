import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';
import { CountryCode } from '../../domain/automator/CountryCode';

export const PerformanceEventsQueryKey = (
  offerId: number,
  startDate: Date,
  endDate: Date,
  countryCode: CountryCode | null
) => {
  return (
    EndpointQueryKey.TIMELINE_EVENTS +
    '_' +
    offerId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate) +
    (countryCode ? `_${countryCode}` : '')
  );
};
