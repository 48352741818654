import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import Shipment from '../../../../domain/automator/Shipment';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import { ShipmentRecipient } from '../../shipments/components/ShipmentReceipient';
import ShipmentStatusChip from '../../shipments/components/ShipmentEventStatus';
import { ShipmentEventLoadingStatus } from '../../../../domain/automator/ShipmentEventLoadingStatus';

interface EmailThreadShipmentSelectionProps {
  shipment: Shipment;
  setAggregateId: (aggregateId: number) => void;
  selectedAggregateId: number | null;
}

export const EmailThreadShipmentSelection = ({
  shipment,
  setAggregateId,
  selectedAggregateId,
}: EmailThreadShipmentSelectionProps) => {
  return (
    <Stack
      direction="row"
      gap={2}
      alignItems={'center'}
    >
      <Radio
        checked={selectedAggregateId === shipment.id}
        onChange={() => setAggregateId(shipment.id)}
      />

      <Stack
        direction={'row'}
        gap={1}
        alignItems={'center'}
      >
        <ShipmentStatusChip
          shipmentEventsLoadingStatus={ShipmentEventLoadingStatus.NOT_APPLICABLE}
          shipment={shipment}
        />

        <DistributionPartyChip distributionParty={shipment.distributionParty} />

        <ShipmentRecipient
          shipmentDetails={shipment.shipmentDetails}
          pickupPoint={shipment.pickupPoint}
        />
      </Stack>
    </Stack>
  );
};
