import { QueryKeysUpdater } from '../../useUpdateData';
import { ShipmentsQueryKey, ShipmentsRequestType } from '../../queryKeys/ShipmentsQueryKey';
import Shipment from '../../../domain/automator/Shipment';
import { TransportEventCode } from '../../../domain/automator/TransportEventCode';
import { isOverDue } from '../../../pages/automator/shipments/components/ShipmentRow';
import { getLastDays } from '../../../helpers/getLastDays';

export const useShipmentQueryKeysUpdater = (): QueryKeysUpdater<Shipment> => {
  const last14DaysUpdaters = Object.fromEntries(
    getLastDays(14).map((date) => {
      return [ShipmentsQueryKey(ShipmentsRequestType.ALL, date), allShipmentUpdater(date)];
    })
  );

  const baseUpdaters = {
    [ShipmentsQueryKey(ShipmentsRequestType.RETURNED_TO_SENDER, null)]:
      returnedToSenderShipmentUpdater(),
    [ShipmentsQueryKey(ShipmentsRequestType.OVERDUE, null)]: overdueShipmentUpdater(),
    [ShipmentsQueryKey(ShipmentsRequestType.AT_PICK_UP_POINT, null)]:
      atPickUpPointShipmentUpdater(),
  };

  return {
    ...baseUpdaters,
    ...last14DaysUpdaters,
  };
};

const returnedToSenderShipmentUpdater = () => {
  return {
    addCriteria: (data: Shipment) => {
      return data.latestEvent?.eventCode === TransportEventCode.RETURNED_TO_SENDER;
    },
    //sort: sortShipments,
  };
};

const overdueShipmentUpdater = () => {
  return {
    addCriteria: (data: Shipment) => {
      return isOverDue(data);
    },
    //sort: sortShipments,
  };
};

const atPickUpPointShipmentUpdater = () => {
  return {
    addCriteria: (data: Shipment) => {
      return data.latestEvent?.eventCode === TransportEventCode.AT_PICK_UP_POINT;
    },
    //sort: sortShipments,
  };
};

const allShipmentUpdater = (date: string) => {
  return {
    addCriteria: (data: Shipment) => {
      return data.shipmentDate === date;
    },
    //sort: sortShipments,
  };
};
//
// const sortShipments = (a: Shipment, b: Shipment) => {
//   function compareTransportEventCode(code?: TransportEventCode | null): number {
//     switch (code) {
//       case TransportEventCode.RETURNED_TO_SENDER:
//         return 0;
//       case TransportEventCode.AT_PICK_UP_POINT:
//         return 1;
//       case TransportEventCode.IN_TRANSIT:
//       case TransportEventCode.INBOUND_COLLECT:
//       case TransportEventCode.AT_TRANSPORTER:
//         return 2;
//       case TransportEventCode.PRE_ANNOUNCED:
//         return 3;
//       case TransportEventCode.DELIVERED_AT_NEIGHBOURS:
//       case TransportEventCode.DELIVERED_AT_CUSTOMER:
//       case TransportEventCode.PICKED_UP_AT_PICK_UP_POINT:
//         return 4;
//       default:
//         return 5;
//     }
//   }
//
//   return (
//     compareTransportEventCode(a.latestEvent?.eventCode) -
//     compareTransportEventCode(b.latestEvent?.eventCode)
//   );
// };
