import { ActionIcon } from './ActionIcon';
import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { tokens } from '../../../../locales/translationTokens';
import { colors } from '@mui/material';
import PriceChangeIcon from '@mui/icons-material/PriceChange';

interface OpenUpdateOfferDialogButtonProps {
  productId: number;
}

export const OpenUpdateOfferDialogButton = ({ productId }: OpenUpdateOfferDialogButtonProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.UPDATE_OFFERS);

  const icon = <PriceChangeIcon style={{ color: colors.blue[400] }} />;
  const label = t(tokens.automator.orders.dialogs.update_offer.update_offers);

  const onClick = () => {
    openDialog({
      productId: productId,
    });
  };

  return (
    <ActionIcon
      icon={icon}
      tooltip={t(label)}
      onClick={onClick}
    />
  );
};
