export const enum EndpointQueryKey {
  USER = 'user',
  ACCOUNT = 'account',
  RETAILERS = 'retailers',
  ADMIN_ACCOUNT = 'admin_account',
  LABEL_TYPE_RULES = 'label_type_rules',
  SHIPMENTS = 'shipments',
  SHIPMENTS_REPORT = 'shipments_reports',
  ORDERS = 'orders',
  ORDER_SUMMARY = 'order_summary',
  PICK_SESSIONS = 'pick_sessions',
  MESSAGE_TEMPLATE = 'message_template',
  EMAIL_RULES = 'email_rules',
  EMAIL_THREAD = 'email_thread',
  THREAD_MESSAGE = 'email_message',
  RESOLUTION_DOSSIER = 'resolution_dossier',
  RESOLUTION_CASE = 'resolution_case',
  RESEND = 'resend',
  RETURNS = 'returns',
  OFFERS = 'offer',

  RETURN_ITEM = 'return_item',
  RETURN_SCANS = 'return_scans',
  RETURN_SCAN_DOCUMENTS = 'return_scan_documents',

  CUSTOMER_ORDER = 'customer_order',
  ORDER_NOTE = 'order_note',

  STORE = 'store',
  OFFER_PERFORMANCES = 'offer_performances',
  PRODUCT_RANKS_SEARCH_TERMS = 'product_ranks_search_terms',
  PRODUCT_RANKS_CATEGORIES = 'product_ranks_categories',
  PRODUCT_RANKS = 'product_ranks',
  SEARCH_TERM_VOLUMES = 'search_term_volumes',
  ASSORTMENT_IMPRESSIONS = 'assortment_impressions',
  ASSORTMENT_VISITS = 'assortment_visits',
  CAMPAIGNS = 'campaigns',
  PICK_SESSION_PICK_ORDERS = 'pick_session_pick_orders',
  AD_GROUP_SEARCH_TERM_PERFORMANCE_SEARCH_TERMS = 'ad_group_search_term_performance_search_terms',
  AD_GROUP_SEARCH_TERM_PERFORMANCES = 'ad_group_search_term_performances',
  AD_GROUP_PERFORMANCES = 'ad_group_performances',
  AD_PERFORMANCES = 'ad_performances',
  PRODUCTS = 'products',
  SHARE_OF_VOICE_AVERAGES = 'share_of_voices_search_terms',
  TIMELINE_EVENTS = 'timeline_events',
  TIMELINE_NOTES = 'timeline_notes',
  MANUAL_SEASONAL_EVENTS = 'manual_seasonal_events',

  PRODUCT_PURCHASE_COSTS = 'product_purchase_costs',
  PRODUCT_REORDER_INTERVAL = 'product_reorder_interval',
  OFFER_SHIPPING_COSTS = 'offer_shipping_costs',
  PRODUCT_PURCHASE_VOLUME = 'product_purchase_volume',
  SHARE_OF_VOICE_TOTALS = 'share_of_voices_totals',
  SHARE_OF_VOICES = 'share_of_voices',

  PRODUCT_PERFORMANCES = 'product_performances',

  PRODUCT_POSITIONS = 'product_positions',

  CONTESTED_CATEGORIES = 'contested_categories',
}
