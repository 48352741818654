import Stack from '@mui/material/Stack';
import { ResolutionCaseStatus } from '../../../../domain/automator/ResolutionCaseStatus';
import { HandleResolutionCaseButton } from './HandleResolutionCaseButton';
import { AwaitingResponseResolutionCaseButton } from './AwaitingResponseResolutionCaseButton';

interface GeneralResolutionCaseActionsProps {
  resolutionCaseId: number;
  status: ResolutionCaseStatus;
}

export const GeneralResolutionCaseActions = ({
  resolutionCaseId,
  status,
}: GeneralResolutionCaseActionsProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
    >
      {status != ResolutionCaseStatus.HANDLED && (
        <HandleResolutionCaseButton resolutionCaseId={resolutionCaseId} />
      )}

      {status != ResolutionCaseStatus.HANDLED &&
        status != ResolutionCaseStatus.AWAITING_RESPONSE && (
          <AwaitingResponseResolutionCaseButton resolutionCaseId={resolutionCaseId} />
        )}
    </Stack>
  );
};
