import TableRow from '@mui/material/TableRow';
import ReturnScan from '../../../../domain/automator/ReturnScan';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import { ReturnItemCard } from '../../returns/components/ReturnItemCard';
import { ReturnItemStatusChip } from '../../returns/components/ReturnItemStatusChip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ActionButton from '../../../../components/ActionButton';
import { MinimalReturnProcessingResultStack } from '../../returns/components/MinimalReturnProcessingResultStack';
import transformReducedReturnToReturn from '../../../../domain/automator/services/transformReducedReturnToReturn';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { getTimeStringFromDate } from '../../../../helpers/getTimeStringFromDate';
import { ScannedIconChip } from './ScannedIcon';

interface ReturnScanTableRowProps {
  returnScan: ReturnScan;
}

export const ReturnItemReturnScanTableRow = ({ returnScan }: ReturnScanTableRowProps) => {
  const returnItem = returnScan.returnItem!;
  const reducedReturn = returnScan.return!;

  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.VIEW_RETURN_SCAN_PHOTOS);

  return (
    <TableRow>
      <SlimTableCell width={20}>
        <ScannedIconChip />
      </SlimTableCell>

      <SlimTableCell>
        <ReturnItemCard
          quantity={0}
          return_={transformReducedReturnToReturn(reducedReturn)}
          returnItem={returnItem}
          showTransporterInfo={false}
        />
      </SlimTableCell>

      <SlimTableCell>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
        >
          <ReturnItemStatusChip returnItem={returnItem} />
          <MinimalReturnProcessingResultStack returnItem={returnItem} />
        </Stack>
      </SlimTableCell>

      <SlimTableCell>
        <Typography variant="body2">{returnScan.comments}</Typography>
      </SlimTableCell>

      <SlimTableCell>
        {returnScan.hasPhotos ? (
          <ActionButton
            size="small"
            variant="outlined"
            label={t(tokens.automator.return_scans.view_photos)}
            onClick={() => openDialog({ returnScanId: returnScan.id })}
          />
        ) : (
          <></>
        )}
      </SlimTableCell>

      <SlimTableCell>
        <Typography variant="body2">
          {getTimeStringFromDate(new Date(returnScan.scannedDateTime))}
        </Typography>
      </SlimTableCell>
    </TableRow>
  );
};
