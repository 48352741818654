import { MESSAGE_TEMPLATES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { MessageTemplatesQueryKey } from '../../queryKeys/MessageTemplatesQueryKey';
import MessageTemplate from '../../../domain/automator/MessageTemplate';

export const useArchiveMessageTemplate = (messageTemplate: MessageTemplate) => {
  const url = useAutomatorApiAccountUrl(
    MESSAGE_TEMPLATES_PATH + '/' + messageTemplate.id + '/archive'
  );

  return useAuthorizedPost<undefined, undefined>(
    url,
    [
      MessageTemplatesQueryKey(
        messageTemplate.type,
        messageTemplate.phase,
        messageTemplate.returnRecoverability
      ),
    ],
    undefined
  );
};
