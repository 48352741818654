import Stack from '@mui/material/Stack';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import { useTranslation } from 'react-i18next';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import { ShipmentDetailsAddress } from '../../orders/components/ShipmentDetailsAddress';
import { OrderRecipient } from '../../orders/components/OrderRecipient';
import { OrderDatesStack } from '../../orders/components/OrderDatesStack';
import Order from '../../../../domain/automator/Order';
import { tokens } from '../../../../locales/translationTokens';
import { OrderSummaryViewHeader } from './OrderSummaryViewHeader';
import Typography from '@mui/material/Typography';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import CopyEmailAddressButton from '../../orders/components/copyButtons/CopyEmailAddressButton';
import CopyPhoneNumberButton from '../../orders/components/copyButtons/CopyPhoneNumberButton';

interface OrderSummaryOrderViewProps {
  order: Order;
}

export const OrderSummaryOrderView = ({ order }: OrderSummaryOrderViewProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
    >
      <OrderSummaryViewHeader label={t(tokens.automator.order_summary.order)} />

      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Stack
          direction="row"
          alignItems="center"
        >
          {order.items.map((item, index) => {
            return (
              <TooltippedProductThumbnail
                key={index}
                productTitle={item.title}
                height={60}
                thumbnailUrl={item.thumbnailUrl}
              />
            );
          })}

          <Stack
            direction="column"
            gap={1}
          >
            <Stack
              direction="row"
              gap={1}
            >
              <DistributionPartyChip distributionParty={order.distributionParty} />
              <OrderRecipient
                shipmentDetails={order.shipmentDetails}
                isRepeatCustomer={order.isRepeatCustomer}
              />
            </Stack>

            <Stack
              direction="row"
              gap={1}
            >
              <RetailerLooksIcon retailerId={order.retailerId} />
              <Typography variant="body2">{order.bolOrderId}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          display={'flex'}
          flexDirection={'column'}
          gap={'0.1rem'}
        >
          <ShipmentDetailsAddress
            pickupPoint={order.pickupPoint}
            shipmentDetails={order.shipmentDetails}
          />
          <Stack
            direction="row"
            gap={0.5}
          >
            <CopyEmailAddressButton emailAddress={order.shipmentDetails.email} />

            {order.shipmentDetails.deliveryPhoneNumber && (
              <CopyPhoneNumberButton phoneNumber={order.shipmentDetails.deliveryPhoneNumber} />
            )}
          </Stack>
        </Stack>

        <OrderDatesStack order={order} />
      </Stack>
    </Stack>
  );
};
