import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { CreateEmailThreadForm, CreateEmailThreadMode } from '../components/CreateEmailThreadForm';
import OrderSummary from '../../../../domain/automator/OrderSummary';

export const CreateEmailThreadDialog = () => {
  const { t } = useTranslation();

  const id = DialogId.CREATE_EMAIL_THREAD;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={DialogId.CREATE_EMAIL_THREAD}
          title={t(tokens.automator.resolutions.dialogs.send_email.dialog_title)}
          maxWidth={'lg'}
        >
          <CreateEmailThreadForm
            phase={data.phase}
            aggregateId={data.aggregateId}
            aggregateIds={data.aggregateIds || []}
            mode={data.aggregateIds ? CreateEmailThreadMode.BULK : CreateEmailThreadMode.SINGLE}
            onClick={closeDialog}
            body={data.body}
            orderSummary={data.orderSummary}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateEmailThreadDialogData {
  phase: ThreadPhase | null;
  aggregateId: number | null;
  aggregateIds?: number[];
  body: string | null;
  orderSummary: OrderSummary | null;
}
