import { DialogId } from '../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../ApplicationDialog';
import { tokens } from '../../locales/translationTokens';
import { SettingsForm } from '../../pages/automator/settings/SettingsForm';
import { useCloseDialog } from '../../store/dialogs/useCloseDialog';
import { useFetchAccount } from '../../api/automator/account/useFetchAccount';

export const SettingsDialog = () => {
  const id = DialogId.SETTINGS;
  const { isOpen } = useIsDialogOpen(id);
  const { t } = useTranslation();
  const closeDialog = useCloseDialog(id);
  const { data: account } = useFetchAccount();

  return (
    <>
      {isOpen && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.settings.settings)}
        >
          <SettingsForm
            settings={account!.settings}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};
