import { OrderStatus } from '../../../domain/automator/OrderStatus';
import { DistributionParty } from '../../../domain/automator/DistributionParty';
import Order from '../../../domain/automator/Order';
import { OrdersQueryKey } from '../../queryKeys/OrdersQueryKey';
import { useFetchAccount } from '../account/useFetchAccount';
import { QueryKeysUpdater } from '../../useUpdateData';

export const useOrderQueryKeysUpdater = (): QueryKeysUpdater<Order> => {
  const { data: account } = useFetchAccount();

  const ignoreLatestHandoverDate = account?.settings.ignoreLatestHandoverDate || false;

  return {
    [OrdersQueryKey(OrderStatus.OPEN, DistributionParty.RETAILER)]: openOrderUpdater(
      ignoreLatestHandoverDate,
      DistributionParty.RETAILER
    ),
    [OrdersQueryKey(OrderStatus.OPEN, DistributionParty.BOL)]: openOrderUpdater(
      ignoreLatestHandoverDate,
      DistributionParty.BOL
    ),

    [OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.RETAILER)]: openOrderLaterUpdater(
      ignoreLatestHandoverDate,
      DistributionParty.RETAILER
    ),
    [OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.BOL)]: openOrderLaterUpdater(
      ignoreLatestHandoverDate,
      DistributionParty.BOL
    ),

    [OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, DistributionParty.RETAILER)]:
      pendingPickSessionOrderUpdater(DistributionParty.RETAILER),

    [OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, DistributionParty.BOL)]:
      pendingPickSessionOrderUpdater(DistributionParty.BOL),

    [OrdersQueryKey(OrderStatus.SHIPPED, DistributionParty.RETAILER)]: shippedOrderUpdater(
      DistributionParty.RETAILER
    ),
    [OrdersQueryKey(OrderStatus.SHIPPED, DistributionParty.BOL)]: shippedOrderUpdater(
      DistributionParty.BOL
    ),

    [OrdersQueryKey(OrderStatus.CANCELLED, DistributionParty.RETAILER)]: cancelledOrderUpdater(
      DistributionParty.RETAILER
    ),
    [OrdersQueryKey(OrderStatus.CANCELLED, DistributionParty.BOL)]: cancelledOrderUpdater(
      DistributionParty.BOL
    ),

    [OrdersQueryKey(OrderStatus.FAILED, DistributionParty.RETAILER)]: failedOrderUpdater(
      DistributionParty.RETAILER
    ),
    [OrdersQueryKey(OrderStatus.FAILED, DistributionParty.BOL)]: failedOrderUpdater(
      DistributionParty.BOL
    ),
  };
};

const openOrderUpdater = (
  ignoreLatestHandoverDate: boolean,
  distributionParty: DistributionParty
) => {
  const openTodayCriteria = (order: Order) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day.
    const orderHandoverDate = new Date(order.latestHandoverDate || '');
    orderHandoverDate.setHours(0, 0, 0, 0); // Start of the day.
    return orderHandoverDate <= today;
  };

  return {
    addCriteria: (data: Order) => {
      const baseCriteria =
        data.status === OrderStatus.OPEN && data.distributionParty === distributionParty;

      if (!ignoreLatestHandoverDate && distributionParty === DistributionParty.BOL) {
        return baseCriteria && openTodayCriteria(data);
      }

      return baseCriteria;
    },
    sort: sortByItemAmountThenInternalReference,
  };
};

const openOrderLaterUpdater = (
  ignoreLatestHandoverDate: boolean,
  distributionParty: DistributionParty
) => {
  const openLaterCriteria = (order: Order) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day.
    const orderHandoverDate = new Date(order.latestHandoverDate || '');
    orderHandoverDate.setHours(0, 0, 0, 0); // Start of the day.
    return orderHandoverDate > today;
  };

  return {
    addCriteria: (data: Order) => {
      if (ignoreLatestHandoverDate) {
        return false;
      }

      const baseCriteria =
        data.status === OrderStatus.OPEN && data.distributionParty === distributionParty;

      return baseCriteria && openLaterCriteria(data);
    },
    sort: sortByItemAmountThenInternalReference, // @TODO also sort by latestHandoverDate first
  };
};

const pendingPickSessionOrderUpdater = (distributionParty: DistributionParty) => {
  return {
    addCriteria: (data: Order) => {
      return (
        data.status === OrderStatus.PENDING_PICK_SESSION &&
        data.distributionParty === distributionParty
      );
    },
    sort: sortByItemAmountThenInternalReference,
  };
};

const shippedOrderUpdater = (distributionParty: DistributionParty) => {
  return {
    addCriteria: (data: Order) => {
      return data.status === OrderStatus.SHIPPED && data.distributionParty === distributionParty;
    },
    sort: (a: Order, b: Order) => {
      const aShipmentTime = a.shipmentDateTime ? new Date(a.shipmentDateTime).getTime() : null;
      const bShipmentTime = b.shipmentDateTime ? new Date(b.shipmentDateTime).getTime() : null;

      if (aShipmentTime && bShipmentTime) {
        if (aShipmentTime > bShipmentTime) {
          return -1;
        } else if (aShipmentTime < bShipmentTime) {
          return 1;
        }
      } else if (aShipmentTime) {
        return -1;
      } else if (bShipmentTime) {
        return 1;
      }

      return compareInternalReference(a, b);
    },
  };
};

const cancelledOrderUpdater = (distributionParty: DistributionParty) => {
  return {
    addCriteria: (data: Order) => {
      return data.status === OrderStatus.CANCELLED && data.distributionParty === distributionParty;
    },
    sort: sortByItemAmountThenInternalReference,
  };
};

const failedOrderUpdater = (distributionParty: DistributionParty) => {
  return {
    addCriteria: (data: Order) => {
      return (
        (data.status === OrderStatus.FAILED || data.status === OrderStatus.CAN_NOT_PROCESS) &&
        data.distributionParty === distributionParty
      );
    },
    sort: sortByItemAmountThenInternalReference,
  };
};

const sortByItemAmountThenInternalReference = (a: Order, b: Order) => {
  const itemsLengthDifference = b.items.length - a.items.length;
  if (itemsLengthDifference !== 0) {
    return itemsLengthDifference;
  }
  return compareInternalReference(a, b);
};

const compareInternalReference = (a: Order, b: Order) => {
  const aInternalReference = a.items[0]?.internalReference || '';
  const bInternalReference = b.items[0]?.internalReference || '';
  return aInternalReference.localeCompare(bInternalReference);
};
