import ActionButton from '../../../../components/ActionButton';
import { LabelType } from '../../../../domain/automator/LabelType';
import { toast } from 'react-hot-toast';
import LabelTypeRule from '../../../../domain/automator/LabelTypeRule';
import { useUpdateLabelTypeRules } from '../../../../api/automator/label-type-rules/useUpdateLabelTypeRules';
import { useTranslation } from 'react-i18next';

interface SetAllLabelTypeRulesToMailboxButtonProps {
  label: string;
  toastMessage: string;
  labelType: LabelType;
  labelTypeRules?: LabelTypeRule[] | null;
}

export const SetAllLabelTypeRulesToLabelTypeButton = ({
  label,
  toastMessage,
  labelType,
  labelTypeRules,
}: SetAllLabelTypeRulesToMailboxButtonProps) => {
  const { mutate: updateLabelTypeRules, isLoading } = useUpdateLabelTypeRules();
  const labelTypeRuleIds = labelTypeRules ? labelTypeRules.map((ltr) => ltr.id) : [];

  const { t } = useTranslation();
  const doUpdate = () => {
    const data = {
      label_type_rule_ids: labelTypeRuleIds,
      max_items_for_mailbox: null,
      label_type: labelType,
    };

    updateLabelTypeRules(data, {
      onSuccess: () => {
        toast.success(t(toastMessage));
      },
    });
  };

  return (
    <ActionButton
      label={t(label)}
      onClick={() => doUpdate()}
      isLoading={isLoading}
    />
  );
};
