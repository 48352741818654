import { Autocomplete, TextField } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { useFetchAutomatorProducts } from '../../../../api/automator/products/useFetchAutomatorProducts';
import { truncateString } from '../../../../helpers/truncateString';
import { ProductThumbnail } from '../../orders/components/ProductThumbnail';
import AutomatorProduct from '../../../../domain/automator/AutomatorProduct';
import Stack from '@mui/material/Stack';

interface AutomatorProductsAutocompleteProps {
  productIds: number[];
  onSelect: (productIds: number[]) => void;
}

interface OptionType {
  value: number;
  label: string;
  firstLetter: string;
  thumbnailUrl?: string;
}

export const AutomatorProductsAutocomplete = ({
  productIds,
  onSelect,
}: AutomatorProductsAutocompleteProps) => {
  const { data: products, isLoading } = useFetchAutomatorProducts();

  const options: OptionType[] = isLoading
    ? []
    : products!.products.map((product: AutomatorProduct) => ({
        value: product.id,
        label: truncateString(product.title, 60),
        firstLetter: product.title[0].toUpperCase(),
        thumbnailUrl: product.thumbnailUrl,
      }));

  const handleChange = (newValue: OptionType[]) => {
    onSelect(newValue.map((option) => option.value));
  };

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option: OptionType) => option.label}
      groupBy={(option: OptionType) => option.firstLetter}
      value={options.filter((option) => productIds.includes(option.value))}
      onChange={(_, value) => handleChange(value)}
      openOnFocus
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={tokens.automator.products.products}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <input
            style={{ marginRight: '20px', marginLeft: '-10px' }}
            type="checkbox"
            checked={selected}
            readOnly
          />
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
          >
            <ProductThumbnail
              thumbnailUrl={option.thumbnailUrl}
              height={40}
            />
            {option.label}
          </Stack>
        </li>
      )}
    />
  );
};
