import Badge from '@mui/material/Badge';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../locales/translationTokens';

interface PickSessionsNeedingApprovalBatchProps {
  amountOfPickSessionsNeedingApproval: number;
}

export const PickSessionsNeedingApprovalBadge = ({
  amountOfPickSessionsNeedingApproval,
}: PickSessionsNeedingApprovalBatchProps) => {
  const { t } = useTranslation();

  if (amountOfPickSessionsNeedingApproval === 0) {
    return '';
  }

  return (
    <Badge
      badgeContent={t(tokens.nav.pick_sessions_needing_approval)}
      color="info"
    />
  );
};
