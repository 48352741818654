import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Orders from '../../../domain/automator/Orders';
import { useOrderQueryKeysUpdater } from './useOrderQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';

export const useApproveCancellationRequests = () => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + `/approve-customer-cancellation-requests`);

  const updater = useOrderQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'orders');

  const onSuccess = (orders: Orders) => {
    updateData(orders);
  };

  return useAuthorizedPost<Orders>(url, [], undefined, onSuccess);
};
