import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import Resend from '../../../../domain/automator/Resend';
import { ResendTableRow } from './ResendTableRow';

interface ResendListTableProps {
  resends?: Resend[];
  isLoading: boolean;
}

export const ResendListTable = ({ resends, isLoading }: ResendListTableProps) => {
  const { t } = useTranslation();

  if (isLoading || !resends) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (resends!.length == 0) {
    return <TableEmpty message={t(tokens.automator.resolutions.no_items)} />;
  }

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.resolutions.resend_table.order_items)}</TableCell>
            <TableCell> {t(tokens.automator.resolutions.resend_table.order_number)}</TableCell>
            <TableCell> {t(tokens.automator.resolutions.resend_table.shipment_details)}</TableCell>
            <TableCell> {t(tokens.automator.resolutions.resend_table.note)}</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {resends!.map((resend) => {
            return (
              <ResendTableRow
                key={resend.id}
                resend={resend}
              />
            );
          })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
