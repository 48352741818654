import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PRODUCT_RANKS } from '../pdApiPaths';
import ProductRanks from '../../../domain/pd/ProductRanks';
import { ProductRanksQueryKey } from '../../queryKeys/ProductRanksQueryKey';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import { CountryCode } from '../../../domain/automator/CountryCode';

export const useFetchProductRanks = (
  startDate: Date,
  endDate: Date,
  productId?: number,
  categoryId?: number,
  searchTermId?: number,
  countryCode?: CountryCode | null
) => {
  const url = usePdApiAccountUrl(
    PRODUCT_RANKS +
      `?startDate=${getDateStringFromDate(startDate)}` +
      `&endDate=${getDateStringFromDate(endDate)}` +
      (productId ? `&productId=${productId}` : '') +
      (searchTermId ? `&searchTermId=${searchTermId}` : '') +
      (categoryId ? `&categoryId=${categoryId}` : '') +
      (countryCode ? `&countryCode=${countryCode}` : '')
  );
  return useFetchData<ProductRanks>(
    url,
    ProductRanksQueryKey(startDate, endDate, productId, categoryId, searchTermId, countryCode),
    { enabled: categoryId !== undefined || searchTermId !== undefined }
  );
};
