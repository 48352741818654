import { RESOLUTION_CASES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ResolutionDossiersQueryKey } from './ResolutionDossiersQueryKey';
import { OrderSummaryQueryKey } from '../../queryKeys/OrderSummaryQueryKey';
import ResolutionCase from '../../../domain/automator/ResolutionCase';
import { ResolutionCaseQueryKey } from './ResolutionCaseQueryKey';

export const useHandleResolutionCase = (resolutionCaseId: number) => {
  const url = useAutomatorApiAccountUrl(RESOLUTION_CASES_PATH + '/' + resolutionCaseId + `/handle`);

  return useAuthorizedPost<ResolutionCase, undefined>(
    url,
    [
      ResolutionDossiersQueryKey(undefined),
      OrderSummaryQueryKey(undefined),
      ResolutionCaseQueryKey(undefined),
    ],
    undefined
  );
};
