import { MESSAGE_TEMPLATES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ThreadPhase } from '../../../domain/automator/ThreadPhase';
import { MessageTemplatesQueryKey } from '../../queryKeys/MessageTemplatesQueryKey';
import { ReturnRecoverability } from '../../../domain/automator/ReturnRecoverability';
import MessageTemplates from '../../../domain/automator/MessageTemplates';
import { MessageTemplateType } from '../../../domain/automator/MessageTemplateType';

export const useFetchMessageTemplates = (
  type: MessageTemplateType,
  phase: ThreadPhase | null,
  recoverability: ReturnRecoverability | null
) => {
  let params = '?type=' + type;
  if (phase) params += `&phase=${phase}`;
  if (recoverability)
    params += phase ? `&recoverability=${recoverability}` : `?recoverability=${recoverability}`;

  const url = useAutomatorApiAccountUrl(`${MESSAGE_TEMPLATES_PATH}${params}`);
  return useFetchData<MessageTemplates>(url, MessageTemplatesQueryKey(type, phase, recoverability));
};
