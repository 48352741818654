import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { OrderSummaryView } from '../components/OrderSummaryView';

export const ViewOrderSummaryDialog = () => {
  const id = DialogId.VIEW_ORDER_SUMMARY;

  const { isOpen, data } = useIsDialogOpen(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'md'}
          title={t(tokens.automator.order_summary.order_summary)}
        >
          <OrderSummaryView bolOrderId={data.bolOrderId} />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface ViewOrderSummaryDialogData {
  bolOrderId: string;
}
