import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { useHandleResolutionCase } from '../../../../api/automator/resolutions/useHandleResolutionCase';
import { toast } from 'react-hot-toast';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { ActionIcon } from '../../orders/components/ActionIcon';

interface HandleResolutionCaseButtonProps {
  resolutionCaseId: number;
}

export const HandleResolutionCaseButton = ({
  resolutionCaseId,
}: HandleResolutionCaseButtonProps) => {
  const { mutate, isLoading } = useHandleResolutionCase(resolutionCaseId);

  const { t } = useTranslation();

  const onClick = () => {
    mutate(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.resolutions.actions.case_handled));
      },
    });
  };

  return (
    <ActionIcon
      tooltip={t(tokens.automator.resolutions.actions.handle_case)}
      onClick={() => onClick()}
      isLoading={isLoading}
      icon={<AssignmentTurnedInIcon />}
      color="primary"
    />
  );
};
