import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { tokens } from '../../../../locales/translationTokens';
import ShipmentStatusChip from '../../shipments/components/ShipmentEventStatus';
import Shipment from '../../../../domain/automator/Shipment';
import { TransportEventCode } from '../../../../domain/automator/TransportEventCode';
import IsResentAfterReturnCheckbox from '../../shipments/components/IsResentAfterReturnCheckbox';
import DaysUntilReturnText from '../../shipments/components/DaysUntilReturnText';
import ShipmentOverdueChip from '../../shipments/components/ShipmentOverdueChip';
import { isOverDue } from '../../shipments/components/ShipmentRow';
import { ShipmentEventLoadingStatus } from '../../../../domain/automator/ShipmentEventLoadingStatus';
import { OrderSummaryViewHeader } from './OrderSummaryViewHeader';
import TransporterChip from '../../shipments/components/TransporterChip';
import HasReturnedToSenderIndicator from '../../shipments/components/HasReturnedToSenderIndicator';

interface OrderSummaryShipmentViewProps {
  shipment: Shipment | null;
}

export const OrderSummaryShipmentView = ({ shipment }: OrderSummaryShipmentViewProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
    >
      <OrderSummaryViewHeader label={t(tokens.automator.order_summary.shipment)} />

      {shipment && (
        <>
          <Stack
            direction="row"
            alignItems={'center'}
          >
            <ShipmentStatusChip
              shipmentEventsLoadingStatus={ShipmentEventLoadingStatus.NOT_APPLICABLE}
              shipment={shipment}
            />
            {shipment.latestEvent?.eventCode == TransportEventCode.RETURNED_TO_SENDER && (
              <IsResentAfterReturnCheckbox shipment={shipment} />
            )}
          </Stack>

          <Stack
            gap={'0.5rem'}
            direction={'row'}
          >
            <Typography>{shipment.expectedDeliveryDate}</Typography>

            {shipment.isTrackAndTraceable && (
              <>
                {shipment.daysUntilReturn != null && (
                  <DaysUntilReturnText daysUntilReturn={shipment.daysUntilReturn!} />
                )}
                {isOverDue(shipment) && <ShipmentOverdueChip />}
              </>
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems={'center'}
            gap={1}
          >
            {shipment.transporterInfos.map((transporterInfo, index) => (
              <TransporterChip
                key={index}
                transporterCode={transporterInfo.transporterCode}
                trackAndTrace={transporterInfo.trackAndTraceUrl}
                distributionParty={shipment.distributionParty}
              />
            ))}
          </Stack>

          {shipment.hasReturnedToSender && <HasReturnedToSenderIndicator />}
        </>
      )}
    </Stack>
  );
};
