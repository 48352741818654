import * as React from 'react';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';
import { Redo } from '@mui/icons-material';

interface OpenCreateResendDialogButtonProps {
  orderId: number;
}

const OpenCreateResendDialogButton = ({ orderId }: OpenCreateResendDialogButtonProps) => {
  const openCreateResendDialog = useOpenDialog(DialogId.CREATE_RESEND);
  const { t } = useTranslation();

  return (
    <ActionButton
      size="small"
      onClick={() => openCreateResendDialog({ orderId: orderId })}
      label={t(tokens.automator.resolutions.dialogs.create_resend.create_resend)}
      style={{ alignSelf: 'center' }}
      icon={<Redo />}
    />
  );
};

export default OpenCreateResendDialogButton;
