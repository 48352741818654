import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { LabelType } from '../../../../domain/automator/LabelType';
import { toast } from 'react-hot-toast';
import { useUpdateOrdersPreferredLabelType } from '../../../../api/automator/orders/useUpdateOrdersPreferredLabelType';
import { useTranslation } from 'react-i18next';

interface SetSelectedOrdersToLabelTypeButtonProps {
  selectedOrderIds: number[];
  labelType: LabelType;
}

const SetSelectedOrdersToLabelTypeButton = ({
  selectedOrderIds,
  labelType,
}: SetSelectedOrdersToLabelTypeButtonProps) => {
  const { mutate: updateOrdersPreferredLabelType, isLoading } = useUpdateOrdersPreferredLabelType();

  const { t } = useTranslation();

  const doUpdateLabelType = () => {
    const data = {
      order_ids: selectedOrderIds,
      label_type: labelType,
    };
    updateOrdersPreferredLabelType(data, {
      onSuccess: () => {
        toast.success(
          t(
            labelType == LabelType.PARCEL
              ? tokens.automator.orders.buttons.set_to_parcel_success
              : tokens.automator.orders.buttons.set_to_mailbox_success
          )
        );
      },
    });
  };

  return (
    <ActionButton
      color="inherit"
      variant="outlined"
      size="small"
      label={
        labelType == LabelType.PARCEL
          ? tokens.automator.orders.buttons.set_to_parcel
          : tokens.automator.orders.buttons.set_to_mailbox
      }
      onClick={() => {
        doUpdateLabelType();
      }}
      isLoading={isLoading}
    />
  );
};

export default SetSelectedOrdersToLabelTypeButton;
