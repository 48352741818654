import { useTranslation } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';

interface TableListDividerProps {
  label: string;
}

export const TableListDivider = ({ label }: TableListDividerProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell
        align="center"
        colSpan={6}
        style={{ borderTop: 'none', borderBottom: 'none', paddingBottom: 0, margin: 0 }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <hr style={{ flexGrow: 1, marginRight: '15px' }} />

          <Chip
            label={t(label)}
            variant="filled"
            size="small"
            style={{ width: 'max-content', minWidth: '130px', borderRadius: '5px' }}
          />

          <hr style={{ flexGrow: 17, marginLeft: '15px' }} />
        </div>
      </TableCell>
    </TableRow>
  );
};
