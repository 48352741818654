import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { ReturnTableRow } from './ReturnTableRow';
import { TableEmpty } from '../../../../components/TableEmpty';
import Return from '../../../../domain/automator/Return';
import { TableListDivider } from './TableListDivider';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';
import { ReturnItemFilter } from '../../../../domain/automator/ReturnItemFilter';

interface ReturnListOverviewTableProps {
  returns?: Return[];
  currentTab: ReturnItemFilter;
  isLoading: boolean;
}

export const ReturnListOverviewTable = ({
  returns,
  isLoading,
  currentTab,
}: ReturnListOverviewTableProps) => {
  const { t } = useTranslation();

  if (isLoading || !returns) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (returns!.length == 0) {
    return <TableEmpty message={t(tokens.automator.returns.no_items)} />;
  }

  const recentReturns = returns.filter(
    (return_) => return_.daysSinceRegistration >= 0 && return_.daysSinceRegistration <= 14
  );
  const oldReturns = returns.filter((return_) => return_.daysSinceRegistration > 14);

  const getDividerLabel = (day: number) => {
    const date = new Date();

    switch (day) {
      case 0:
        return t(tokens.automator.returns.today);
      case 1:
        return (
          t(tokens.automator.returns.yesterday) + ' | ' + '1 ' + t(tokens.automator.returns.day_old)
        );
      case 2:
        return (
          t(tokens.automator.returns.day_before_yesterday) +
          ' | ' +
          '2 ' +
          t(tokens.automator.returns.days_old)
        );
      default:
        date.setDate(date.getDate() - day);
        // format date as per your requirement, using toLocaleDateString method for illustration
        return (
          getAlphabeticFormatFromDate(date) +
          ' | ' +
          day +
          ' ' +
          t(tokens.automator.returns.days_old)
        );
    }
  };

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={10} />
          </TableRow>
        </TableHead>

        <TableBody>
          {Array.from({ length: 15 }, (_, i) => i).map((day) => {
            const returnsForDay = recentReturns.filter(
              (return_) => return_.daysSinceRegistration === day
            );

            if (returnsForDay.length == 0) return null;

            return (
              <>
                <TableListDivider label={getDividerLabel(day)} />
                {returnsForDay.map((return_) => (
                  <ReturnTableRow
                    showDaysRegistered={false}
                    key={return_.id}
                    return_={return_}
                    currentTab={currentTab}
                  />
                ))}
              </>
            );
          })}

          <TableListDivider label={t(tokens.automator.returns.other)} />

          {oldReturns.map((return_) => {
            return (
              <ReturnTableRow
                showDaysRegistered={false}
                key={return_.id}
                return_={return_}
                currentTab={currentTab}
              />
            );
          })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
