import Box from '@mui/material/Box';
import { LatestDays } from '../LatestDaysDropdownSelect';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import { useFetchSearchTermVolumes } from '../../../../../../api/pd/productPerformances/useFetchSearchTermVolumes';
import { DeviationBarChart } from './DeviationBarChart';
import { tokens } from '../../../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import getYesterday from '../../../../../../helpers/getYesterday';
import { CountryCode } from '../../../../../../domain/automator/CountryCode';

interface SearchTermVolumeDeviationChartProps {
  searchTermId: number;
  latestDays: LatestDays;
  countryCode: CountryCode | null;
}

export const SearchTermVolumeDeviationChart = ({
  searchTermId,
  latestDays,
  countryCode,
}: SearchTermVolumeDeviationChartProps) => {
  const { data: searchTermVolumes, isLoading } = useFetchSearchTermVolumes(
    searchTermId,
    getLatestDateByLatestDays(latestDays),
    getYesterday(),
    countryCode
  );

  const { t } = useTranslation();

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  const searchTermVolumesSorted = searchTermVolumes!.searchTermVolumes.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const chartSeries = [
    {
      name: t(tokens.pd.performance.search_term_volume_chart.volume),
      data: searchTermVolumesSorted.map((searchTermVolume) => searchTermVolume.deviation),
    },
  ];

  return (
    <DeviationBarChart
      header={t(tokens.pd.performance.search_term_volume_chart.title)}
      chartSeries={chartSeries}
      categories={searchTermVolumesSorted.map((searchTermVolume) => searchTermVolume.date)}
    />
  );
};
