import Badge from '@mui/material/Badge';
import Mail01Icon from '@untitled-ui/icons-react/build/esm/Mail01';

interface NewEmailMessagesBadgeProps {
  amountOfNewEmailMessages: number;
}

export const NewEmailMessagesBadge = ({ amountOfNewEmailMessages }: NewEmailMessagesBadgeProps) => {
  if (amountOfNewEmailMessages === 0) {
    return '';
  }

  return (
    <Badge
      badgeContent={'+' + amountOfNewEmailMessages}
      color="info"
    >
      <Mail01Icon color="action" />
    </Badge>
  );
};
