import { ProductThumbnail } from '../../orders/components/ProductThumbnail';
import ResolutionDossier from '../../../../domain/automator/ResolutionDossier';
import Badge from '@mui/material/Badge';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface ResolutionDossierProductThumbnailProps {
  resolutionDossier: ResolutionDossier;
}

export const ResolutionDossierProductThumbnail = ({
  resolutionDossier,
}: ResolutionDossierProductThumbnailProps) => {
  const { t } = useTranslation();

  const isAwaitingResponseOnReply = (resolutionDossier: ResolutionDossier) => {
    return resolutionDossier.resolutionCases.some((resolutionCase) => {
      return resolutionCase.isAWaitingResponseOnReply;
    });
  };

  const hasNewReply = (resolutionDossier: ResolutionDossier) => {
    if (isAwaitingResponseOnReply(resolutionDossier)) {
      return false;
    }

    return resolutionDossier.resolutionCases.some((resolutionCase) => {
      return resolutionCase.latestReplyDate != null;
    });
  };

  return (
    <Badge
      color="warning"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      invisible={!resolutionDossier.isOld}
      badgeContent={t(tokens.automator.resolutions.resolution_dossier_table.is_old)}
    >
      <Badge
        color="success"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        invisible={!hasNewReply(resolutionDossier) && !isAwaitingResponseOnReply(resolutionDossier)}
        badgeContent={
          hasNewReply(resolutionDossier)
            ? t(tokens.automator.resolutions.resolution_dossier_table.new_reply)
            : t(tokens.automator.resolutions.resolution_dossier_table.awaiting_reply_response)
        }
      >
        <ProductThumbnail
          height={40}
          thumbnailUrl={resolutionDossier.thumbnailUrl}
        />
      </Badge>
    </Badge>
  );
};
