import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Order from '../../../../domain/automator/Order';
import Checkbox from '@mui/material/Checkbox';
import { tokens } from '../../../../locales/translationTokens';
import { LabelTypeToggleButton } from './LabelTypeToggleButton';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useUpdateOrdersPreferredLabelType } from '../../../../api/automator/orders/useUpdateOrdersPreferredLabelType';
import { CountryCode } from '../../../../domain/automator/CountryCode';
import { OrderItemsTableCell } from './OrderItemsTableCell';
import { OrderDatesStack } from './OrderDatesStack';
import { LabelType } from '../../../../domain/automator/LabelType';
import { OrderStatus } from '../../../../domain/automator/OrderStatus';
import { OrderRecipient } from './OrderRecipient';

import { DistributionParty } from '../../../../domain/automator/DistributionParty';
import { useActiveCopyButton } from '../../../../store/activeCopyButton/useActiveCopyButton';
import Stack from '@mui/material/Stack';
import { OrderCopyButtons } from './copyButtons/OrderCopyButtons';
import OrderItem from '../../../../domain/automator/OrderItem';
import { OrdersMode } from '../OrdersPage';
import { ShipmentDetailsAddress } from './ShipmentDetailsAddress';

interface OrderTableRowProps {
  order: Order;
  mode: OrdersMode;
  isOrderSelected: boolean;
  onSelectOrder?: (order: Order) => void;
  onDeselectOrder?: (orderId: number) => void;
  distributionParty: DistributionParty;
  selectedOrderItemIds: number[];
  onSelectOrderItem: (orderItem: OrderItem) => void;
}

export const OrderTableRow = ({
  order,
  mode,
  isOrderSelected,
  onSelectOrder,
  onDeselectOrder,
  distributionParty,
  selectedOrderItemIds,
  onSelectOrderItem,
}: OrderTableRowProps) => {
  const { t } = useTranslation();

  const { activeAggregateId } = useActiveCopyButton();

  const { mutate: updateOrdersPreferredLabelType } = useUpdateOrdersPreferredLabelType();

  const isSelectedLabelTypeDisabled =
    mode != OrdersMode.BULK_ORDERS ||
    order.shipmentDetails.countryCode == CountryCode.BE ||
    order.pickupPoint ||
    order.isPartiallyProcessedExternally ||
    order.hasConflictingHandoverDateTime;

  const doUpdateLabelType = (orderId: number, labelType: LabelType) => {
    const data = {
      order_ids: [orderId],
      label_type: labelType,
    };
    updateOrdersPreferredLabelType(data, {
      onSuccess: () => {
        toast.success(t(tokens.automator.orders.toasts.label_type_changed));
      },
    });
  };

  const determineSideBarColor = () => {
    if (order.items.length > 1) {
      return 'blue';
    } else if (order.items.some((item) => item.quantity > 1)) {
      return 'blue';
    } else {
      return 'white';
    }
  };

  return (
    <TableRow
      style={{ backgroundColor: activeAggregateId == order.id ? '#ece3e3' : undefined }}
      key={order.id}
    >
      <TableCell sx={{ padding: 0, maxWidth: 2, position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            borderLeft: '5.5px solid ' + determineSideBarColor(),
            height: '85%',
          }}
        />
      </TableCell>

      {mode == OrdersMode.BULK_ORDERS && (
        <TableCell padding="checkbox">
          <Checkbox
            disabled={
              order.isPartiallyProcessedExternally ||
              order.hasConflictingHandoverDateTime ||
              order.status == OrderStatus.CAN_NOT_PROCESS
            }
            checked={isOrderSelected}
            onChange={(event) => {
              event.target.checked ? onSelectOrder?.(order) : onDeselectOrder?.(order.id);
            }}
          />
        </TableCell>
      )}

      <OrderItemsTableCell
        distributionParty={distributionParty}
        order={order}
        mode={mode}
        onSelect={onSelectOrderItem}
        selectedOrderItemIds={selectedOrderItemIds}
      />

      <TableCell>
        <OrderDatesStack order={order} />
      </TableCell>

      <TableCell>
        <Stack
          display={'flex'}
          flexDirection={'column'}
          gap={'0.1rem'}
        >
          <OrderRecipient
            shipmentDetails={order.shipmentDetails}
            isRepeatCustomer={order.isRepeatCustomer}
          />
          <ShipmentDetailsAddress
            pickupPoint={order.pickupPoint}
            shipmentDetails={order.shipmentDetails}
          />
        </Stack>
      </TableCell>

      {order.distributionParty == DistributionParty.BOL && (
        <TableCell>
          <LabelTypeToggleButton
            id={order.id}
            isDisabled={isSelectedLabelTypeDisabled}
            value={order.labelType}
            onChange={(orderId, labelType) => {
              doUpdateLabelType(orderId, labelType);
            }}
          />
        </TableCell>
      )}

      {order.status == OrderStatus.SHIPPED && <TableCell>{order.shipmentDateTime}</TableCell>}

      {order.status == OrderStatus.SHIPPED && (
        <TableCell>
          <OrderCopyButtons order={order} />
        </TableCell>
      )}
    </TableRow>
  );
};
