import { tokens } from '../../../../locales/translationTokens';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EmailDocument from '../../../../domain/automator/EmailDocument';
import { useTranslation } from 'react-i18next';

interface EmailDocumentsListProps {
  documents: EmailDocument[];
}

export const EmailDocumentsList = ({ documents }: EmailDocumentsListProps) => {
  const { t } = useTranslation();

  return (
    documents.length > 0 && (
      <Stack direction="column">
        <Typography variant="h6">
          {t(tokens.automator.resolutions.dialogs.send_email.attachments)}
        </Typography>

        {documents.map((doc, index) => (
          <Typography
            key={index}
            variant="body1"
          >
            {doc.name}
          </Typography>
        ))}
      </Stack>
    )
  );
};
