import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { ReturnListTable } from './components/ReturnListTable';
import ReturnItemTabs from './components/ReturnItemTabs';
import { useState } from 'react';
import { HandledReturnListTable } from './components/HandledReturnListTable';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import ActionButton from '../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import { ReturnListOverviewTable } from './components/ReturnListOverviewTable';
import { useFetchReturns } from '../../../api/automator/returns/useFetchReturns';
import { ReturnItemFilter } from '../../../domain/automator/ReturnItemFilter';

const ReturnsPage = () => {
  const [currentTab, setCurrentTab] = useState(ReturnItemFilter.OVERVIEW);

  const openDialog = useOpenDialog(DialogId.CREATE_RETURN);

  const { data: returns, isLoading } = useFetchReturns(currentTab);

  const { t } = useTranslation();

  const determineTable = () => {
    switch (currentTab) {
      case ReturnItemFilter.HANDLED:
        return (
          <HandledReturnListTable
            returns={returns && returns.returns}
            isLoading={isLoading}
          />
        );
      case ReturnItemFilter.OVERVIEW:
        return (
          <ReturnListOverviewTable
            currentTab={currentTab}
            isLoading={isLoading}
            returns={returns && returns.returns}
          />
        );
      default:
        return (
          <ReturnListTable
            currentTab={currentTab}
            returns={returns && returns.returns}
            isLoading={isLoading}
          />
        );
    }
  };

  return (
    <AccountPage
      buttons={[
        <ActionButton
          key={1}
          label={t(tokens.automator.returns.dialogs.create_return.dialog_title)}
          onClick={() => openDialog()}
          variant="contained"
          color="primary"
          size="small"
        />,
      ]}
      title={tokens.automator.returns.returns}
    >
      <ReturnItemTabs
        report={returns && returns.report}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      {determineTable()}
    </AccountPage>
  );
};

export default ReturnsPage;
