import SearchTerm from '../../../../../../domain/pd/SearchTerm';
import Stack from '@mui/material/Stack';
import { LatestDays } from '../LatestDaysDropdownSelect';
import { SearchTermVolumeDeviationChart } from './SearchTermVolumeDeviationChart';
import { AssortmentImpressionsDeviationChart } from './AssortmentImpressionsDeviationChart';
import { AssortmentVisitsDeviationChart } from './AssortmentVisitsDeviationChart';
import { CountryCode } from '../../../../../../domain/automator/CountryCode';
import { useState } from 'react';
import { DeviationChartType, DeviationChartTypeDropdown } from './DeviationChartTypeDropdown';
import Box from '@mui/material/Box';

interface DeviationsChartProps {
  storeId: number;
  latestDays: LatestDays;
  countryCode: CountryCode | null;
  searchTerm: SearchTerm | undefined;
}

export const DeviationsChart = ({
  storeId,
  latestDays,
  countryCode,
  searchTerm,
}: DeviationsChartProps) => {
  const [currentTab, setCurrentTab] = useState<DeviationChartType | undefined>(undefined);

  return (
    <Stack spacing={2}>
      <DeviationChartTypeDropdown
        selectedChartType={currentTab}
        onSelect={setCurrentTab}
      />
      <Box>
        {currentTab == DeviationChartType.ASSORTMENT_IMPRESSIONS && (
          <AssortmentImpressionsDeviationChart
            latestDays={latestDays}
            storeId={storeId}
          />
        )}

        {currentTab == DeviationChartType.ASSORTMENT_VISITS && (
          <AssortmentVisitsDeviationChart
            latestDays={latestDays}
            storeId={storeId}
          />
        )}

        {searchTerm && currentTab == DeviationChartType.SEARCH_TERM_VOLUME && (
          <SearchTermVolumeDeviationChart
            latestDays={latestDays}
            searchTermId={searchTerm.id}
            countryCode={countryCode}
          />
        )}
      </Box>
    </Stack>
  );
};
