import Order from '../../../../domain/automator/Order';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useUpdateOrdersLatestHandoverDate } from '../../../../api/automator/orders/useUpdateOrdersLatestHandoverDate';
import { toast } from 'react-hot-toast';
import Typography from '@mui/material/Typography';
import ActionButton from 'src/components/ActionButton';
import { useQueryClient } from 'react-query';
import { OrdersQueryKey } from '../../../../api/queryKeys/OrdersQueryKey';
import { OrderStatus } from '../../../../domain/automator/OrderStatus';

interface ChangeOrdersLatestHandoverFormProps {
  onClick?: () => void;
  orders: Order[];
}

export const ChangeOrdersLatestHandoverForm = ({
  onClick,
  orders,
}: ChangeOrdersLatestHandoverFormProps) => {
  const [latestHandoverDate, setLatestHandoverDate] = useState<Date | null>(
    orders.length == 1 && orders[0].latestHandoverDate != null
      ? new Date(orders[0].latestHandoverDate)
      : null
  );

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const distributionParty = orders[0].distributionParty;

  const { mutate: updateOrdersLatestHandoverDate, isLoading } = useUpdateOrdersLatestHandoverDate();

  const handleDateChange = (date: Date | null) => {
    setLatestHandoverDate(date);
  };

  const doUpdate = () => {
    const data = {
      order_ids: orders.map((order) => order.id),
      latest_hand_over_date: latestHandoverDate,
    };
    updateOrdersLatestHandoverDate(data, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(OrdersQueryKey(OrderStatus.OPEN, distributionParty));
        await queryClient.invalidateQueries(
          OrdersQueryKey(OrderStatus.OPEN_LATER, distributionParty)
        );
        onClick?.();
        toast.success(t(tokens.automator.orders.latest_handover_date.latest_handover_date_updated));
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={2}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
        >
          {t(tokens.automator.orders.latest_handover_date.explanation)}
        </Typography>

        <StaticDatePicker
          disablePast={true}
          value={latestHandoverDate}
          onChange={handleDateChange}
          disabled={latestHandoverDate === null}
          slots={{
            toolbar: () => null, // Disables the toolbar
            actionBar: () => null,
          }}
        />

        <FormControlLabel
          control={
            <Switch
              checked={latestHandoverDate === null}
              onChange={() =>
                setLatestHandoverDate(latestHandoverDate === null ? new Date() : null)
              }
            />
          }
          label={t(tokens.automator.orders.latest_handover_date.let_bol_decide)}
        />

        <Box width={'100%'}>
          <ActionButton
            isLoading={isLoading}
            fullWidth
            variant="contained"
            color="primary"
            onClick={doUpdate}
            label={tokens.common.save}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default ChangeOrdersLatestHandoverForm;
