import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import LabelTypeRule from '../../../../domain/automator/LabelTypeRule';
import { LabelTypeRuleTableRow } from './LabelTypeRuleTableRow';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';

interface LabelTypeRuleListTableProps {
  labelTypeRules?: LabelTypeRule[] | null;
}

export const LabelTypeRuleListTable = (props: LabelTypeRuleListTableProps) => {
  const { t } = useTranslation();

  const { labelTypeRules } = props;

  if (!labelTypeRules) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (labelTypeRules.length == 0) {
    return <TableEmpty message={t(tokens.automator.ltr.no_items)} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t(tokens.automator.ltr.title)}</TableCell>
              <TableCell>{t(tokens.common.netherlands)}</TableCell>
              <TableCell>{t(tokens.common.belgium)}</TableCell>
              <TableCell>{t(tokens.automator.ltr.more_than)}</TableCell>
              <TableCell>{t(tokens.automator.ltr.retailers)}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {labelTypeRules!.map((labelTypeRule) => {
              return (
                <LabelTypeRuleTableRow
                  key={labelTypeRule.id}
                  labelTypeRule={labelTypeRule}
                />
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
