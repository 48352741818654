import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import EmailRuleForm from '../components/EmailRuleForm';
import { DistributionParty } from '../../../../domain/automator/DistributionParty';
import { CountryCode } from '../../../../domain/automator/CountryCode';
import { RecipientType } from '../../../../domain/automator/RecipientType';
import EmailRuleTrigger from '../../../../domain/automator/EmailRuleTrigger';
import { toast } from 'react-hot-toast';
import { useUpdateEmailRule } from '../../../../api/automator/email-rules/useUpdateEmailRule';
import EmailRule from '../../../../domain/automator/EmailRule';

export const UpdateEmailRuleDialog = () => {
  const id = DialogId.UPDATE_EMAIL_RULE;

  const { isOpen, data } = useIsDialogOpen(id);

  const { mutate: updateEmailRule, isLoading } = useUpdateEmailRule(data?.emailRule.id || 0);

  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  const onUpdate = (
    productIds: number[],
    messageTemplateId: number,
    distributionParty: DistributionParty | null,
    countryCode: CountryCode | null,
    recipientType: RecipientType,
    trigger: EmailRuleTrigger,
    delayHours: number | null,
    executeAtTime: string | null,
    isStartWithCase: boolean
  ) => {
    updateEmailRule(
      {
        product_ids: productIds,
        email_template_id: messageTemplateId,
        distribution_party: distributionParty,
        country_code: countryCode,
        recipient_type: recipientType,
        trigger: trigger,
        execute_at_time: executeAtTime,
        execute_in_hours: delayHours,
        is_start_with_resolution_case: isStartWithCase,
      },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.email_rules.form.email_rule_updated));
          closeDialog();
        },
      }
    );
  };

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.email_rules.form.update_email_rule)}
        >
          <EmailRuleForm
            emailRule={data.emailRule}
            onSave={onUpdate}
            isLoading={isLoading}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateEmailRuleDialogData {
  emailRule: EmailRule;
}
