import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { EmailTaskStatus } from '../../../../domain/automator/EmailTaskStatus';

interface EmailTaskStatusChipProps {
  emailTaskStatus: EmailTaskStatus;
}

const EmailTaskStatusChip = ({ emailTaskStatus }: EmailTaskStatusChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (emailTaskStatus: EmailTaskStatus): string => {
    switch (emailTaskStatus) {
      case EmailTaskStatus.SENT:
        return tokens.common.email_status.sent;
      case EmailTaskStatus.CANCELLED:
        return tokens.common.email_status.cancelled;
      case EmailTaskStatus.PENDING:
        return tokens.common.email_status.pending;
      default:
        return '';
    }
  };

  const determineColor = (emailTaskStatus: EmailTaskStatus) => {
    switch (emailTaskStatus) {
      case EmailTaskStatus.SENT:
        return 'success';
      case EmailTaskStatus.CANCELLED:
        return 'warning';
      case EmailTaskStatus.PENDING:
        return 'info';
      default:
        return 'error';
    }
  };

  return (
    <Chip
      style={{ borderRadius: 4 }}
      size="small"
      label={t(determineLabel(emailTaskStatus))}
      variant="filled"
      color={determineColor(emailTaskStatus)}
    />
  );
};

export default EmailTaskStatusChip;
