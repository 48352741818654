import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Retailer from '../../../domain/automator/Retailer';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { RETAILERS_PATH } from '../automatorApiPaths';
import Address from '../../../domain/automator/Address';

export const useUpdateRetailerAddress = (retailerId: number) => {
  const url = useAutomatorApiAccountUrl(RETAILERS_PATH + '/' + retailerId + `/address`);
  return useAuthorizedPost<Retailer, useUpdateRetailerAddressData>(url, [
    EndpointQueryKey.RETAILERS,
  ]);
};

export interface useUpdateRetailerAddressData {
  address: Address;
}
