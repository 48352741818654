import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { tokens } from '../../locales/translationTokens';
import { TutorialAlert } from './TutorialAlert';
import { useFetchRetailers } from '../../api/automator/retailers/useFetchRetailers';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useOpenDialog } from '../../store/dialogs/useOpenDialog';
import { DialogId } from '../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../store/dialogs/useIsDialogOpen';
import { TutorialKey, TutorialService } from './TutorialService';

export const RetailersTutorial = () => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.FIRST_RETAILER_CREATED);
  const { isOpen } = useIsDialogOpen(DialogId.FIRST_RETAILER_CREATED);

  const { data: retailers } = useFetchRetailers();

  useEffect(() => {
    const isUsedRetailersWithoutRetailer = TutorialService.isUsed(
      TutorialKey.RETAILERS_WITHOUT_RETAILER
    );

    if (retailers && retailers.length > 0 && !isUsedRetailersWithoutRetailer) {
      TutorialService.setToUsed(TutorialKey.ORDERS_WITHOUT_RETAILER);
      TutorialService.setToUsed(TutorialKey.RETAILERS_WITHOUT_RETAILER);
    }

    const isUsedRetailersWithRetailer = TutorialService.isUsed(TutorialKey.RETAILERS_WITH_RETAILER);

    if (!isOpen && !isUsedRetailersWithRetailer && retailers && retailers.length > 0) {
      openDialog();
      TutorialService.setToUsed(TutorialKey.RETAILERS_WITH_RETAILER);
    }
  }, [retailers]);

  return (
    <TutorialAlert tutorialKey={TutorialKey.RETAILERS_WITHOUT_RETAILER}>
      <Stack spacing={3}>
        <Box>{t(tokens.automator.retailers.tutorials.without_retailer_message)}</Box>
        <Button
          sx={{ width: '300px' }}
          color="primary"
          size="small"
          variant="contained"
          href="https://partner.bol.com/sdd/preferences/services/api"
          target="_blank"
        >
          {t(tokens.automator.retailers.tutorials.without_retailer_button)}
        </Button>
      </Stack>
    </TutorialAlert>
  );
};
