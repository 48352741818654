import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import { ReturnRecoverability } from '../../../../domain/automator/ReturnRecoverability';
import { useCreateMessageTemplate } from '../../../../api/automator/emails/useCreateMessageTemplate';
import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { Stack } from '@mui/material';
import FileUpload from '../../shared/FileUpload';
import Box from '@mui/material/Box';
import HtmlEditor from '../../email/components/HtmlEditor';
import { EmailPhaseToggleButtonGroup } from './EmailPhaseToggleButtonGroup';
import { MessageTemplateType } from '../../../../domain/automator/MessageTemplateType';
import { EmailVariableSelection } from '../../shipments/components/EmailVariableSelection';
import MessageTemplate from '../../../../domain/automator/MessageTemplate';
import FileUploadForm from './FileUploadForm';

interface CreateMessageTemplateFormProps {
  onCreate?: (messageTemplate: MessageTemplate) => void;
  phase: ThreadPhase | null;
  type: MessageTemplateType;
  recoverability: ReturnRecoverability | null;
}

export const CreateMessageTemplateForm = ({
  onCreate,
  phase: initialPhase,
  recoverability,
  type,
}: CreateMessageTemplateFormProps) => {
  const [name, setName] = useState('');
  const [phase, setPhase] = useState<ThreadPhase>(initialPhase || ThreadPhase.ORDER);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);
  const [insertVariable, setInsertVariable] = useState<string | null>(null);

  const { t } = useTranslation();

  const { mutate: createMessageTemplate, isLoading } = useCreateMessageTemplate();

  const handleCreate = async () => {
    createMessageTemplate(
      {
        phase: phase,
        subject: subject,
        body: body,
        name: name,
        return_recoverability: recoverability,
        files: fileUploads,
        type: type,
      },
      {
        onSuccess: async (messageTemplate) => {
          onCreate?.(messageTemplate);
          toast.success(
            t(tokens.automator.resolutions.dialogs.create_email_template.email_template_created)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="row"
        gap={2}
      >
        <Stack
          direction="column"
          minWidth="70%"
        >
          <TextField
            id="name-field"
            label={t(tokens.automator.resolutions.dialogs.create_email_template.name)}
            variant="filled"
            fullWidth
            style={{ marginBottom: '1em' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Box paddingBottom={2}>
            <EmailPhaseToggleButtonGroup
              onSelect={setPhase}
              value={phase}
              selectablePhases={[ThreadPhase.ORDER, ThreadPhase.SHIPMENT, ThreadPhase.RETURN]}
            />
          </Box>

          <TextField
            id="subject-field"
            label={t(tokens.automator.resolutions.dialogs.create_email_template.subject)}
            variant="filled"
            fullWidth
            style={{ marginBottom: '1em' }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <HtmlEditor
            content={body}
            onChange={setBody}
            insertVariable={insertVariable}
          />

          <Box
            marginLeft={-1}
            paddingY={1}
          >
            <FileUploadForm
              onChange={(fileUploads: FileUpload[]) => setFileUploads(fileUploads)}
              multiple
            />
          </Box>

          <ActionButton
            icon={<Email />}
            label={t(
              tokens.automator.resolutions.dialogs.create_email_template.create_email_template
            )}
            onClick={() => handleCreate()}
            isLoading={isLoading}
            variant="contained"
            color="primary"
            size="small"
          />
        </Stack>

        <EmailVariableSelection onSelectVariable={setInsertVariable} />
      </Stack>
    </form>
  );
};
