import { LatestDays } from './LatestDaysDropdownSelect';
import { CountryCode } from '../../../../../domain/automator/CountryCode';
import Offer from '../../../../../domain/pd/Offer';
import Stack from '@mui/material/Stack';
import { OfferPerformancesChart } from './charts/OfferPerformancesChart';
import { ProductAndOfferActionButtons } from './ProductAndOfferActionButtons';
import { OfferPerformancesListTable } from './tables/OfferPerformancesListTable';
import { OfferPerformancesTotalsOverview } from './OfferPerformancesTotalsOverview';
import { UpdatePerformanceNoteForm } from './forms/UpdatePerformanceNoteForm';
import { useFetchPerformanceNotes } from '../../../../../api/pd/notes/useFetchPerformanceNotes';
import Box from '@mui/material/Box';
import { PerformanceNoteType } from '../../../../../domain/pd/PerformanceNoteType';

interface OfferPerformancesTabProps {
  offer: Offer;
  latestDays: LatestDays;
  rollingAverageDays: number;
  countryCode: CountryCode | null;
}

export const OfferPerformancesTab = ({
  offer,
  latestDays,
  rollingAverageDays,
  countryCode,
}: OfferPerformancesTabProps) => {
  const { data: performanceNotes, isLoading } = useFetchPerformanceNotes(offer.productId);

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Stack direction="column">
      <ProductAndOfferActionButtons offer={offer} />

      <OfferPerformancesTotalsOverview
        offer={offer}
        countryCode={countryCode}
        latestDays={latestDays}
        rollingAverageDays={rollingAverageDays}
      />

      <OfferPerformancesChart
        offer={offer}
        countryCode={countryCode}
        latestDays={latestDays}
        rollingAverageDays={rollingAverageDays}
      />

      <UpdatePerformanceNoteForm
        performanceNote={
          performanceNotes!.performanceNotes.find(
            (note) => note.type === PerformanceNoteType.OFFER_PERFORMANCES
          )!
        }
      />

      <OfferPerformancesListTable
        offer={offer}
        latestDays={latestDays}
        rollingAverageDays={rollingAverageDays}
        countryCode={countryCode}
      />
    </Stack>
  );
};
