import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Redo } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { useCreateResend } from '../../../../api/automator/resolutions/useCreateResend';
import * as React from 'react';

interface CreateResendFormProps {
  onClick?: () => void;
  orderId: number;
}

export const CreateResendForm = ({ onClick, orderId }: CreateResendFormProps) => {
  const { t } = useTranslation();

  const { mutate: create, isLoading } = useCreateResend();

  const onCreate = () => {
    create(
      {
        order_id: orderId,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.resolutions.dialogs.create_resend.resend_created));
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <ActionButton
        icon={<Redo />}
        label={t(tokens.automator.resolutions.dialogs.create_resend.create_resend)}
        onClick={() => onCreate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};
