import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { LabelGeneration } from '../../../../domain/automator/LabelGeneration';

interface LabelGenerationDropdownProps {
  selected: LabelGeneration;
  onSelect: (labelGeneration: LabelGeneration) => void;
}

export const LabelGenerationDropdown = ({ onSelect, selected }: LabelGenerationDropdownProps) => {
  const { t } = useTranslation();

  const labelGenerationOptions = [
    {
      key: LabelGeneration.BUY_FROM_BOL,
      value: t(tokens.automator.pick_sessions.label_generation.buy_from_bol),
    },
    {
      key: LabelGeneration.MANUAL,
      value: t(tokens.automator.pick_sessions.label_generation.manual),
    },
  ];

  return (
    <TextField
      label={t(tokens.automator.pick_sessions.label_generation.label_generation)}
      select
      value={selected}
      SelectProps={{ native: true }}
      onChange={(e) => onSelect(e.target.value as LabelGeneration)}
    >
      {labelGenerationOptions.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {option.value}
        </option>
      ))}
    </TextField>
  );
};
