import { tokens } from '../../../../locales/translationTokens';
import { DropdownSelect } from '../../shared/DropdownSelect';
import EmailRuleTrigger from '../../../../domain/automator/EmailRuleTrigger';
import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PhaseTypography } from './PhaseTypography';

interface EmailRuleTriggerDropdownProps {
  trigger: EmailRuleTrigger;
  onSelect: (trigger: EmailRuleTrigger) => void;
}

export const EmailRuleTriggerDropdown = ({ trigger, onSelect }: EmailRuleTriggerDropdownProps) => {
  const { t } = useTranslation();

  const createValue = (phase: ThreadPhase, token: string) => {
    return (
      <Stack
        direction={'row'}
        gap={1}
      >
        <PhaseTypography phase={phase} />
        <Typography>{t(token)}</Typography>
      </Stack>
    );
  };

  const options = [
    {
      key: EmailRuleTrigger.NEW_ORDER,
      value: createValue(ThreadPhase.ORDER, tokens.automator.email_rules.triggers.new_order),
    },
    {
      key: EmailRuleTrigger.CUSTOMER_IS_RECURRING,
      value: createValue(
        ThreadPhase.ORDER,
        tokens.automator.email_rules.triggers.customer_is_recurring
      ),
    },
    {
      key: EmailRuleTrigger.ORDER_IS_PLACED_DOUBLE,
      value: createValue(
        ThreadPhase.ORDER,
        tokens.automator.email_rules.triggers.order_is_placed_double
      ),
    },
    {
      key: EmailRuleTrigger.SHIPMENT_SHIPPED,
      value: createValue(ThreadPhase.ORDER, tokens.automator.email_rules.triggers.shipment_send),
    },
    {
      key: EmailRuleTrigger.EXPECTED_DELIVERY_DATE_REACHED,
      value: createValue(
        ThreadPhase.ORDER,
        tokens.automator.email_rules.triggers.expected_delivery_date_reached
      ),
    },
    {
      key: EmailRuleTrigger.SHIPMENT_TRACK_AND_TRACE_UPLOADED,
      value: createValue(
        ThreadPhase.SHIPMENT,
        tokens.automator.email_rules.triggers.shipment_track_and_trace_uploaded
      ),
    },
    {
      key: EmailRuleTrigger.SHIPMENT_DELIVERED,
      value: createValue(
        ThreadPhase.SHIPMENT,
        tokens.automator.email_rules.triggers.shipment_delivered
      ),
    },
    {
      key: EmailRuleTrigger.SHIPMENT_DELIVERED_AT_PICKUP_POINT,
      value: createValue(
        ThreadPhase.SHIPMENT,
        tokens.automator.email_rules.triggers.shipment_at_pickup_point
      ),
    },
    {
      key: EmailRuleTrigger.SHIPMENT_RETURNED_TO_SENDER,
      value: createValue(
        ThreadPhase.SHIPMENT,
        tokens.automator.email_rules.triggers.shipment_returned_to_sender
      ),
    },

    {
      key: EmailRuleTrigger.RETURN_HANDLED,
      value: createValue(
        ThreadPhase.SHIPMENT,
        tokens.automator.email_rules.triggers.return_handled
      ),
    },
    {
      key: EmailRuleTrigger.RETURN_REGISTERED,
      value: createValue(
        ThreadPhase.RETURN,
        tokens.automator.email_rules.triggers.return_registered
      ),
    },
    {
      key: EmailRuleTrigger.RECOVERABLE_RETURN_ITEM_REGISTERED,
      value: createValue(
        ThreadPhase.RETURN,
        tokens.automator.email_rules.triggers.recoverable_return_item_registered
      ),
    },
    {
      key: EmailRuleTrigger.RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED,
      value: createValue(
        ThreadPhase.RETURN,
        tokens.automator.email_rules.triggers.recoverability_unclear_return_item_registered
      ),
    },
    {
      key: EmailRuleTrigger.NOT_RECOVERABLE_RETURN_ITEM_REGISTERED,
      value: createValue(
        ThreadPhase.RETURN,
        tokens.automator.email_rules.triggers.not_recoverable_return_item_registered
      ),
    },
  ];

  return (
    <DropdownSelect
      options={options}
      onSelect={onSelect}
      selected={trigger}
      label={tokens.automator.email_rules.triggers.trigger}
    />
  );
};
