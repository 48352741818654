import { EMAIL_THREADS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Orders, { AGGREGATE_KEY as OrderAggregateKey } from '../../../domain/automator/Orders';
import Returns, { AGGREGATE_KEY as ReturnAggregateKey } from '../../../domain/automator/Returns';
import Shipments, {
  AGGREGATE_KEY as ShipmentAggregateKey,
} from '../../../domain/automator/Shipments';

import { useOrderQueryKeysUpdater } from '../orders/useOrderQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';
import { RecipientType } from '../../../domain/automator/RecipientType';
import { ThreadPhase } from '../../../domain/automator/ThreadPhase';
import CreateEmailThreadsResponse from '../../../domain/automator/CreateEmailThreadsResponse';
import { useShipmentQueryKeysUpdater } from '../shipments/useShipmentsQueryKeysUpdater';
import { useReturnsQueryKeysUpdater } from '../returns/useReturnsQueryKeysUpdater';
import { OrderSummaryQueryKey } from '../../queryKeys/OrderSummaryQueryKey';
import FileUpload from '../../../pages/automator/shared/FileUpload';

export const useCreateEmailThreads = (phase: ThreadPhase) => {
  const url = useAutomatorApiAccountUrl(EMAIL_THREADS_PATH);

  const getAggregateKey = (phase: ThreadPhase) => {
    switch (phase) {
      case ThreadPhase.RETURN:
        return ReturnAggregateKey;
      case ThreadPhase.SHIPMENT:
        return ShipmentAggregateKey;
      case ThreadPhase.ORDER:
        return OrderAggregateKey;
      default:
        throw new Error('Invalid phase');
    }
  };

  const orderUpdater = useOrderQueryKeysUpdater();
  const shipmentUpdate = useShipmentQueryKeysUpdater();
  const returnUpdater = useReturnsQueryKeysUpdater();

  const getUpdater = (phase: ThreadPhase) => {
    switch (phase) {
      case ThreadPhase.ORDER:
        return orderUpdater;
      case ThreadPhase.SHIPMENT:
        return shipmentUpdate;
      case ThreadPhase.RETURN:
        return returnUpdater;
      default:
        throw new Error('Invalid phase');
    }
  };

  //@ts-ignore
  const updateData = useUpdateData(getUpdater(phase), getAggregateKey(phase));

  const onSuccess = (emailTasks: CreateEmailThreadsResponse) => {
    const getAggregates = (emailTasks: CreateEmailThreadsResponse, phase: ThreadPhase) => {
      switch (phase) {
        case ThreadPhase.ORDER:
          return { orders: emailTasks.responses.map((emailTask) => emailTask.order!) } as Orders;
        case ThreadPhase.SHIPMENT:
          return {
            shipments: emailTasks.responses.map((emailTask) => emailTask.shipment!),
          } as Shipments;
        case ThreadPhase.RETURN:
          return {
            returns: emailTasks.responses.map((emailTask) => emailTask.return!),
          } as Returns;
        default:
          throw new Error('Invalid phase');
      }
    };

    // @ts-ignore
    updateData(getAggregates(emailTasks, phase));
  };

  return useAuthorizedPost<CreateEmailThreadsResponse, CreateEmailThreadData[]>(
    url,
    [OrderSummaryQueryKey(undefined)],
    undefined,
    onSuccess
  );
};

export interface CreateEmailThreadData {
  order_item_id?: number;
  shipment_id?: number;
  return_item_id?: number;
  email_template_id?: number;
  subject: string;
  body: string;
  recipient_type: RecipientType;
  phase: ThreadPhase;
  is_start_with_resolution_case: boolean;
  files: FileUpload[];
}
