import { RETURNS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/Returns';
import { HandlingResult } from '../../../domain/automator/HandlingResult';

export const useCreateReturn = () => {
  const url = useAutomatorApiAccountUrl(RETURNS_PATH);

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns, CreateReturnData>(url, [], undefined, onSuccess);
};

export interface CreateReturnData {
  retailer_id: number;
  bol_order_id: string;
  bol_order_item_id: string;
  quantity_returned: number;
  handling_result: HandlingResult;
}
