import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import Order from '../../../../domain/automator/Order';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import ChangeOrdersLatestHandoverDateForm from '../components/ChangeOrdersLatestHandoverDateForm';
import { tokens } from '../../../../locales/translationTokens';

export const ChangeOrderLatestHandoverDateDialog = () => {
  const id = DialogId.CHANGE_ORDER_HANDOVER_DATE;
  const { isOpen, data } = useIsDialogOpen(id);
  const { t } = useTranslation();
  const closeDialog = useCloseDialog(id);

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.orders.latest_handover_date.latest_handover_date)}
        >
          <ChangeOrdersLatestHandoverDateForm
            onClick={closeDialog}
            orders={data.orders}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface ChangeOrderLatestHandoverDateDialogData {
  orders: Order[];
}
