import { useTranslation } from 'react-i18next';
import { tokens } from '../../locales/translationTokens';
import { TutorialAlert } from './TutorialAlert';
import Box from '@mui/material/Box';
import { TutorialKey } from './TutorialService';

export const LabelTypeRulesTutorial = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <TutorialAlert tutorialKey={TutorialKey.LABEL_TYPE_RULES}>
        <Box>{t(tokens.automator.ltr.tutorials.message)}</Box>
      </TutorialAlert>
    </Box>
  );
};
