import { RESOLUTION_CASES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import ResolutionCase from '../../../domain/automator/ResolutionCase';
import { ResolutionCaseQueryKey } from './ResolutionCaseQueryKey';

export const useFetchResolutionCase = (id: number) => {
  const url = useAutomatorApiAccountUrl(RESOLUTION_CASES_PATH + '/' + id);
  return useFetchData<ResolutionCase>(url, ResolutionCaseQueryKey(id));
};
