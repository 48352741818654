import Typography from '@mui/material/Typography';
import { truncateString } from '../../../../helpers/truncateString';
import Stack from '@mui/material/Stack';
import { tss } from 'tss-react/mui-compat';
import OrderItem from '../../../../domain/automator/OrderItem';
import { ActionIcon } from './ActionIcon';
import { Launch } from '@mui/icons-material';
import { tokens } from '../../../../locales/translationTokens';
import { ProductThumbnail } from './ProductThumbnail';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';

interface OrderItemProductInfoProps {
  orderItem: OrderItem;
}

export const OrderItemProductInfo = ({ orderItem }: OrderItemProductInfoProps) => {
  const openDialog = useOpenDialog(DialogId.UPDATE_OFFER_INTERNAL_REFERENCE);

  const { t } = useTranslation();

  const { cx, classes } = useStyles();

  return (
    <Stack className={cx(classes.productInfo)}>
      <Typography
        width={20}
        variant="h6"
      >
        {orderItem.quantity}x
      </Typography>

      <Stack
        direction="row"
        gap={0.7}
        justifyContent="space-between"
      >
        <ProductThumbnail
          height={60}
          thumbnailUrl={orderItem.thumbnailUrl}
        />

        <Stack
          direction="column"
          justifyContent="center"
          gap={0.2}
        >
          <Typography
            noWrap
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            variant="body1"
          >
            {truncateString(orderItem.title, 33)}
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            gap={0.7}
          >
            <Typography variant="body2">{orderItem.ean}</Typography>
            <Typography variant="body2"> | </Typography>
            <Typography
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() =>
                openDialog({
                  orderItemId: orderItem.id,
                  internalReference: orderItem.internalReference ? orderItem.internalReference : '',
                })
              }
            >
              {orderItem.internalReference
                ? orderItem.internalReference
                : t(
                    tokens.automator.orders.dialogs.update_internal_reference.add_internal_reference
                  )}
            </Typography>
            <ActionIcon
              onClick={() => window.open(orderItem.productPageUrl, '_blank')}
              tooltip={tokens.automator.orders.open_product_page}
              iconSize={20}
              icon={<Launch />}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const useStyles = tss.create(() => ({
  productInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '5px',
    paddingLeft: '12px',
  },
}));
