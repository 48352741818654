import { RESENDS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ResendsQueryKey } from './ResendsQueryKey';
import { ResendStatus } from '../../../domain/automator/ResendStatus';
import Resends from '../../../domain/automator/Resends';
import { ResolutionDossiersQueryKey } from './ResolutionDossiersQueryKey';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';

export const useCreateResend = () => {
  const url = useAutomatorApiAccountUrl(RESENDS_PATH);
  return useAuthorizedPost<Resends, CreateResendData>(
    url,
    [
      ResendsQueryKey(ResendStatus.HANDLED),
      ResendsQueryKey(ResendStatus.OPEN),
      ResolutionDossiersQueryKey(undefined),
      [EndpointQueryKey.ACCOUNT],
    ],
    undefined
  );
};

interface CreateResendData {
  order_id: number;
}
