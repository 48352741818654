import { ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { LabelType } from '../../../../domain/automator/LabelType';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

interface LabelTypeToggleButtonProps {
  id: number;
  value: LabelType;
  isDisabled?: boolean;
  onChange: (id: number, labelType: LabelType) => void;
}

export const LabelTypeToggleButton = (props: LabelTypeToggleButtonProps) => {
  const { t } = useTranslation();
  const { id, value, isDisabled, onChange } = props;

  const handleChange = (_: any, newValue: LabelType) => {
    if (newValue !== null) {
      onChange(id, newValue);
    }
  };

  return (
    <ToggleButtonGroup
      size={'small'}
      disabled={isDisabled}
      orientation="vertical"
      color="primary"
      value={value == LabelType.BOL_RECOMMENDED ? LabelType.PARCEL : value}
      exclusive
      onChange={handleChange}
      aria-label="LabelType"
      sx={{ '& .MuiToggleButton-root': { height: '25px', padding: '0 5px', fontSize: '12px' } }}
    >
      <ToggleButton
        color={!isDisabled ? 'success' : 'standard'}
        value={LabelType.MAILBOX}
      >
        {t(tokens.automator.label_type.mailbox)}
      </ToggleButton>
      <ToggleButton
        color={!isDisabled ? 'warning' : 'standard'}
        value={LabelType.PARCEL}
      >
        {t(tokens.automator.label_type.parcel)}
      </ToggleButton>
      {/*<ToggleButton*/}
      {/*  color={!isDisabled ? 'primary' : 'standard'}*/}
      {/*  value={LabelType.BOL_RECOMMENDED}*/}
      {/*>*/}
      {/*  {t(tokens.automator.label_type.bol_recommended)}*/}
      {/*</ToggleButton>*/}
    </ToggleButtonGroup>
  );
};
