import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import PickSession from '../../../../domain/automator/PickSession';
import { Download01, Download02, Download03 } from '@untitled-ui/icons-react';
import { ActionIcon } from '../../orders/components/ActionIcon';

interface PickSessionTableRowProps {
  pickSession: PickSession;
}

export const PickSessionDownloads = ({ pickSession }: PickSessionTableRowProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <ActionIcon
        icon={<Download01 />}
        tooltip={t(tokens.automator.pick_sessions.buttons.download_pick_list)}
        tooltipDisabled={t(
          tokens.automator.pick_sessions.buttons.download_enriched_labels_disabled
        )}
        disabled={pickSession.downloadPickListUrl == null}
        href={pickSession.downloadPickListUrl ? pickSession.downloadPickListUrl : '#'}
      />
      <ActionIcon
        icon={<Download02 />}
        tooltip={t(tokens.automator.pick_sessions.buttons.download_enriched_labels)}
        tooltipDisabled={t(
          tokens.automator.pick_sessions.buttons.download_enriched_labels_disabled
        )}
        disabled={pickSession.downloadEnrichedLabelsUrl == null}
        href={pickSession.downloadEnrichedLabelsUrl ? pickSession.downloadEnrichedLabelsUrl : '#'}
      />
      <ActionIcon
        icon={<Download03 />}
        tooltip={t(tokens.automator.pick_sessions.buttons.download_clean_labels)}
        tooltipDisabled={t(tokens.automator.pick_sessions.buttons.download_clean_labels_disabled)}
        disabled={pickSession.downloadCleanLabelsUrl == null}
        href={pickSession.downloadCleanLabelsUrl ? pickSession.downloadCleanLabelsUrl : '#'}
      />
    </Box>
  );
};
