import { MESSAGE_TEMPLATES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { MessageTemplatesQueryKey } from '../../queryKeys/MessageTemplatesQueryKey';
import MessageTemplate from '../../../domain/automator/MessageTemplate';
import { EmailRulesQueryKey } from '../../queryKeys/EmailRulesQueryKey';

export const useUpdateMessageTemplate = (messageTemplate: MessageTemplate) => {
  const url = useAutomatorApiAccountUrl(MESSAGE_TEMPLATES_PATH + '/' + messageTemplate.id);

  return useAuthorizedPost<MessageTemplate, UpdateMessageTemplateData>(
    url,
    [
      MessageTemplatesQueryKey(
        messageTemplate.type,
        messageTemplate.phase,
        messageTemplate.returnRecoverability
      ),
      EmailRulesQueryKey(),
    ],
    undefined
  );
};

export interface UpdateMessageTemplateData {
  name: string;
  subject: string;
  body: string;
}
