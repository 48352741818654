import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReturnRecoverabilityDropdown from './ReturnRecoverabilityDropdown';
import ReturnItem from '../../../../domain/automator/ReturnItem';
import useTranslateDetailedReason from '../translations/useTranslateDetailedReason';

interface ReturnReasonStackProps {
  returnItem: ReturnItem;
}

export const ReturnReasonStack = ({ returnItem }: ReturnReasonStackProps) => {
  const detailedReason = useTranslateDetailedReason(returnItem.returnReason?.detailedReason);

  return (
    <Stack
      gap={0.5}
      paddingTop={1}
      direction="row"
      alignItems="center"
    >
      <ReturnRecoverabilityDropdown returnItem={returnItem} />

      <Typography
        sx={{ paddingLeft: 0.5 }}
        variant="caption"
      >
        {detailedReason}
      </Typography>

      {returnItem.returnReason?.customerComments && <Typography>|</Typography>}

      <Typography variant="caption">{returnItem.returnReason?.customerComments}</Typography>
    </Stack>
  );
};
