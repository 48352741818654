import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { LabelGeneration } from '../../../../domain/automator/LabelGeneration';

interface LabelGenerationChipProps {
  labelGeneration: LabelGeneration;
}

const LabelGenerationChip = ({ labelGeneration }: LabelGenerationChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (labelGeneration: LabelGeneration) => {
    switch (labelGeneration) {
      case LabelGeneration.VVB:
        return tokens.automator.pick_sessions.label_generation.vvb;
      case LabelGeneration.MANUAL:
        return tokens.automator.pick_sessions.label_generation.manual;
      case LabelGeneration.BUY_FROM_BOL:
        return tokens.automator.pick_sessions.label_generation.buy_from_bol;
    }
  };

  const determineColor = (labelGeneration: LabelGeneration) => {
    switch (labelGeneration) {
      case LabelGeneration.VVB:
        return 'secondary';
      case LabelGeneration.MANUAL:
        return 'primary';
      case LabelGeneration.BUY_FROM_BOL:
        return 'info';
    }
  };

  return (
    <Chip
      size="small"
      label={t(determineLabel(labelGeneration))}
      color={determineColor(labelGeneration)}
      variant="filled"
    />
  );
};

export default LabelGenerationChip;
