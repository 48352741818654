import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EmailRule from '../../../../domain/automator/EmailRule';
import Stack from '@mui/material/Stack';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import { DistributionParty } from '../../../../domain/automator/DistributionParty';
import { FlagIcon } from '../../orders/components/FlagIcon';
import { CountryCode } from '../../../../domain/automator/CountryCode';
import RecipientTypeChip from '../../resolutions/components/RecipientTypeChip';
import EmailRuleTriggerChip from '../../resolutions/components/EmailRuleTriggerChip';
import { useEnableEmailRule } from '../../../../api/automator/email-rules/useEnableEmailRule';
import { useDisableEmailRule } from '../../../../api/automator/email-rules/useDisableEmailRule';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import Typography from '@mui/material/Typography';
import { Switch } from '@mui/material';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';

interface EmailRuleTableRowProps {
  emailRule: EmailRule;
}

export const EmailRuleTableRow = ({ emailRule }: EmailRuleTableRowProps) => {
  const { mutate: enable } = useEnableEmailRule(emailRule.id);
  const { mutate: disable } = useDisableEmailRule(emailRule.id);

  const openUpdateMessageTemplateDialog = useOpenDialog(DialogId.UPDATE_MESSAGE_TEMPLATE);

  const openUpdateEmailRule = useOpenDialog(DialogId.UPDATE_EMAIL_RULE);

  const { t } = useTranslation();

  const handleEnable = () => {
    enable(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.email_rules.enabled));
      },
    });
  };

  const handleDisable = () => {
    disable(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.email_rules.disabled));
      },
    });
  };

  const renderDelay = (emailRule: EmailRule) => {
    if (emailRule.executeAtTime) {
      return emailRule.executeAtTime;
    } else if (emailRule.executeInHours) {
      if (emailRule.executeInHours % 24 === 0) {
        return `${emailRule.executeInHours / 24} ${t(
          tokens.automator.email_rules.delay_type.days
        )}`;
      } else {
        return `${emailRule.executeInHours} ${t(tokens.automator.email_rules.delay_type.hours)}`;
      }
    } else {
      return '';
    }
  };

  return (
    <TableRow key={emailRule.id}>
      <TableCell width={150}>
        <Stack>
          <Typography variant="body1"> {emailRule.messageTemplate.name}</Typography>

          <Typography variant="body2">{'SENT: ' + emailRule.amountOfEmailMessagesSent}</Typography>
          <Typography variant="body2">
            {'OPENED: ' + emailRule.amountOfEmailMessagesOpened}
          </Typography>
          <Typography variant="body2">{'REPLIES: ' + emailRule.amountOfReplies}</Typography>

          <Typography variant="body2">{'QUEUED: ' + emailRule.amountOfEmailTasksQueued}</Typography>
        </Stack>
      </TableCell>

      <TableCell width={200}>
        <Stack
          direction="column"
          gap={1}
        >
          <EmailRuleTriggerChip trigger={emailRule.trigger} />
          <RecipientTypeChip recipientType={emailRule.recipientType} />

          {emailRule.distributionParty ? (
            <DistributionPartyChip distributionParty={emailRule.distributionParty} />
          ) : (
            <Stack
              direction="row"
              gap={1}
            >
              <DistributionPartyChip distributionParty={DistributionParty.BOL} />
              <DistributionPartyChip distributionParty={DistributionParty.RETAILER} />
            </Stack>
          )}

          {emailRule.countryCode ? (
            <FlagIcon
              size={30}
              countryCode={emailRule.countryCode}
            />
          ) : (
            <Stack
              direction="row"
              gap={1}
            >
              <FlagIcon
                size={30}
                countryCode={CountryCode.NL}
              />
              <FlagIcon
                size={30}
                countryCode={CountryCode.BE}
              />
            </Stack>
          )}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          flexWrap={'wrap'}
        >
          {emailRule.products.map((product, index) => (
            <TooltippedProductThumbnail
              key={index}
              productTitle={product.title}
              height={50}
              thumbnailUrl={product.thumbnailUrl}
            />
          ))}
        </Stack>
      </TableCell>

      <TableCell>{renderDelay(emailRule)}</TableCell>

      <TableCell>
        <Stack
          direction="column"
          alignItems="center"
          gap={0.5}
        >
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography variant="body1">{t(tokens.automator.email_rules.disabled)}</Typography>
            <Switch
              checked={!emailRule.isDisabled}
              onChange={emailRule.isDisabled ? handleEnable : handleDisable}
              color="primary"
              name="enabled"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="body1">{t(tokens.automator.email_rules.enabled)}</Typography>
          </Stack>

          <ActionButton
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => openUpdateEmailRule({ emailRule: emailRule })}
            label={t(tokens.automator.email_rules.update_email_rule)}
          />

          <ActionButton
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() =>
              openUpdateMessageTemplateDialog({ messageTemplate: emailRule.messageTemplate })
            }
            label={t(tokens.automator.email_rules.update_email_template)}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
