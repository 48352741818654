import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';

import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import { useFetchRetailers } from '../../../../api/automator/retailers/useFetchRetailers';
import { colors, Tooltip } from '@mui/material';

interface RetailerLooksIconProps {
  retailerId: number;
}

const RetailerLooksIcon = ({ retailerId }: RetailerLooksIconProps) => {
  const { data: retailers, isLoading } = useFetchRetailers();

  const index =
    !isLoading &&
    retailers!.findIndex((retailer) => {
      return retailer.id == retailerId;
    }) + 1;

  const determineIcon = (index: number) => {
    const style = {
      backgroundColor: 'WHITE',
      color: colors.lightBlue[500],
    };

    switch (index) {
      case 1:
        return (
          <LooksOneIcon
            style={style}
            fontSize={'small'}
          />
        );
      case 2:
        return (
          <LooksTwoIcon
            style={style}
            fontSize={'small'}
          />
        );
      case 3:
        return (
          <Looks3Icon
            style={style}
            fontSize={'small'}
          />
        );
      case 4:
        return (
          <Looks4Icon
            style={style}
            fontSize={'small'}
          />
        );
      case 5:
        return (
          <Looks5Icon
            style={style}
            fontSize={'small'}
          />
        );
      case 6:
        return (
          <Looks6Icon
            style={style}
            fontSize={'small'}
          />
        );
      default:
        return (
          <DeviceUnknownIcon
            style={style}
            fontSize={'small'}
          />
        );
    }
  };

  if (index && retailers) {
    return (
      <Tooltip
        enterDelay={2000}
        enterNextDelay={2000}
        title={retailers[index - 1]?.name}
        placement={'right'}
      >
        {determineIcon(index)}
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

export default RetailerLooksIcon;
