import { RETURN_PROCESSING_RESULTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/Returns';
import { OrderSummaryQueryKey } from '../../queryKeys/OrderSummaryQueryKey';
import { ReturnScansQueryKey } from '../../queryKeys/ReturnScansQueryKey';

export const useStillAcceptReturnProcessingResult = (returnProcessingResultId: number) => {
  const url = useAutomatorApiAccountUrl(
    RETURN_PROCESSING_RESULTS_PATH + '/' + returnProcessingResultId + `/still-accept`
  );

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns, undefined>(
    url,
    [OrderSummaryQueryKey(undefined), ReturnScansQueryKey()],
    undefined,
    onSuccess
  );
};
