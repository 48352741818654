import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReturnItem from '../../../../domain/automator/ReturnItem';
import { useState } from 'react';
import AcceptReturnItemButton from '../../returns/components/AcceptReturnItemButton';
import RejectReturnItemButton from '../../returns/components/RejectReturnItemButton';
import { QuantityDropdown } from '../../returns/components/QuantityDropdown';

interface HandleReturnItemFormProps {
  onHandle?: () => void;
  returnItem: ReturnItem;
  type: 'ACCEPT' | 'REJECT';
}

export const HandleReturnItemForm = ({ returnItem, type, onHandle }: HandleReturnItemFormProps) => {
  const [quantity, setQuantity] = useState(returnItem.quantityUnprocessed);

  const { t } = useTranslation();

  return (
    <>
      <Stack spacing={3}>
        <Typography>
          {t(tokens.automator.returns.dialogs.handle_return_item.handle_return_explanation)}
        </Typography>

        <QuantityDropdown
          selected={quantity}
          onSelect={setQuantity}
          maxQuantity={returnItem.quantityUnprocessed}
        />

        {type === 'ACCEPT' ? (
          <AcceptReturnItemButton
            returnItem={returnItem}
            quantityReturned={quantity}
            onClick={() => onHandle && onHandle()}
          />
        ) : (
          <RejectReturnItemButton
            returnItem={returnItem}
            quantityReturned={quantity}
            onClick={() => onHandle && onHandle()}
          />
        )}
      </Stack>
    </>
  );
};
