import ShipmentDayReport from '../../../../../domain/automator/ShipmentDayReport';
import Box from '@mui/material/Box';
import StepperPhase from './StepperPhase';
import { tokens } from '../../../../../locales/translationTokens';
import {
  ArrowCircleRight,
  ArrowCircleRightOutlined,
  Error,
  HomeWork,
  HomeWorkOutlined,
  LocalShipping,
  LocalShippingOutlined,
  Store,
  StoreOutlined,
  ThumbUp,
  ThumbUpOutlined,
  Warehouse,
  WarehouseOutlined,
} from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import StepperStart from './StepperStart';
import ShipmentStepperTotals from './ShipmentStepperTotals';

interface ShipmentStepperProps {
  shipmentDayReport?: ShipmentDayReport;
}

const ShipmentStepper = ({ shipmentDayReport }: ShipmentStepperProps) => {
  const lineLength = 15;

  return (
    <Box
      display="flex"
      gap={1}
      alignItems="center"
    >
      <StepperStart
        total={shipmentDayReport?.totalPreAnnounced}
        color={'#6363a8'}
        lineLength={25}
        tooltip={tokens.automator.shipments.total_pre_announced}
        activeIcon={<HomeWork />}
        inactiveIcon={<HomeWorkOutlined />}
      />

      <StepperPhase
        total={shipmentDayReport?.totalInboundCollect}
        delta={shipmentDayReport?.totalInboundCollectDelta}
        color={'#6363a8'}
        lineLength={lineLength}
        tooltip={tokens.automator.shipments.total_inbound_collect}
        activeIcon={<ArrowCircleRight />}
        inactiveIcon={<ArrowCircleRightOutlined />}
      />

      <StepperPhase
        total={shipmentDayReport?.totalAtTransporter}
        delta={shipmentDayReport?.totalAtTransporterDelta}
        color={'#6363a8'}
        lineLength={lineLength}
        tooltip={tokens.automator.shipments.total_at_transporter}
        activeIcon={<Warehouse />}
        inactiveIcon={<WarehouseOutlined />}
      />

      <StepperPhase
        total={shipmentDayReport?.totalInTransit}
        delta={shipmentDayReport?.totalInTransitDelta}
        color={'#6363a8'}
        lineLength={lineLength}
        tooltip={tokens.automator.shipments.total_in_transit}
        activeIcon={<LocalShipping />}
        inactiveIcon={<LocalShippingOutlined />}
      />

      <Stack
        gap={1.6}
        direction="row"
      >
        <StepperPhase
          total={shipmentDayReport?.totalAtPickupPoint}
          delta={shipmentDayReport?.totalAtPickupPointDelta}
          color={'ORANGE'}
          lineLength={lineLength}
          tooltip={tokens.automator.shipments.total_at_pickup_point}
          activeIcon={<Store />}
          inactiveIcon={<StoreOutlined />}
        />

        <Box sx={{ width: '1px', bgcolor: 'ORANGE', height: '30px' }} />
      </Stack>

      <Stack
        gap={0.5}
        direction="column"
      >
        <StepperPhase
          total={shipmentDayReport?.totalReturnedToSender}
          delta={shipmentDayReport?.totalReturnedToSenderDelta}
          color={'RED'}
          lineLength={lineLength}
          tooltip={tokens.automator.shipments.total_returned_to_sender}
          activeIcon={<Error />}
          inactiveIcon={<ErrorOutlineOutlinedIcon />}
          isTerminated={true}
        />

        <StepperPhase
          total={shipmentDayReport?.totalDelivered}
          delta={shipmentDayReport?.totalDeliveredDelta}
          color={'GREEN'}
          lineLength={lineLength}
          tooltip={tokens.automator.shipments.total_delivered}
          activeIcon={<ThumbUp />}
          inactiveIcon={<ThumbUpOutlined />}
          isTerminated={true}
        />
      </Stack>

      <Divider
        orientation="vertical"
        flexItem
      />

      <ShipmentStepperTotals shipmentDayReport={shipmentDayReport} />
    </Box>
  );
};

export default ShipmentStepper;
