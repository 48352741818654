import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useFetchEmailRules } from '../../../api/automator/email-rules/useFetchEmailRules';
import { EmailRuleListTable } from './components/EmailRuleListTable';
import ActionButton from '../../../components/ActionButton';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';

const EmailRulesPage = () => {
  const { data: emailRules, isLoading } = useFetchEmailRules();

  const openDialog = useOpenDialog(DialogId.CREATE_EMAIL_RULE);

  const { t } = useTranslation();

  return (
    <AccountPage
      buttons={[
        <ActionButton
          key={1}
          label={t(tokens.automator.email_rules.form.create_email_rule)}
          onClick={() => openDialog()}
          isLoading={isLoading}
        />,
      ]}
      title={tokens.automator.email_rules.email_rules}
    >
      <EmailRuleListTable
        emailRules={emailRules?.emailRules}
        isLoading={isLoading}
      />
    </AccountPage>
  );
};

export default EmailRulesPage;
