import { LABEL_TYPE_RULES_PATH } from '../automatorApiPaths';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { LabelType } from '../../../domain/automator/LabelType';
import LabelTypeRule from '../../../domain/automator/LabelTypeRule';
import { useAuthorizedPost } from '../../useAuthorizedPost';

export interface UpdateLabelTypeRulesData {
  label_type_rule_ids: number[];
  max_items_for_mailbox: number | null;
  label_type: LabelType;
}

export const useUpdateLabelTypeRules = () => {
  const url = useAutomatorApiAccountUrl(`${LABEL_TYPE_RULES_PATH}/update`);
  return useAuthorizedPost<LabelTypeRule, UpdateLabelTypeRulesData>(url, [
    EndpointQueryKey.LABEL_TYPE_RULES,
  ]);
};
