import { Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout } from '../layouts/automator/Layout';
import RetailersPage from '../pages/automator/retailers/RetailersPage';
import PricingTablePage from '../pages/automator/pricing/PricingTablePage';
import OrdersPage from '../pages/automator/orders/OrdersPage';
import PickSessionsPage from '../pages/automator/pick-sessions/PickSessionsPage';
import LabelTypeRulesPage from '../pages/automator/label-type-rules/LabelTypeRulesPage';
import ShipmentsPage from '../pages/automator/shipments/ShipmentsPage';
import SettingsPage from '../pages/automator/settings/SettingsPage';
import ReturnsPage from '../pages/automator/returns/ReturnsPage';
import ResolutionDossiersPage from '../pages/automator/resolutions/ResolutionDossiersPage';
import ResendsPage from '../pages/automator/resolutions/ResendsPage';
import { DistributionParty } from '../domain/automator/DistributionParty';
import ReportsPage from '../pages/automator/reports/ReportsPage';
import EmailRulesPage from '../pages/automator/email/EmailRulesPage';
import ReturnScansPage from '../pages/automator/returnScans/ReturnScansPage';

export const automatorRoutes: RouteObject[] = [
  {
    path: '/automator/accounts/:accountId',
    element: (
      <Layout>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout>
    ),
    children: [
      {
        path: 'vvb-orders',
        element: <OrdersPage distributionParty={DistributionParty.BOL} />,
      },
      {
        path: 'non-vvb-orders',
        element: <OrdersPage distributionParty={DistributionParty.RETAILER} />,
      },
      {
        path: 'pick-sessions',
        element: <PickSessionsPage />,
      },
      {
        path: 'label-type-rules',
        element: <LabelTypeRulesPage />,
      },
      {
        path: 'retailers',
        element: <RetailersPage />,
      },
      {
        path: 'shipments',
        element: <ShipmentsPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'pricing',
        element: <PricingTablePage />,
      },
      {
        path: 'returns',
        element: <ReturnsPage />,
      },
      {
        path: 'return-scans',
        element: <ReturnScansPage />,
      },

      {
        path: 'resolution-dossiers',
        element: <ResolutionDossiersPage />,
      },
      {
        path: 'resends',
        element: <ResendsPage />,
      },
      {
        path: 'email-rules',
        element: <EmailRulesPage />,
      },
      {
        path: 'reports',
        element: <ReportsPage />,
      },
    ],
  },
];
