import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import useEmailPhaseTranslations from '../translations/useEmailPhaseTranslations';
import Chip from '@mui/material/Chip';

interface PhaseChipProps {
  phase: ThreadPhase;
}

export const PhaseChip = ({ phase }: PhaseChipProps) => {
  const label = useEmailPhaseTranslations(phase);
  return (
    <Chip
      style={{ borderRadius: 4 }}
      size="small"
      label={label}
    />
  );
};
