import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Offers from '../../../domain/automator/Offers';
import { DeliveryCode } from '../../../domain/pd/DeliveryCode';
import BundlePrice from '../../../domain/automator/BundlePrice';
import { OFFERS_PATH } from '../automatorApiPaths';
import { OffersQueryKey } from '../../queryKeys/OffersQueryKey';

export const useUpdateOffer = (offerId: number, productId: number) => {
  const url = useAutomatorApiAccountUrl(`${OFFERS_PATH}/${offerId}`);
  return useAuthorizedPost<Offers, UpdateOfferData>(url, [OffersQueryKey(productId)]);
};

export interface UpdateOfferData {
  is_on_hold_by_retailer: boolean;
  delivery_code: DeliveryCode;
  stock_amount: number;
  bundle_prices: BundlePrice[];
}
