import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { RetailerTableRow } from './RetailerTableRow';
import Retailer from '../../../../domain/automator/Retailer';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';

interface RetailerListTableProps {
  retailers: Retailer[] | undefined;
}

export const RetailerListTable = (props: RetailerListTableProps) => {
  const { t } = useTranslation();

  const { retailers } = props;

  if (!retailers) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (retailers.length == 0) {
    return <TableEmpty message={t(tokens.automator.retailers.no_items)} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell align="left">{t(tokens.automator.retailers.name)}</TableCell>
              <TableCell align="right">{t(tokens.automator.retailers.status.status)}</TableCell>
              {/*<TableCell align="right">*/}
              {/*  {t(tokens.automator.retailers.forward_email_address)}*/}
              {/*</TableCell>*/}
              <TableCell align="right">{t(tokens.automator.retailers.created_date)}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {retailers!.map((retailer) => {
              return (
                <RetailerTableRow
                  key={retailer.id}
                  retailer={retailer}
                />
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
