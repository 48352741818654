import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ReturnReasonStack } from '../../returns/components/ReturnReasonStack';
import Return from '../../../../domain/automator/Return';
import { OrderSummaryViewHeader } from './OrderSummaryViewHeader';
import ReturnItemButtons from '../../returns/components/ReturnItemButtons';
import AcceptOrRejectReturnItemButtons from '../../returns/components/AcceptOrRejectReturnItemButtons';
import { ReturnProcessingResultListTable } from '../../returns/components/ReturnProcessingResultListTable';
import { ReturnItemTransporterChip } from '../../returns/components/ReturnItemTransporterChip';
import * as React from 'react';
import { ReturnItemFilter } from '../../../../domain/automator/ReturnItemFilter';

interface OrderSummaryReturnViewProps {
  returns: Return[];
}

export const OrderSummaryReturnView = ({ returns }: OrderSummaryReturnViewProps) => {
  const { t } = useTranslation();

  const returnItems = returns.flatMap((return_) => {
    return return_.returnItems;
  });

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
    >
      <OrderSummaryViewHeader label={t(tokens.automator.order_summary.returns)} />

      <Stack direction="column">
        {returnItems.map((returnItem, index) => {
          return !returnItem.isHandled ? (
            <Stack
              key={index}
              direction="row"
              gap={2}
              alignItems="center"
            >
              <ReturnReasonStack returnItem={returnItem} />

              <ReturnItemButtons
                returnItem={returnItem}
                currentTab={ReturnItemFilter.OVERVIEW}
              />

              <AcceptOrRejectReturnItemButtons returnItem={returnItem} />

              {returnItem.transporterInfos.map((info) => {
                return (
                  info.trackAndTraceUrl && <ReturnItemTransporterChip transporterInfo={info} />
                );
              })}
            </Stack>
          ) : (
            <ReturnProcessingResultListTable returnItem={returnItem} />
          );
        })}
      </Stack>
    </Stack>
  );
};
