import { EMAIL_RULES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import EmailRules from '../../../domain/automator/EmailRules';
import { DistributionParty } from '../../../domain/automator/DistributionParty';
import { CountryCode } from '../../../domain/automator/CountryCode';
import EmailRuleTrigger from '../../../domain/automator/EmailRuleTrigger';
import { RecipientType } from '../../../domain/automator/RecipientType';

export const useUpdateEmailRule = (emailRuleId: number) => {
  const url = useAutomatorApiAccountUrl(EMAIL_RULES_PATH + '/' + emailRuleId);
  return useAuthorizedPost<EmailRules, UseUpdateEmailRuleData>(url, undefined);
};

export interface UseUpdateEmailRuleData {
  product_ids: number[];
  email_template_id: number;
  distribution_party: DistributionParty | null;
  country_code: CountryCode | null;
  recipient_type: RecipientType;
  trigger: EmailRuleTrigger;
  execute_at_time: string | null;
  execute_in_hours: number | null;
  is_start_with_resolution_case: boolean;
}
