import ReturnItem from '../../../../domain/automator/ReturnItem';
import Stack from '@mui/material/Stack';
import HandleReturnItemButton from './HandleReturnItemButton';

interface AcceptOrRejectReturnItemButtonsProps {
  returnItem: ReturnItem;
}

const AcceptOrRejectReturnItemButtons = ({ returnItem }: AcceptOrRejectReturnItemButtonsProps) => {
  return (
    <Stack
      direction="row"
      alignItems="end"
      justifyContent="right"
      gap={1}
    >
      <HandleReturnItemButton
        type="ACCEPT"
        returnItem={returnItem}
      />

      <HandleReturnItemButton
        type="REJECT"
        returnItem={returnItem}
      />
    </Stack>
  );
};

export default AcceptOrRejectReturnItemButtons;
