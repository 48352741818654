import { RESOLUTION_DOSSIERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ResolutionDossierStatus } from '../../../domain/automator/ResolutionDossierStatus';
import ResolutionDossiers from '../../../domain/automator/ResolutionDossiers';
import { ResolutionDossiersQueryKey } from './ResolutionDossiersQueryKey';

export const useFetchResolutionDossiers = (status: ResolutionDossierStatus) => {
  const url = useAutomatorApiAccountUrl(RESOLUTION_DOSSIERS_PATH + '?status=' + status);
  return useFetchData<ResolutionDossiers>(url, ResolutionDossiersQueryKey(status));
};
