import { ThreadPhase } from '../../../../domain/automator/ThreadPhase';
import OrderSummary from '../../../../domain/automator/OrderSummary';
import Box from '@mui/material/Box';
import { EmailThreadOrderItemSelection } from './EmailThreadOrderItemSelection';
import { EmailThreadShipmentSelection } from './EmailThreadShipmentSelection';
import { EmailThreadReturnItemSelection } from './EmailThreadReturnItemSelection';

interface EmailThreadAggregateSelectionProps {
  orderSummary: OrderSummary;
  phase: ThreadPhase;
  aggregateId: number | null;
  setAggregateId: (aggregateId: number) => void;
}

export const EmailThreadAggregateSelection = ({
  orderSummary,
  phase,
  aggregateId,
  setAggregateId,
}: EmailThreadAggregateSelectionProps) => {
  return (
    <Box>
      {phase == ThreadPhase.ORDER && (
        <EmailThreadOrderItemSelection
          order={orderSummary.order!}
          setAggregateId={setAggregateId}
          selectedAggregateId={aggregateId}
        />
      )}

      {phase == ThreadPhase.SHIPMENT && (
        <EmailThreadShipmentSelection
          shipment={orderSummary.shipment!}
          setAggregateId={setAggregateId}
          selectedAggregateId={aggregateId}
        />
      )}

      {phase == ThreadPhase.RETURN && (
        <EmailThreadReturnItemSelection
          returns={orderSummary.returns}
          selectedAggregateId={aggregateId}
          setAggregateId={setAggregateId}
        />
      )}
    </Box>
  );
};
