import { OFFERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { OffersQueryKey } from '../../queryKeys/OffersQueryKey';
import Offers from '../../../domain/automator/Offers';

export const useFetchOffers = (productId: number) => {
  const url = useAutomatorApiAccountUrl(OFFERS_PATH + '?product_id=' + productId);
  return useFetchData<Offers>(url, OffersQueryKey(productId));
};
