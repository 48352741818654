import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

interface OrderSummaryViewHeaderProps {
  label: string;
}

export const OrderSummaryViewHeader = ({ label }: OrderSummaryViewHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Typography
      fontWeight={700}
      color="#008ab8"
      variant="body2"
      width={120}
    >
      {t(label)}
    </Typography>
  );
};
