import { ORDER_SUMMARIES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import OrderSummary from '../../../domain/automator/OrderSummary';
import { OrderSummaryQueryKey } from '../../queryKeys/OrderSummaryQueryKey';

export const useFetchOrderSummary = (bolOrderId: string) => {
  const url = useAutomatorApiAccountUrl(ORDER_SUMMARIES_PATH + '?bolOrderId=' + bolOrderId);

  return useFetchData<OrderSummary | null>(url, OrderSummaryQueryKey(bolOrderId), {
    enabled: bolOrderId.length >= 10,
  });
};
