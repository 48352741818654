import { CountryCode } from '../automator/CountryCode';
import OfferPerformanceOverwrite from './OfferPerformanceOverwrite';

export default interface OfferPerformance {
  ids: number[];
  offerId: number;

  impressions: number;

  [OfferPerformanceProperty.VISITS]?: number;
  [OfferPerformanceProperty.ORGANIC_VISITS]?: number;
  [OfferPerformanceProperty.NON_ORGANIC_VISITS]?: number;

  ctr: number;

  amountOfOrders?: number;

  [OfferPerformanceProperty.ARTICLES_SOLD]: number;
  [OfferPerformanceProperty.ORGANIC_ARTICLES_SOLD]: number;
  [OfferPerformanceProperty.NON_ORGANIC_ARTICLES_SOLD]: number;

  [OfferPerformanceProperty.CONVERSION_RATE]?: number;
  [OfferPerformanceProperty.ORGANIC_CONVERSIONS_RATE]?: number;
  [OfferPerformanceProperty.NON_ORGANIC_CONVERSIONS_RATE]?: number;

  ordersConversionRate: number;

  profitInCents: number | null;
  yearlyProfitInCents: number | null;
  yearlyReturnOnInvestmentDemandEfficiency: number | null;

  countryCodes: CountryCode[];
  overwrite?: OfferPerformanceOverwrite;
  date: string;
}

export enum OfferPerformanceProperty {
  VISITS = 'visits',
  ORGANIC_VISITS = 'organicVisits',
  NON_ORGANIC_VISITS = 'nonOrganicVisits',

  ARTICLES_SOLD = 'articlesSold',
  ORGANIC_ARTICLES_SOLD = 'organicArticlesSold',
  NON_ORGANIC_ARTICLES_SOLD = 'nonOrganicArticlesSold',

  CONVERSION_RATE = 'conversionRate',
  ORGANIC_CONVERSIONS_RATE = 'organicConversionRate',
  NON_ORGANIC_CONVERSIONS_RATE = 'nonOrganicConversionRate',
}
