import Shipment from '../../../../domain/automator/Shipment';
import Stack from '@mui/material/Stack';
import { CountryCode } from '../../../../domain/automator/CountryCode';
import { FlagIcon } from '../../orders/components/FlagIcon';
import TransporterChip from './TransporterChip';

interface ShipmentTransportInfoProps {
  shipment: Shipment;
}

const ShipmentTransportInfo = ({ shipment }: ShipmentTransportInfoProps) => {
  return (
    <Stack
      direction="row"
      alignItems={'center'}
      gap={1}
    >
      {shipment.transporterInfos.map((transporterInfo) => (
        <Stack
          direction={'row'}
          alignItems="center"
          gap={0.5}
          key={transporterInfo.transporterCode}
        >
          <TransporterChip
            distributionParty={shipment.distributionParty}
            transporterCode={transporterInfo.transporterCode}
            trackAndTrace={transporterInfo.trackAndTraceUrl}
          />
        </Stack>
      ))}

      {shipment.shipmentDetails.countryCode == CountryCode.BE && (
        <FlagIcon countryCode={CountryCode.BE} />
      )}
    </Stack>
  );
};

export default ShipmentTransportInfo;
