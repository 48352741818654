import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useState } from 'react';
import { ResolutionDossierStatus } from '../../../domain/automator/ResolutionDossierStatus';
import { useFetchResolutionDossiers } from '../../../api/automator/resolutions/useFetchResolutionDossiers';
import ResolutionDossierTabs from './components/ResolutionDossierTabs';
import { ResolutionDossierListTable } from './components/ResolutionDossierListTable';

const ResolutionDossiersPage = () => {
  const [currentTab, setCurrentTab] = useState(ResolutionDossierStatus.OPEN);
  const { data: resolutionDossiers, isLoading } = useFetchResolutionDossiers(currentTab);

  return (
    <AccountPage title={tokens.automator.resolutions.resolution_dossiers}>
      <ResolutionDossierTabs
        report={resolutionDossiers ? resolutionDossiers.report : null}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      <ResolutionDossierListTable
        resolutionDossiers={resolutionDossiers && resolutionDossiers.resolutionDossiers}
        isLoading={isLoading}
      />
    </AccountPage>
  );
};

export default ResolutionDossiersPage;
