import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { RecipientType } from '../../../../domain/automator/RecipientType';

interface RecipientTypeChipProps {
  recipientType: RecipientType;
}

const RecipientTypeChip = ({ recipientType }: RecipientTypeChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (recipientType: RecipientType): string => {
    switch (recipientType) {
      case RecipientType.TRANSPORTER:
        return 'V';
      case RecipientType.BOL:
        return 'P';
      case RecipientType.CUSTOMER:
        return 'K';
      case RecipientType.SELLER:
        return 'S';
      default:
        return '';
    }
  };

  const determineColor = (recipientType: RecipientType) => {
    switch (recipientType) {
      case RecipientType.SELLER:
        return 'success';
      case RecipientType.CUSTOMER:
        return 'primary';
      case RecipientType.BOL:
        return 'secondary';
      case RecipientType.TRANSPORTER:
        return 'info';
      default:
        return 'default';
    }
  };

  return (
    <Chip
      style={{ borderRadius: 4 }}
      size="small"
      label={t(determineLabel(recipientType))}
      variant="filled"
      color={determineColor(recipientType)}
    />
  );
};

export default RecipientTypeChip;
