import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useUpdateReturnItemOverwriteCustomerComments } from '../../../../api/automator/returns/useUpdateReturnItemOverwriteCustomerComments';

interface UpdateReturnItemSellerCommentsButtonProps {
  returnItemId: number;
}

const UpdateReturnItemSellerCommentsButton = ({
  returnItemId,
}: UpdateReturnItemSellerCommentsButtonProps) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useUpdateReturnItemOverwriteCustomerComments(returnItemId);

  const selectedText = () => {
    // Modern approach using Clipboard API
    return window.getSelection()?.toString() ? window.getSelection()!.toString() : '';
  };

  return (
    <ActionButton
      size={'small'}
      style={{ alignSelf: 'center' }}
      label={t(tokens.automator.returns.actions.update_seller_comments)}
      onClick={() =>
        mutate(
          { overwrite_customer_comments: selectedText() },
          {
            onSuccess: async () => {
              toast.success(t(tokens.automator.returns.actions.seller_comments_updated));
            },
          }
        )
      }
      isLoading={isLoading}
    />
  );
};

export default UpdateReturnItemSellerCommentsButton;
