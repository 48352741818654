import Stack from '@mui/material/Stack';
import { OrderSummaryViewHeader } from './OrderSummaryViewHeader';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import { PhaseTypography } from '../../email/components/PhaseTypography';
import { RecipientType } from '../../../../domain/automator/RecipientType';
import { RecipientTypeTypography } from '../../email/components/RecipientTypeTypography';
import Typography from '@mui/material/Typography';
import { GeneralResolutionCaseActions } from '../../resolutions/components/GeneralResolutionCaseActions';
import ResolutionDossier from '../../../../domain/automator/ResolutionDossier';

interface OrderSummaryDossierViewProps {
  resolutionDossier: ResolutionDossier | null;
}

export const OrderSummaryDossierView = ({ resolutionDossier }: OrderSummaryDossierViewProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
    >
      <OrderSummaryViewHeader label={t(tokens.automator.order_summary.resolution_cases)} />

      {resolutionDossier != null && (
        <Stack
          direction="column"
          gap={1}
        >
          {resolutionDossier.resolutionCases.map((resolutionCase, index) => {
            return (
              <Stack
                key={index}
                direction="row"
                gap={2}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={3}
                >
                  <Chip
                    color="info"
                    label={'C' + (index + 1)}
                  />

                  <Stack width={300}>
                    <Stack
                      direction="row"
                      divider={<>{'|'}</>}
                      gap={1}
                    >
                      <PhaseTypography
                        minWidth={2}
                        phase={resolutionCase.phase}
                      />
                      {resolutionCase.recipientType != RecipientType.CUSTOMER && (
                        <RecipientTypeTypography recipientType={resolutionCase.recipientType} />
                      )}
                    </Stack>
                    <Typography
                      width={200}
                      variant="body1"
                    >
                      {resolutionCase.subject}
                    </Typography>
                  </Stack>
                </Stack>

                <GeneralResolutionCaseActions
                  resolutionCaseId={resolutionCase.id}
                  status={resolutionCase.status}
                />
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
