import { ReturnRecoverability } from '../../../../domain/automator/ReturnRecoverability';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useState } from 'react';
import ReturnRecoverabilityChip from './ReturnRecoverabilityChip';
import ReturnItem from '../../../../domain/automator/ReturnItem';
import { useUpdateReturnItemRecoverability } from '../../../../api/automator/returns/useUpdateReturnItemRecoverability';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

interface ReturnRecoverabilityDropdownProps {
  returnItem: ReturnItem;
}

const ReturnRecoverabilityDropdown = ({ returnItem }: ReturnRecoverabilityDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { mutate, isLoading } = useUpdateReturnItemRecoverability(returnItem.id);

  const { t } = useTranslation();

  const returnRecoverAbility = returnItem.returnReason?.recoverability;

  const doUpdate = (returnRecoverAbility: ReturnRecoverability) => {
    const data = {
      recoverability: returnRecoverAbility,
    };
    handleClose();
    mutate(data, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.recoverability.recoverability_updated));
      },
    });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const returnRecoverabilities = Object.values(ReturnRecoverability).filter(
    (value) => value !== returnRecoverAbility
  );

  //#F8F9FA
  return (
    <div>
      <div onClick={handleClick}>
        {isLoading ? (
          <CircularProgress size={40} />
        ) : (
          <ReturnRecoverabilityChip returnRecoverAbility={returnRecoverAbility} />
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button', style: { padding: 0 } }}
      >
        <Box
          mt={0.5}
          mb={0.5}
        >
          <MenuItem
            onClick={() => doUpdate(returnRecoverabilities[0])}
            style={{ padding: 0 }}
          >
            <ReturnRecoverabilityChip returnRecoverAbility={returnRecoverabilities[0]} />
          </MenuItem>
        </Box>
        <Box mb={0}>
          <MenuItem
            onClick={() => doUpdate(returnRecoverabilities[1])}
            style={{ padding: 0 }}
          >
            <ReturnRecoverabilityChip returnRecoverAbility={returnRecoverabilities[1]} />
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

export default ReturnRecoverabilityDropdown;
