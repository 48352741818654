export enum TutorialKey {
  ORDERS_WITHOUT_RETAILER = 'automator.tutorial_alerts.orders_without_retailer', // #1
  RETAILERS_WITHOUT_RETAILER = 'automator.tutorial_alerts.retailers_without_retailer', // #2
  RETAILERS_WITH_RETAILER = 'automator.tutorial_alerts.retailers_with_retailer', // #3
  ORDERS_WITH_RETAILER = 'automator.tutorial_alerts.orders_with_retailer', // #4
  LABEL_TYPE_RULES = 'automator.tutorial_alerts.label_type_rules',
  PICK_SESSIONS = 'automator.tutorial_alerts.pick_sessions',
}

export class TutorialService {
  static isUsed(key: TutorialKey): boolean {
    return localStorage.getItem(key) === 'true';
  }

  static setToUsed(key: TutorialKey): void {
    localStorage.setItem(key, 'true');
  }
}
