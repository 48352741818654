import { QueryKeysUpdater } from '../../useUpdateData';
import { ReturnsQueryKey } from '../../queryKeys/ReturnsQueryKey';
import { ReturnItemStatus } from '../../../domain/automator/ReturnItemStatus';
import Return from '../../../domain/automator/Return';
import { ReturnItemFilter } from '../../../domain/automator/ReturnItemFilter';

export const useReturnsQueryKeysUpdater = (): QueryKeysUpdater<Return> => {
  return {
    [ReturnsQueryKey(ReturnItemFilter.OVERVIEW)]: returnUpdater([
      ReturnItemStatus.REGISTERED,
      ReturnItemStatus.EXCHANGE,
      ReturnItemStatus.REPAIR,
      ReturnItemStatus.ON_HOLD,
    ]),

    [ReturnsQueryKey(ReturnItemFilter.EXCHANGE)]: returnUpdater([ReturnItemStatus.EXCHANGE]),

    [ReturnsQueryKey(ReturnItemFilter.REPAIR)]: returnUpdater([ReturnItemStatus.REPAIR]),

    [ReturnsQueryKey(ReturnItemFilter.ON_HOLD)]: returnUpdater([ReturnItemStatus.ON_HOLD]),

    [ReturnsQueryKey(ReturnItemFilter.HANDLED)]: handledReturnUpdater(),
  };
};

const returnUpdater = (status: ReturnItemStatus[]) => {
  return {
    addCriteria: (data: Return) => {
      return data.returnItems?.some((item) => status.includes(item.status));
    },
  };
};

const handledReturnUpdater = () => {
  return {
    addCriteria: (data: Return) => {
      return data.returnItems?.some((item) => item.returnProcessingResults.length > 0);
    },
  };
};
