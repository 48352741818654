import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { ProcessingResult } from '../../../../domain/automator/ProcessingResult';
import { tokens } from '../../../../locales/translationTokens';

interface ProcessingResultChipProps {
  processingResult: ProcessingResult;
}

const ProcessingResultChip = ({ processingResult }: ProcessingResultChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (processingResult: ProcessingResult) => {
    switch (processingResult) {
      case ProcessingResult.ACCEPTED:
        return tokens.automator.returns.processing_result.accepted;
      case ProcessingResult.REJECTED:
        return tokens.automator.returns.processing_result.rejected;
      case ProcessingResult.CANCELLED:
        return tokens.automator.returns.processing_result.cancelled;
      default:
        return 'default';
    }
  };

  const determineColor = (processingResult: ProcessingResult) => {
    switch (processingResult) {
      case ProcessingResult.ACCEPTED:
        return 'success';
      case ProcessingResult.REJECTED:
        return 'error';
      case ProcessingResult.CANCELLED:
        return 'warning';
      default:
        return 'default';
    }
  };

  return (
    <Chip
      style={{ width: 120 }}
      size="small"
      label={t(determineLabel(processingResult))}
      color={determineColor(processingResult)}
      variant="filled"
    />
  );
};

export default ProcessingResultChip;
