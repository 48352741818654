import { useState } from 'react';
import { InfoAlert } from '../alerts/InfoAlert';
import { TutorialKey, TutorialService } from './TutorialService';

interface TutorialAlertProps {
  tutorialKey: TutorialKey;
  children?: React.ReactNode;
}

export const TutorialAlert = ({ children, tutorialKey }: TutorialAlertProps) => {
  const [isDismissed, setIsDismissed] = useState(TutorialService.isUsed(tutorialKey));

  if (isDismissed) return <></>;

  return (
    <InfoAlert
      onClose={() => {
        TutorialService.setToUsed(tutorialKey);
        setIsDismissed(true);
      }}
    >
      {children}
    </InfoAlert>
  );
};
