import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ResolutionDossier from '../../../../domain/automator/ResolutionDossier';
import { ResolutionDossierProductThumbnail } from './ResolutionDossierProductThumbnail';

interface ResolutionDossierCardProps {
  resolutionDossier: ResolutionDossier;
}

export const ResolutionDossierCard = ({ resolutionDossier }: ResolutionDossierCardProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
    >
      <ResolutionDossierProductThumbnail resolutionDossier={resolutionDossier} />

      <Stack direction={'column'}>
        <Stack
          direction={'row'}
          gap={1}
        >
          <Typography
            noWrap
            variant={'body1'}
          >
            {resolutionDossier.shipmentDetails.firstName}{' '}
            {resolutionDossier.shipmentDetails.surname}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          gap={0.5}
          alignItems={'center'}
        >
          <Typography variant={'body2'}>{resolutionDossier.bolOrderId}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
