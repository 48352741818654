import ShipmentDayReport from '../../../../../domain/automator/ShipmentDayReport';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';
import { tokens } from '../../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface ShipmentStepperTotalsProps {
  shipmentDayReport?: ShipmentDayReport;
}

const ShipmentStepperTotals = ({ shipmentDayReport }: ShipmentStepperTotalsProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      paddingLeft={2}
      gap={4}
    >
      <Tooltip
        enterDelay={2000}
        enterNextDelay={2000}
        title={t(tokens.automator.shipments.total_shipments_track_and_traceble)}
      >
        <Stack
          alignItems="center"
          direction="row"
          gap={0.5}
          width={50}
        >
          <div>
            <img
              width={35}
              src={'/assets/icons/TrackAndTraceable.svg'}
              alt="My logo"
            />
          </div>
          {shipmentDayReport?.totalShipmentsTrackAndTraceable}
        </Stack>
      </Tooltip>

      <Tooltip title={t(tokens.automator.shipments.total_shipments_non_track_and_traceble)}>
        <Stack
          alignItems="center"
          direction="row"
          gap={0.5}
          width={50}
        >
          <div>
            <img
              width={25}
              src={'/assets/icons/NonTrackAndTraceable.svg'}
              alt="My logo"
            />
          </div>
          {shipmentDayReport?.totalShipmentsNotTrackAndTraceable}
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default ShipmentStepperTotals;
