import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { RetailerListTable } from './components/RetailerListTable';
import ActionButton from '../../../components/ActionButton';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import { useFetchRetailers } from '../../../api/automator/retailers/useFetchRetailers';
import { RetailersTutorial } from '../../../components/tutorials/RetailersTutorial';

const RetailersPage = () => {
  const { data: retailers } = useFetchRetailers();

  const openDialog = useOpenDialog(DialogId.ADD_RETAILER);

  return (
    <AccountPage
      tutorialAlert={<RetailersTutorial />}
      title={tokens.automator.retailers.retailers}
      buttons={[
        <ActionButton
          key={1}
          label={tokens.automator.retailers.add_retailer.add_retailer}
          onClick={() => openDialog()}
        />,
      ]}
    >
      <RetailerListTable retailers={retailers} />
    </AccountPage>
  );
};

export default RetailersPage;
