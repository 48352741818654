import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import { useFetchPickSessions } from '../../../../api/automator/pick-sessions/useFetchPickSessions';
import { PickSessionTableRow } from './PickSessionTableRow';
import groupBy from '../../../../helpers/groupBy';
import { TableListDivider } from '../../returns/components/TableListDivider';
import { useState } from 'react';
import { Collapse } from '@mui/material';
import PickOrdersListTable from './PickOrdersListTable';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';

export const PickSessionListTable = () => {
  const [selectedPickSessionId, setSelectedPickSessionId] = useState<number | null>(null);

  const { t } = useTranslation();

  const { data: pickSessions, isLoading } = useFetchPickSessions();

  if (isLoading || !pickSessions) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (pickSessions?.pickSessions.length == 0) {
    return <TableEmpty message={t(tokens.automator.pick_sessions.no_items)} />;
  }

  const sortedPickSessions = [...pickSessions!.pickSessions].sort(
    (a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime()
  );

  const groupedPickSessions = groupBy(sortedPickSessions, (pickSession) =>
    new Date(pickSession.createdDateTime).toDateString()
  );

  const onPickSessionSelect = (pickSessionId: number) => {
    if (selectedPickSessionId === pickSessionId) {
      setSelectedPickSessionId(null);
    } else {
      setSelectedPickSessionId(pickSessionId);
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={10}
                align="left"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedPickSessions).map(([date, pickSessions]) => (
              <>
                <TableListDivider label={getAlphabeticFormatFromDate(new Date(date))} />
                {pickSessions.map((pickSession) => (
                  <>
                    <PickSessionTableRow
                      onClick={onPickSessionSelect}
                      pickSession={pickSession}
                    />

                    <TableRow>
                      <TableCell
                        style={{ padding: 0 }}
                        colSpan={10}
                      >
                        <Collapse
                          in={selectedPickSessionId === pickSession.id}
                          unmountOnExit
                        >
                          <PickOrdersListTable pickSessionId={pickSession.id} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
