import TableRow from '@mui/material/TableRow';
import Return from '../../../../domain/automator/Return';
import { ReturnItemCard } from './ReturnItemCard';
import { ReturnProcessingResultListTable } from './ReturnProcessingResultListTable';
import { SlimTableCell } from '../../../../components/SlimTableCell';

interface HandledReturnTableRowProps {
  return_: Return;
}

export const HandledReturnTableRow = ({ return_ }: HandledReturnTableRowProps) => {
  const returnItems = return_.returnItems.filter(
    (returnItem) => returnItem.returnProcessingResults.length > 0
  );

  const hasBorderBottom = (index: number) => {
    return returnItems.length > 1 && index !== returnItems.length - 1;
  };

  return (
    <>
      {returnItems.map((returnItem, index) => {
        const quantityHandled = returnItem.returnProcessingResults.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );

        return (
          <TableRow key={returnItem.id}>
            <SlimTableCell hasBorderBottom={hasBorderBottom(index)}>
              <ReturnItemCard
                key={returnItem.id}
                quantity={quantityHandled}
                returnItem={returnItem}
                return_={return_}
                showTransporterInfo={true}
              />
            </SlimTableCell>

            <SlimTableCell
              align="right"
              hasBorderBottom={hasBorderBottom(index)}
            >
              <ReturnProcessingResultListTable returnItem={returnItem} />
            </SlimTableCell>
          </TableRow>
        );
      })}
    </>
  );
};
