import { useTranslation } from 'react-i18next';
import { useReturnItemAwaitingRepair } from '../../../../api/automator/returns/useReturnItemAwaitingRepair';
import { useReturnItemOnHold } from '../../../../api/automator/returns/useReturnItemOnHold';
import { useReturnItemAwaitingExchange } from '../../../../api/automator/returns/useReturnItemAwaitingExchange';
import { tokens } from '../../../../locales/translationTokens';
import { ReturnItemStatus } from '../../../../domain/automator/ReturnItemStatus';
import { Build, PanTool, Sync } from '@mui/icons-material';
import ActionButton from '../../../../components/ActionButton';
import { toast } from 'react-hot-toast';
import ReturnItem from '../../../../domain/automator/ReturnItem';
import { useReturnItemSetToNew } from '../../../../api/automator/returns/useReturnItemSetToNew';
import ButtonGroup from '@mui/material/ButtonGroup';
import { ReturnItemFilter } from '../../../../domain/automator/ReturnItemFilter';

interface ReturnItemButtonsProps {
  returnItem: ReturnItem;
  currentTab: ReturnItemFilter;
  onSuccess?: () => void;
}

const ReturnItemButtons = ({ returnItem, currentTab, onSuccess }: ReturnItemButtonsProps) => {
  const { t } = useTranslation();

  const { mutate: returnItemOnHold, isLoading: returnItemOnHoldIsLoading } = useReturnItemOnHold(
    returnItem.id
  );
  const { mutate: returnItemAwaitingExchange, isLoading: returnItemAwaitingExchangeIsLoading } =
    useReturnItemAwaitingExchange(returnItem.id);
  const { mutate: returnItemAwaitingRepair, isLoading: returnItemAwaitingRepairIsLoading } =
    useReturnItemAwaitingRepair(returnItem.id);
  const { mutate: returnItemNew, isLoading: returnItemNewLoading } = useReturnItemSetToNew(
    returnItem.id
  );

  const onNew = () => {
    returnItemNew(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.actions.set_to_new));
        onSuccess?.();
      },
    });
  };

  const onOnHold = () => {
    returnItemOnHold(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.actions.set_to_on_hold));
        onSuccess?.();
      },
    });
  };

  const onAwaitingExchange = () => {
    returnItemAwaitingExchange(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.actions.set_to_awaiting_exchange));
        onSuccess?.();
      },
    });
  };

  const onAwaitingRepair = () => {
    returnItemAwaitingRepair(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.actions.set_to_awaiting_repair));
        onSuccess?.();
      },
    });
  };

  if (currentTab === ReturnItemFilter.HANDLED) {
    return <></>;
  }

  return (
    <ButtonGroup
      variant="outlined"
      color="info"
      size="small"
      sx={{ height: 'auto' }}
    >
      <ActionButton
        icon={<PanTool />}
        label={''}
        onClick={returnItem.status != ReturnItemStatus.ON_HOLD ? () => onOnHold() : () => onNew()}
        disabled={
          returnItem.status == ReturnItemStatus.REPAIR ||
          returnItem.status == ReturnItemStatus.EXCHANGE
        }
        isLoading={returnItemOnHoldIsLoading || returnItemNewLoading}
        variant={returnItem.status == ReturnItemStatus.ON_HOLD ? 'contained' : 'outlined'}
        size="small"
      />

      <ActionButton
        icon={<Build />}
        label={''}
        onClick={onAwaitingRepair}
        disabled={
          returnItem.status == ReturnItemStatus.REPAIR ||
          returnItem.status == ReturnItemStatus.EXCHANGE
        }
        isLoading={returnItemAwaitingRepairIsLoading || returnItemNewLoading}
        variant={returnItem.status == ReturnItemStatus.REPAIR ? 'contained' : 'outlined'}
        size="small"
      />

      <ActionButton
        icon={<Sync />}
        label={''}
        onClick={onAwaitingExchange}
        disabled={
          returnItem.status == ReturnItemStatus.REPAIR ||
          returnItem.status == ReturnItemStatus.EXCHANGE
        }
        isLoading={returnItemAwaitingExchangeIsLoading || returnItemNewLoading}
        variant={returnItem.status == ReturnItemStatus.EXCHANGE ? 'contained' : 'outlined'}
        size="small"
      />
    </ButtonGroup>
  );
};

export default ReturnItemButtons;
