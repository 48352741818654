import {useLocation} from 'react-router-dom';

// Custom hook to get the value of a query parameter
function useQueryParam(key: string) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(key);
}

export default useQueryParam;
