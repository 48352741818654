import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { OrderStatus } from '../../../domain/automator/OrderStatus';
import Orders from '../../../domain/automator/Orders';
import { DistributionParty } from '../../../domain/automator/DistributionParty';
import { OrdersQueryKey } from '../../queryKeys/OrdersQueryKey';

export const useFetchOrders = (
  status: OrderStatus,
  distributionParty: DistributionParty,
  startShipmentDate: string | undefined,
  endShipmentDate: string | undefined
) => {
  const url = useAutomatorApiAccountUrl(
    ORDERS_PATH +
      '?status=' +
      status +
      '&distributionParty=' +
      distributionParty +
      (startShipmentDate ? '&startShipmentDate=' + startShipmentDate : '') +
      (endShipmentDate ? '&endShipmentDate=' + endShipmentDate : '')
  );

  return useFetchData<Orders>(
    url,
    OrdersQueryKey(status, distributionParty, startShipmentDate, endShipmentDate)
  );
};
